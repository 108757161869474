<template>
  <ScaleBox>
    <div class="mr-break">
      <v-header-box></v-header-box>
      <div class="mr-break-container">
        <v-page-date @changeData="changeData"></v-page-date>
        <break-top :propsData="faultData"></break-top>
        <div class="break-bottom">
          <break-bottom-left :propsData="faultData"></break-bottom-left>
          <break-bottom-center :propsData="faultData"></break-bottom-center>
          <break-bottom-right :propsData="faultData"></break-bottom-right>
        </div>
      </div>
      <v-footer></v-footer>
    </div>
  </ScaleBox>
</template>

<script>
import VHeaderBox from "../components/v-header-box.vue";
import VFooter from "../components/v-footer.vue";
import BreakTop from "./components/top.vue";
import BreakBottomLeft from "./components/bottom-left.vue";
import BreakBottomCenter from "./components/bottom-center.vue";
import BreakBottomRight from "./components/bottom-right.vue";
import VPageDate from "../components/v-page-date.vue";
import ScaleBox from "@/components/view/scaleBox.vue";
import { mapState } from "vuex";

export default {
  components: {
    VHeaderBox,
    VFooter,
    BreakTop,
    BreakBottomLeft,
    BreakBottomCenter,
    BreakBottomRight,
    VPageDate,
    ScaleBox,
  },
  data() {
    return {
      faultData: {},
      dateVal: "",
      stype: "",
    };
  },
  computed: {
    ...mapState(["projectCurrentRowData"]),
  },
  watch: {
    projectCurrentRowData: {
      deep: true,
      handler() {
        this.getData()
      }
    }
  },
  methods: {
    getData() {
      this.request
        .screenFault({
          project: this.projectCurrentRowData.projectCode,
          stype: this.stype,
          date: this.dateVal,
        })
        .then((res) => {
          console.log("故障运维--", res.data);
          this.faultData = res.data || {};
        });
    },
    changeData({ type, dateVal }) {
      this.stype = type;
      this.dateVal = dateVal;
      this.getData();
    },
  },
};
</script>

<style scoped lang="scss">
.mr-break {
  height: 100%;
  background: #1b1c20;
  background-size: 100% 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  .mr-break-container {
    padding: 24px;
    flex: 1;
    overflow: hidden;
    .break-bottom {
      margin-top: 24px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
  }
}
</style>
