<template>
  <div class="summary-count">
    <div class="count-total">
      <div class="count">
        <div class="img"><img src="../images/deviceTotal.png" alt="" /></div>
        <div class="count-box">
          <p class="title">设备总数</p>
          <p class="total">{{ summaryData.devices || 0 }}</p>
          <p>
            <span class="label">在线</span><span class="value">{{ summaryData.online || 0 }}</span
            ><span class="label">离线</span><span class="value">{{ summaryData.offline || 0}}</span>
          </p>
        </div>
      </div>
    </div>
    <div class="count-break">
      <p class="title">故障情况</p>
      <div class="count-break-box">
        <div class="count">
          <div class="img"><img src="../images/breakTotal.png" alt="" /></div>
          <div class="count-box">
            <p class="title">总数</p>
            <p class="value">{{ summaryData.faults || 0}}</p>
          </div>
        </div>
        <div class="count">
          <div class="img"><img src="../images/breakAdd.png" alt="" /></div>
          <div class="count-box">
            <p class="title">今日新增</p>
            <p class="value">{{ summaryData.today || 0}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SummaryCount",
  props: {
    propsData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      summaryData: {},
    };
  },
  watch: {
    propsData: {
      deep: true,
      handler() {
        this.summaryData = this.propsData;
      },
    },
  },
};
</script>

<style scoped lang="scss">
.summary-count {
  width: 350px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 12px;
  > div {
    height: 110px;
    background-color: #fff;
  }
  .count-total {
  }

  .count-total,
  .count-break {
    height: 110px;
    background: #ffffff;
    padding: 12px 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .count {
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .count-break-box {
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .img {
    width: 42px;
    height: 42px;
    opacity: 1;
    background: #f6f7fb;
    border-radius: 50%;
    margin-right: 32px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  p {
    font-family: "microsoft yahei ui","microsoft yahei";
    line-height: 1.5;
  }
  .title {
    font-size: 14px;
    font-weight: 500;
    color: #3d3d3d;
  }
  .total {
    font-size: 24px;
    font-weight: bold;
    color: #3d3d3d;
  }
  .label {
    font-size: 12px;
    color: #3d3d3d;
  }
  .value {
    font-size: 14px;
    font-weight: bold;
    color: #3d3d3d;
    margin-left: 16px;
    margin-right: 16px;
  }
}
</style>
