<template>
  <el-dialog :title="title" :visible.sync="editVisible" width="500px" :before-close="handleClose" :close-on-click-modal="false" center>
    <div class="from-body" v-if="flag!=''">
      <el-form :model="ruleForm" ref="ruleForm" :rules="rules" label-width="auto" class="demo-ruleForm">
        <el-form-item :label="paramItem.chinese" prop="value">
          <div v-if="flag=='select'">
            <el-select v-model="ruleForm.value" placeholder="请选择设置值" style="width:100%;">
              <el-option v-for="(item,index) in itemList" :key="index" :label="item.name" :value="item.value"/>
            </el-select>
          </div>
          <div v-else>
            <el-slider v-model="ruleForm.value" :min="minValue" :max="maxValue" style="width:210px"/>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      ruleForm: {
        value: null
      },
      rules: {
        value: [
          {
            required: true,
            message: "请设置更新值",
            trigger: "blur",
          }
        ]
      },
      title: "编辑参数",
      itemList: [],
      flag: "",
      minValue:0,
      maxValue:100,
    };
  },
  computed: {
    ...mapState(["buttonTextColor"]),
  },
  props: ["editVisible","paramItem","deviceId"],
  watch: {
    editVisible(newValue) {
      if (newValue) {
        this.itemList=[];
        this.ruleForm.value=this.paramItem.value;
        let fanwei=this.paramItem.fanwei;
        if (fanwei==""||fanwei==null||fanwei==undefined) {
          this.flag="input";
          this.$message.error("该参数无设置范围");
          this.handleClose();
        } else {
          if (fanwei.includes(":")) {
            this.ruleForm.value=parseInt(this.ruleForm.value);
            let array=fanwei.split(";");
            for (var i=0; i<array.length; i++) {
              let data=array[i].split(":");
              this.itemList.push({
                "name":data[1],
                "value":parseInt(data[0])
              });
            } 
            this.flag="select";
            this.title="参数设置";
          } else {
            if (fanwei.includes("~")==false) {
              this.$message.error("参数范围错误（"+fanwei+"）");
              this.handleClose();
            } else {
              if (this.ruleForm.value.includes(".")) {
                this.ruleForm.value=parseFloat(this.ruleForm.value);
              } else {
                this.ruleForm.value=parseInt(this.ruleForm.value);
              }
              var start=fanwei.split("~")[0];
              var end=fanwei.split("~")[1];
              if (start.includes(".")) {
                this.minValue=parseFloat(start);
              } else {
                this.minValue=parseInt(start);
              }
              if (end.includes(".")) {
                this.maxValue=parseFloat(end);
              } else {
                this.maxValue=parseInt(end);
              }
              this.flag="input";
            }
            this.title="参数设置（"+this.paramItem.fanwei+"）";
          }
        }
      }
    },
  },
  methods: {
    onInput() {
      this.$forceUpdate();
    },
    handleClose() {
      this.ruleForm = {
        value: null,
      };
      this.$emit("handleClose");
    },
    submit() {
      if (this.ruleForm.value==null||this.ruleForm.value=="") {
        this.$message.error("请设置参数值");
      } else {
        this.$refs["ruleForm"].validate((valid) => {
          if (valid) {
            var param={
              "deviceId": this.deviceId,
              "name": this.paramItem.name,
              "value": this.ruleForm.value
            };
            this.request.deviceSetParams(param).then((res) => {
              if (res.code==0) {
                this.$message.success("修改成功");
                this.handleClose();
              }
            });
          }
        });
      }
    },
  },
};
</script>
<style scoped>
.from-body {
  padding: 0 60px;
  box-sizing: border-box;
}
</style>