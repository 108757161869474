<template>
  <!-- 新增账号 -->
  <el-dialog
    :title="title"
    :visible.sync="accountVisible"
    width="650px"
    :before-close="handleClose"
    :close-on-click-modal="false"
    center
  >
    <div class="from-body">
      <el-form :model="ruleForm" ref="ruleForm" :rules="rules" label-width="auto" class="demo-ruleForm">
        <el-form-item label="登录账号" prop="account">
          <el-input v-model="ruleForm.account" :disabled="accountManagementAddState == 2"/>
        </el-form-item>
        <el-form-item label="登录密码" prop="password">
          <el-input v-model="ruleForm.password" show-password autocomplete="new-password" :disabled="accountManagementAddState == 2">
            <template slot="append">
              <el-button @click="randomString">自动生成</el-button>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="归属组织" prop="parentOrg">
          <el-cascader :options="orgList" :props="defaultProps" v-model="ruleForm.parentOrg" style="width:400px;" :disabled="accountManagementAddState == 2" clearable/>
        </el-form-item>
        <el-form-item label="员工姓名" prop="name">
          <el-input v-model="ruleForm.name" :disabled="accountManagementAddState == 2"/>
        </el-form-item>
        <el-form-item label="手机号码" prop="mobile">
          <el-input v-model="ruleForm.mobile" @input="onInput()" :disabled="accountManagementAddState == 2"/>
        </el-form-item>
        <el-form-item label="联系邮箱" prop="email">
          <el-input v-model="ruleForm.email" :disabled="accountManagementAddState == 2" />
        </el-form-item>
        <el-form-item label="用户角色" prop="roleIds">
          <el-checkbox-group v-model="ruleForm.roleIds" :disabled="accountManagementAddState == 2">
            <el-checkbox :label="item.value" v-for="(item, index) in roleList" :key="index">{{ item.label }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">{{ accountManagementAddState != 2 ? "取 消" : "关 闭"}}</el-button>
      <el-button type="primary" @click="submit" v-if="accountManagementAddState != 2">确 定</el-button>
    </span>
  </el-dialog>
</template>


<script>
import { validateUsername } from "@/utils/util/test";
import { randomString } from "@/utils/util/validate";
import { mapState } from "vuex";
export default {
  data() {
    return {
      ruleForm: {
        parentOrg: [], //部门id
        email: "", //邮箱
        header: null, //	图片信息
        password: "", //密码
        name: "", //真实姓名
        roleIds: [], //角色ids
        account: "", //账号名称
      },
      defaultProps: {
        children: "orgList",
        label: "name",
        value: "id",
        checkStrictly: true
      },
      title: "新增账号",
      roleList: [], //角色列表
      orgList: [], //部门列表
      rules: {
        account: [
          {
            validator: validateUsername,
            trigger: "blur",
          },
          {
            required: true,
            message: "请填写登录账号",
            trigger: "blur",
          },
          {
            min: 2,
            max: 12,
            message: "请填写2-12位账号",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请填写登录密码",
            trigger: "blur",
          },
          {
            min: 6,
            max: 18,
            message: "请填写6-18位密码",
            trigger: "blur",
          },
        ],
        parentOrg: [
          { 
            required: true, 
            message: "请选择组织", 
            trigger: "change" 
          },
        ],
        roleIds: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个角色",
            trigger: "change",
          },
        ],
        name: [
          {
            required: true,
            message: "请填写员工姓名",
            trigger: "blur",
          },
        ],
        mobile: [
          {
            required: false,
            message: "请填写手机号",
            trigger: "change",
          }
        ],
      },
    };
  },
  computed: {
    ...mapState(["buttonTextColor"]),
  },
  mounted() {
    
  },
  watch: {
    accountVisible(newValue) {
      if (newValue) {
        this.listOrg();
        this.roleManagementListAll();
        if (this.accountManagementAddState == 0) {
          this.randomString();
          this.title = "新增账号";
        }
        if (this.accountManagementAddState == 1) {
          this.title = "编辑账号";
        }
        if (this.accountManagementAddState == 2) {
          this.title = "查看账号";
        }
        if (this.accountManagementAddState != 0) {
          this.ruleForm.parentOrg = this.currentAccount.parentOrg;
          this.ruleForm.email = this.currentAccount.email;
          this.ruleForm.mobile = this.currentAccount.mobile;
          this.ruleForm.header = null;
          this.ruleForm.password = this.currentAccount.post;
          this.ruleForm.name = this.currentAccount.name;
          this.ruleForm.id = this.currentAccount.id;
          this.ruleForm.account = this.currentAccount.account;
          for (let i in this.currentAccount.roles) {
            this.ruleForm.roleIds.push(this.currentAccount.roles[i].id);
          }
        }
      }
    },
  },
  props: ["accountVisible", "accountManagementAddState", "currentAccount"],
  methods: {
    onInput() {
      //input输入框无法编辑
      this.$forceUpdate();
    },
    handleClose() {
      this.ruleForm = {
        parentOrg: [],//部门id
        email: "",    //邮箱
        header: null, //用户头像
        password: "", //密码
        name: "",     //真实姓名
        roleIds: [],  //角色ids
        account: "",  //账号名称
        mobile: "",   //手机号
      };
      this.$refs["ruleForm"].resetFields();
      this.$emit("accountClose");
    },
    //自动生成密码
    randomString() {
      this.ruleForm.password = randomString(6);
    },
    //部门管理-查询所有部门
    listOrg() {
      this.request.listOrg().then((res) => {
        if (res.code==0) {
          this.orgList=[res.data];
        }
      });
    },
    //查询所有角色
    roleManagementListAll() {
      this.request.roleManagementListAll().then((res) => {
        let arr = [];
        for (let i in res.data) {
          arr.push({
            value: res.data[i].id,
            label: res.data[i].roleName,
          });
        }
        this.roleList = arr;
      });
    },
    //提交
    submit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (this.accountManagementAddState == 0) {
            this.request.accountManagementAdd(this.ruleForm).then((res) => {
              if (res.code==0) {
                this.$message.success("新增成功");
                this.handleClose();
                this.$emit("accountManagementList");
              }
            });
          } else {
            this.request.accountManagementEdit(this.ruleForm).then((res) => {
              if (res.code==0) {
                this.$message.success("编辑成功");
                this.handleClose();
                this.$emit("accountManagementList");
              }
            });
          }
        }
      });
    },
  },
};
</script>


<style scoped>
.from-body {
  padding: 0 60px;
  box-sizing: border-box;
}
</style>