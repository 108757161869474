<template>
  <div class="scene-exp">
    <div class="exp-title">例：</div>
    <div class="container">
      <el-row :gutter="8">
        <el-col :span="4">
          <div class="box">
            <div class="box-label">场景名称</div>
            <div class="box-container">
              <div class="box-label-container">
                <p class="label">场景命名</p>
              </div>
              <div class="box-label-container">
                <p class="group-title">夏日制冷</p>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="box">
            <div class="box-label">设定条件</div>
            <div class="box-container">
              <div class="box-label-container">
                <p class="blankDiv"></p>
                <p class="label">选择设备</p>
                <p class="label">选择属性</p>
                <p class="label">选择条件</p>
                <p class="label">设定数值</p>
              </div>
              <div class="box-scroll">
                <div class="box-label-container" v-for="(condition, index) in expData.condition" :key="index">
                  <p class="blankDiv">
                    <span v-if="index >= 1">{{
                      expData.condition.length > 1 ? "或" : ""
                    }}</span>
                  </p>
                  <p class="value">{{ condition.device }}</p>
                  <p class="value">{{ condition.attr }}</p>
                  <p class="value">{{ condition.condition }}</p>
                  <p class="value">{{ condition.num }}</p>
                </div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="box">
            <div class="box-label">触发动作</div>
            <div class="box-container">
              <div class="box-label-container">
                <p class="label longlabel">选择设备</p>
                <p class="label">工作状态</p>
                <p class="label">工作数值</p>
              </div>
              <div class="box-scroll">
                <div class="box-label-container" v-for="(item, index) in expData.eventData" :key="index">
                  <p class="value longvalue">{{ item.device }}</p>
                  <p class="value">{{ item.status }}</p>
                  <p class="value">{{ item.num }}</p>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "SceneExp",
  data() {
    return {
      expData: {
        sceneName: "夏日制冷",
        condition: [
          {
            device: "温度计",
            attr: "温度",
            condition: "大于",
            num: "28°C",
          },
          {
            device: "压力表",
            attr: "压强",
            condition: "等于",
            num: "13",
          },
        ],
        eventData: [
          {
            device: "50栋阳台机组",
            status: "制冷",
            num: "26°C",
          },
          {
            device: "新风",
            status: "开启",
            num: "",
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss" lang="scss">
.scene-exp {
  .exp-title {
    font-family: "microsoft yahei ui","microsoft yahei";
    font-size: 16px;
    color: #424e66;
    margin-bottom: 18px;
    margin-top: 18px;
  }

  .container {
    margin: 18px 0;
  }

  .box-label {
    font-family: "microsoft yahei ui","microsoft yahei";
    font-size: 12px;
    color: #666666;
    margin-bottom: 8px;
  }

  .box-container {
    background: #f5f5f7;
    box-sizing: border-box;
    padding-top: 12px;
    padding-left: 12px;
    padding-bottom: 12px;
    height: 124px;
    .box-scroll {
      height: 72px;
      overflow-y: auto;
      padding-right: 12px;
      box-sizing: border-box;
    }
    .box-label-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
      .blankDiv {
        width: 25px;
        color: #999999;
        font-size: 12px;
        text-align: center;
      }
      .group-title {
        color: #333333;
        font-size: 14px;
      }
      .label {
        width: 33%;
        font-family: "microsoft yahei ui","microsoft yahei";
        font-size: 12px;
        color: #999999;
        margin-bottom: 12px;
        text-align: center;
      }
      .value {
        width: 33%;
        font-family: "microsoft yahei ui","microsoft yahei";
        font-size: 12px;
        color: #333333;
        text-align: center;
        line-height: 28px;
      }
      .longlabel {
        width: 34%;
        text-align: left;
      }
      .longvalue {
        width: 34%;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
</style>
