<template>
  <el-dialog :title="id==null ? '新增组织' : '编辑组织'" :visible.sync="orgVisible" width="650px" :modal-append-to-body='false' :before-close="handleClose" :close-on-click-modal="false" center>
    <div class="from-body">
      <el-form :model="ruleForm" ref="ruleForm" :rules="rules" label-width="auto" class="demo-ruleForm">
        <el-form-item label="组织名称" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      ruleForm: {
        id: null,
        name: null,
        parentId: null,
      },
      rules: {
        name: [
          {
            required: true,
            message: "请填写部门名称",
            trigger: "blur",
          },
        ]
      },
    };
  },
  computed: {
    ...mapState(["buttonTextColor"]),
  },
  props: ["orgVisible","parentId","id","name"],
  watch: {
    orgVisible(newVal) {
      if (newVal) {
        this.ruleForm.id=this.id;
        this.ruleForm.parentId=this.parentId;
        this.ruleForm.name=this.name;
      }
    }
  },
  methods: {
    handleClose() {
      this.ruleForm.name = ""; 
      this.ruleForm.parentId = 0;
      this.$refs["ruleForm"].resetFields();
      this.$emit("handleClose");
    },
    //确定
    submit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (this.ruleForm.id==null) {
            this.request.addOrg(this.ruleForm).then((res) => {
              if (res.code==0) {
                this.$emit("listOrg");
                this.handleClose();
              }
            });
          } else {
            this.request.editOrg(this.ruleForm).then((res) => {
              if (res.code==0) {
                this.$emit("listOrg");
                this.handleClose();
              }
            });
          }
        }
      });
    },
  },
};
</script>


<style scoped>
.from-body {
  padding: 0 60px;
  box-sizing: border-box;
}
</style>