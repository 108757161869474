<template>
  <div class="break-bottom-left">
    <div class="box">
      <div class="title">
        <span>故障类别</span>
      </div>
      <div class="chart">
        <mr-echarts :options="options1"></mr-echarts>
      </div>
    </div>
    <div class="box">
      <div class="title">
        <span>故障等级</span>
      </div>
      <div class="chart">
        <mr-echarts :options="options2"></mr-echarts>
      </div>
    </div>
  </div>
</template>

<script>
import MrEcharts from "@/components/public/mr-echarts.vue";
import chart1Opt from "../chartOpt/chart1.js";
import chart2Opt from "../chartOpt/chart2.js";
export default {
  name: "BreakBottomLeft",
  components: {
    MrEcharts
  },
  props: {
    propsData: {
      type: Object, 
      default: () => {
        return {}
      }
    }
  },
  watch: {
    propsData: {
      deep: true,
      handler() {
        this.faultData = Object.assign({}, this.propsData);
        this.initChart();
      }
    }
  },
  data() {
    return {
      options1: {},
      options2: {},
      faultData: {},
    }
  },
  mounted() {
  },
  methods: {
    initChart() {
      const { faultTypes, faultLevels } = this.faultData;
      chart1Opt.series[0].data = faultTypes;
      this.options1 = chart1Opt;
      chart2Opt.series[0].data = faultLevels;
      this.options2 = chart2Opt;
    },
  }
}
</script>


<style scoped lang="scss">
.break-bottom-left {
  .box {
    width: 433px;
    height: 314px;
    opacity: 1;
    background: linear-gradient(180deg, rgba(27, 53, 56, 0.52) 0%, rgba(21, 52, 73, 0) 41%, rgba(13, 51, 97, 0.09) 100%);
    box-sizing: border-box;
    padding: 12px;
    border: 1px solid ;
    border-image: linear-gradient(283deg, rgba(0, 255, 221, 0.26) 8%, rgba(0, 255, 221, 0) 24%, rgba(0, 229, 255, 0) 81%, rgba(39, 233, 255, 0.66) 96%) 1;
    margin-bottom: 24px;
    .title {
      width: 128px;
      height: 28px;
      background: url("~@/assets/images/visual/rect.png") no-repeat;
      background-size: 100% 100%;
      padding-left: 24px;
      margin-top: 24px;
      line-height: 28px;
      >span {
        font-family: "microsoft yahei ui","microsoft yahei";
        color: #FFFFFF;
        font-size: 16px;
      }
    }

    .chart {
      height: 264px;
    }
  }
}
</style>