export default {
  title: {
    text: "项目所在地分布",
    top: "3%",
    textStyle: {
      color: "rgba(61, 61, 61, 1)",
      fontSize: 14,
      fontWeight: 500,
    },
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "shadow",
    },
  },
  grid: {
    left: "3%",
    right: "4%",
    bottom: "3%",
    containLabel: true,
  },
  xAxis: {
    type: "category",
    data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
  },
  yAxis: {
    type: "value",
  },
  series: [
    {
      data: [],
      type: "bar",
      barWidth: 8,
      itemStyle: {
        color: "#409eff",
      },
    },
  ],
};
