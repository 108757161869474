<template>
  <div class="page2">
    <v-page-date @changeData="changeData"></v-page-date>
    <mr-echarts :options="options" style="width: 100%;height: 80%;"></mr-echarts>
  </div>
</template>

<script>
// import { nodes, links } from "./chartOpt/chart3.js";
import MrEcharts from "@/components/public/mr-echarts.vue";
import { mapState } from "vuex";
import VPageDate from "./../components/v-page-date.vue";
export default {
  name: "EnergySankey",
  components: {
    MrEcharts,
    VPageDate
  },
  data() {
    return {
      chart1: null,
      options: {},
      type: null,
      dateVal: null
    };
  },
  computed: {
    ...mapState(["projectCurrentRowData"]),
  },
  watch: {
    projectCurrentRowData: {
      deep: true,
      handler() {
        this.getData()
      }
    }
  },
  mounted() {
  },
  methods: {
    changeData({ type, dateVal }) {
      this.type = type;
      this.dateVal = dateVal;
      this.getData();
    },
    initObj(data, links) {
      this.options = {
        tooltip: {
          trigger: "item",
          triggerOn: "mousemove",
        },
        series: [
          {
            type: "sankey",
            nodeAlign: "left",
            data: data,
            links: links,
            lineStyle: {
              color: "source",
              curveness: 0.5,
            },
          },
        ],
      };
    },
    getData() {
      this.request
        .energyFlow({ project: this.projectCurrentRowData.projectCode, date: this.dateVal, stype: this.type })
        .then((res) => {
          const { data, links } = res.data;
          console.log("能流统计--", data, links);
          this.initObj(data, links);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.page2 {
  width: 100%;
  margin: 0 auto;
  height: 100%;
}
</style>
