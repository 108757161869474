<template>
  <router-view></router-view>
</template>

<script>
import PageHeader from "../../components/layout/page-header.vue";
export default {
  data() {
    return {
    };
  },
  components: {
    PageHeader,
  },
  mounted() {
    
  }
};
</script>

<style scoped>
.layout-row {
  flex: 1;
  overflow: hidden;
  height:100%;
}
.layout-col {
  height: 100%;
  overflow: hidden;
}
.layout-right {
  box-sizing: border-box;
  height: 99.5%;
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  display: flex;
  flex-direction: column;
}
.view {
  background-color: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
}
</style>