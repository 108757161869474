<template>
  <div class="d-card2">
    <div class="title">
      <span>累计工作时长</span>
    </div>
    <div class="d-chart">
      <mr-echarts :options="options1"></mr-echarts>
    </div>
  </div>
</template>


<script>
import MrEcharts from "@/components/public/mr-echarts.vue";
export default {
  name: "DCard2",
  components: {
    MrEcharts
  },
  props: {
    propsData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    propsData: {
      deep: true,
      handler() {
        console.log("设备累计工作时长---", this.propsData)
      },
    },
  },
  data() {
    return {
      options1: null
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      setTimeout(() => {
        this.options1 = {
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            show: false
          },
          grid: {
            top: "20%",
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: [],
            axisLabel: {
              color: "#fff",
            },
            axisLine: {
              lineStyle: {
                color: "#fff",
                opacity: 0.4
              }
            },
            axisTick: {
              show: false
            },
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              color: "#fff",
              rotate: 30
            },
            axisLine: {
              show: true
            },
            splitLine: {
              show: true,
              lineStyle: {
                opacity: 0.4
              }
            }
          },
          series: [
            {
              name: '时间',
              type: 'line',
              stack: 'Total',
              data: [],
              itemStyle: {
                color: "#0EEFFF"
              }
            },
          ]
        };
      }, 10)
    }
  }
}
</script>

<style scoped lang="scss">
.d-card2 {
  width: 100%;
  .title {
    width: 173.25px;
    height: 24px;
    font-family: "microsoft yahei ui","microsoft yahei";
    color: #FFFFFF;
    font-size: 16px;
    background: url("~@/assets/images/visual/rect.png") no-repeat;
    padding-left: 18px;
    background-size: 100% 100%;
  }
  .d-chart {
    width: 100%;
    height: 264px;
    opacity: 1;
    margin-top: 24px;
    box-sizing: border-box;
    border: 1px solid ;
    border-image: linear-gradient(273deg, rgba(0, 255, 221, 0.26) 1%, rgba(0, 255, 221, 0) 19%, rgba(0, 229, 255, 0) 82%, rgba(39, 233, 255, 0.66) 98%) 1;
  }
}
</style>