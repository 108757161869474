const systemList = [
  {
    id: 1,
    name: "冷暖两联供"
  },
  {
    id: 2,
    name: "农业大棚恒温"
  },
  {
    id: 3,
    name: "商用热水"
  },
  {
    id: 4,
    name: "多能源复合"
  },
  {
    id: 5,
    name: "恒温恒湿"
  },
  {
    id: 6,
    name: "户式采暖"
  },
  {
    id: 7,
    name: "泳池"
  },
  {
    id: 8,
    name: "烘干"
  },
  {
    id: 9,
    name: "热水BOT"
  },
  {
    id: 10,
    name: "热水租赁"
  },
  {
    id: 11,
    name: "畜牧养殖供暖"
  },
  {
    id: 12,
    name: "集中供暖"
  },
  {
    id: 13,
    name: "其他"
  }
]

export default systemList;