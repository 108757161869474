<template>
  <div class="mr-visual">
    <div class="mr-visual-container">
      <div class="mr-visual-header">
        <v-header/>
        <v-card :propsData="searchParams.project"/>
      </div>
      <div class="title">宁夏链杞烘干车间智慧控制系统</div>
      <div class="operation">
        <div style="width:68.5%;margin-left:1.5%;">
          <el-form ref="form" :model="searchParams" label-width="auto" class="search-form" style="height:50px;line-height:50px;display:flex;align-items:center;margin-top:5px;">
            <el-form-item label="设备列表">
              <el-select v-model="searchParams.deviceId" placeholder="请选择查看设备" style="width:350px;margin-top:5px;" @change="getFaultList" clearable>
                <el-option v-for="(item, index) in deviceList" :key="index" :label="item.name" :value="item.id"/>
              </el-select>
            </el-form-item>
            <el-form-item label="严重程度">
              <el-select v-model="searchParams.level" placeholder="全部属性" style="width:150px;margin-top:5px;" @change="getFaultList" clearable>
                <el-option label="轻微" value=10 />
                <el-option label="一般" value=20 />
                <el-option label="严重" value=30 />
              </el-select>
            </el-form-item>
            <el-form-item label="故障名称">
              <el-input v-model="searchParams.name" style="width:150px;margin-top:5px;" @keyup.enter.native="getFaultList" clearable/>
            </el-form-item>
          </el-form>
        </div>
        <div style="width:29%;margin-right:1%;display:flex;flex-direction:row;justify-content:flex-end;">
          <div class="btnHome" @click="backHome"><img :src="zhuye" style="width:20px;height:20px;margin-right:5px;"/>主页</div>
          <div class="btnBack" @click="backPrev"><img :src="back" style="width:16px;height:16px;margin-right:5px;"/>返回</div>
        </div>
      </div>
      <div class="body1">
        <div class="fasheng">
          <div class="param-header">
            <div style="width:40%;text-align:left;padding-left:1%;">发生故障</div>
            <div style="width:20%;text-align:center;">严重程度</div>
            <div style="width:40%;text-align:center;">发生时间</div>
          </div> 
          <div style="width:100%;" v-for="(item, index) in fsList" :key="index">
            <div class="param-item">
              <div style="width:40%;text-align:left;padding-left:1%;">{{item.name}}</div>
              <div style="width:20%;text-align:center;">{{getLevel(item)}}</div>
              <div style="width:40%;text-align:center;">{{ item.createdAt }}</div>
            </div>
            <div style="width:100%;height:2px;background-color:whitesmoke;">&nbsp;</div>
          </div> 
        </div>  
        <div class="xiaochu">
          <div class="param-header">
            <div style="width:40%;text-align:left;padding-left:1%;">消除故障</div>
            <div style="width:20%;text-align:center;">严重程度</div>
            <div style="width:40%;text-align:center;">消除时间</div>
          </div> 
          <div style="width:100%;" v-for="(item, index) in xcList" :key="index">
            <div class="param-item">
              <div style="width:40%;text-align:left;padding-left:1%;">{{item.name}}</div>
              <div style="width:20%;text-align:center;">{{getLevel(item)}}</div>
              <div style="width:40%;text-align:center;">{{ item.createdAt }}</div>
            </div>
            <div style="width:100%;height:2px;background-color:whitesmoke;">&nbsp;</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import VHeader from "./components/v-header.vue";
import VCard from "./components/v-card.vue";
export default {
  name: "nxhg",
  components: {
    VCard,
    VHeader
  },
  data() {
    return {
      searchParams:{
        project: null,
        deviceId: null,
        name: null,
        level: null,
        status: null,
        pageSize: 200,
        pageNum: 1,
      },
      fsList: [],
      xcList: [],
      deviceData: {},
      placeList: [],
      deviceList: [],
      itemList: [],
      first: true,
      zhuye: require("@/assets/images/nxhg/zhuye.png"),
      back: require("@/assets/images/nxhg/back.png"),
    }
  },
  computed: { },
  mounted() {
    this.searchParams.project=this.$route.query.projectCode;
    this.getPlaceList();
    this.getFaultList();
  },
  methods: {
    getDeviceList() {
      this.request.deviceList({project:this.searchParams.project}).then((res) => {
        this.deviceList=[];
        var itemList = res.data || [];
        for (var i=0; i<itemList.length; i++) {
          var name=itemList[i].categoryName+"|"+itemList[i].modelName+"|"+itemList[i].manufacturerName;
          var place=this.getPlace(this.placeList,itemList[i].place[itemList[i].place.length-1]);
          if (place!=null) {
            name=name+"|"+place;
          }
          if (itemList[i].online=='yes') {
            name=name+"|在线";
          } else {
            name=name+"|离线";
          }
          var item={
            "id":itemList[i].id,
            "name":name
          };
          if (itemList[i].categoryName=="PLC") {
            this.deviceList.push(item);
            if (i!=0) {
              var temp=this.deviceList[0];
              this.deviceList[0]=item;
              this.deviceList[i]=temp;
            }
          } else {
            this.deviceList.push(item);
          }
        }
        this.itemList=itemList;
        if (this.deviceList.length>0) {
          this.searchParams.deviceId=this.deviceList[0].id;
          this.getFaultList();
        }
      });
    },
    getPlaceList() {
      this.request.placeList({project:this.searchParams.project}).then((res) => {
          if (res.code==0) {
            this.placeList=[res.data];
            if (this.first) {
              this.first=false;
              this.getDeviceList();
            }
          }
      });
    },
    getPlace(list,placeId) {
      for (var j=0;j<list.length;j++) {
        if (placeId==list[j].id) {
          return list[j].name;
        }
        if (list[j].nodeList!=null&&list[j].nodeList.length>0) {
          return this.getPlace(list[j].nodeList,placeId);
        }
      }
      return null;
    },
    getLevel(item) {
      if (item.level==10) {
        return "轻微";
      }
      if (item.level==20) {
        return "一般";
      }
      if (item.level==30) {
        return "严重";
      }
      return "未知";
    },
    getFaultList() {
      this.getFasheng();
      this.getXiaochu();
    },
    getFasheng() {
      this.searchParams.status=10;
      this.request.deviceFaults(this.searchParams).then(res => {
        if (res.code==0) {
          this.fsList = res.data || [];
        }
      });
    },
    getXiaochu() {
      this.searchParams.status=20;
      this.request.deviceFaults(this.searchParams).then(res => {
        if (res.code==0) {
          this.xcList = res.data || [];
        }
      });
    },
    backHome() {
      this.$router.replace({
        path: "/project"
      });
    },
    backPrev() {
      this.$router.replace({
        path: "/nxhg",
        query: {
          projectCode: this.searchParams.project,
        },
      });
    }
  }
}
</script>
<style scoped lang="scss">
.mr-visual {
  height: 100%;
  background: #EFEFEF;
  overflow: hidden;
  position: relative;

  .mr-visual-container {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .mr-visual-header {
    height: 52px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    background: black;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 24px;
    box-sizing: border-box;
  }

  .title {
    width:100%;
    height:52px;
    line-height:52px;
    text-align:center;
    color:white;
    font-size: 20px;
    top: 0px;
    position: absolute;
    z-index: 200;
  }

  .operation {
    margin-top:65px;
    width:100%;
    height:50px;
    line-height:50px;
    display: flex;
    flex-direction: row;
  }

  .btnSwitch {
    width:90px;
    height:36px;
    line-height:36px;
    text-align:center;
    margin-left:15px;
    margin-right:10px;
    color:#2D76F6;
    background:white;
    border-radius:10px;
  }

  .labelStatus {
    width:80px;
    height:36px;
    line-height:36px;
    font-size:16px;
    font-weight: bold;
    margin-left:15px;
  }

  .btnHome {
    width:90px;
    height:36px;
    line-height:36px;
    text-align:center;
    margin-right:10px;
    color:black;
    background:white;
    border-radius:10px;
    display:flex;
    align-items: center;
    justify-content: center;
  }

  .btnBack {
    width:90px;
    height:36px;
    line-height:36px;
    text-align:center;
    margin-left:15px;
    color:black;
    background:white;
    border-radius:10px;
    display:flex;
    align-items: center;
    justify-content: center;
  }

  .body1 {
    width: 98%;
    height: 85%;
    margin-left: 1%;
    margin-right: 1%;
    background: white;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
  }

  .param-item {
    display: flex;
    align-items: center;
    width: 100%;
    height: 36px;
  }

  .fasheng {
    width: 47%;
    margin-left: 2%;
    margin-right: 1%;
    margin-top: 10px;
    overflow: auto;
    border-radius: 5px;
  }

  .xiaochu {
    width: 47%;
    margin-left: 1%;
    margin-right: 2%;
    margin-top: 10px;
    overflow: auto;
    border-radius: 5px;
  }
  
  .param-header {
    display: flex;
    align-items: center;
    width: 100%;
    height: 36px;
    background-color: whitesmoke;
  }

}
</style>
