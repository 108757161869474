<template>
   <div class="dilu-page">
    <div class="search">
       <el-form ref="form" :model="searchParams" label-width="auto" class="search-form">
        <el-form-item label="固件类别">
            <el-select v-model="searchParams.stype" placeholder="请选择" style="width:150px;margin-right:15px;" clearable>
              <el-option label="4G模块" value=10 />
              <el-option label="通讯网关" value=20 />
              <el-option label="边缘服务器" value=30 />
            </el-select>
         </el-form-item>
         <el-form-item label="固件名称">
           <el-input v-model="searchParams.name" style="width:150px;margin-right:15px;"/>
         </el-form-item>
         <el-form-item label="固件版本">
          <el-input v-model="searchParams.version" style="width:150px;margin-right:15px;"/>
         </el-form-item>
         <el-form-item label="文件名称">
          <el-input v-model="searchParams.fileUrl" style="width:150px;margin-right:15px;"/>
         </el-form-item>
         <el-form-item label="启用状态">
            <el-select v-model="searchParams.status" placeholder="请选择" style="width:150px;margin-right:15px;" clearable>
              <el-option label="启用" value=10 />
              <el-option label="禁用" value=20 />
            </el-select>
         </el-form-item>
         <el-form-item label="上传日期">
          <el-date-picker type="daterange" placeholder="选择日期" value-format="yyyy-MM-dd" format="yyyy-MM-dd" v-model="searchParams.createdAt" style="width:236px" clearable/>
         </el-form-item>
       </el-form>
       <div class="search-submit">
        <el-button icon="el-icon-plus" type="primary" @click="add" style="margin-right:30px;">新增固件</el-button>
        <el-button icon="el-icon-search" type="success" @click="search">搜索</el-button>
        <el-button icon="el-icon-refresh" type="reset" @click="reset">重置</el-button>
      </div>
     </div>
     <DiluTable :list="tableData" :tableConfig="tableConfig" :pageSize="searchParams.pageSize" @currentChange="currentChange" :total="total">
       <el-table-column prop="stype" label="固件类别" width="130" align="left">
          <template slot-scope="scope">
              <span v-if="scope.row.stype == 10">4G模块</span>
              <span v-if="scope.row.stype == 20">通讯网关</span>
              <span v-if="scope.row.stype == 30">边缘服务器</span>
          </template>
       </el-table-column>
       <el-table-column prop="name" label="固件名称" width="180" align="left"/>
       <el-table-column prop="version" label="固件版本" width="120" align="left"/>
       <el-table-column prop="fileUrl" label="固件地址" width="400" align="left"/>
       <el-table-column prop="createdAt" label="上传日期" width="150" align="center"/>
       <el-table-column prop="createdBy" label="上传人员" width="120" align="center"/>
       <el-table-column prop="status" label="启用状态" width="120" align="center">
          <template slot-scope="scope">
              <span v-if="scope.row.status == 10">启用</span>
              <span v-if="scope.row.status == 20">禁用</span>
          </template>
       </el-table-column>
       <el-table-column prop="remark" label="操作" align="center" fixed="right" min-width="150">
          <template slot-scope="scope">
            <div class="table-operation">
              <el-button @click="edit(scope.row)" type="text">编辑</el-button>
              <el-button v-if="scope.row.status == 10" @click="disableStatus(scope.row)" type="text" size="small">禁用</el-button>
              <el-button v-else @click="enableStatus(scope.row)" type="text" size="small">启用</el-button>
            </div>
          </template>
       </el-table-column>
     </DiluTable>

     <!--新增DTU固件-->
     <AddFirmwareDtu :firmwareItem="firmwareItem" :editStatus="editStatus" :editVisible="editVisible" @handleClose="handleClose"/>

   </div>
 </template>
 
 <script>
 import AddFirmwareDtu from "./module/addFirmwareDtu.vue";
 import DiluTable from "@/components/public/dilu-table.vue";
 export default {
   data() {
     return {
       tableConfig: {
         isTree: false,
         isPage: true, 
       },
       searchParams: {
         stype: "",
         name: "",
         fileUrl: "", 
         version: "",
         status: "",
         createdAt: "",
         createdBy: "",
         pageNum: 1, 
         pageSize: 12,
       },
       editVisible: false, 
       editStatus: 0,
       firmwareItem: {},
       total: 0,
       tableData: [],
       editItem: {},
     };
   },
   components: {
     DiluTable,
     AddFirmwareDtu
   },
   mounted() {
     this.firmwareDtuList();
   },
   methods: {
     firmwareDtuList() {
      this.request.firmwareDtuList(this.searchParams).then((res) => {
        if (res.code == 0) {
          this.tableData=res.data;
          this.total=res.dataMaxCount;
        }
      });
     },
     search() {
       this.searchParams.pageNum = 1;
       this.total = 0;
       this.firmwareDtuList();
     },
     disableStatus(e) {
      var params={
        "id":e.id,
        "status":20
      }
      this.request.firmwareDtuStatus(params).then((res) => {
        if (res.code == 0) {
          this.$message.success("操作成功");
          e.status=20;
        }
      });
     },
     enableStatus(e) {
      var params={
        "id":e.id,
        "status":10
      }
      this.request.firmwareDtuStatus(params).then((res) => {
        if (res.code == 0) {
          this.$message.success("操作成功");
          e.status=10;
        }
      });
     },
     //翻页
     currentChange(e) {
       this.searchParams.pageNum = e;
       this.firmwareDtuList();
     },
     //关闭新增编辑弹窗
     dialogClose() {
       this.editVisible = false;
     },
     //新增
     add() {
       this.editStatus=0;
       this.editVisible = true;
       this.firmwareItem = {};
     },
     //编辑
     edit(e) {
       this.editStatus=1;
       this.firmwareItem = e;
       this.editVisible = true;
     },
     reset() {
      this.total = 0;
      this.searchParams.stype = "";
      this.searchParams.name = "";
      this.searchParams.fileUrl = "";
      this.searchParams.createdAt = "";
      this.searchParams.status = "";
      this.searchParams.version = "";
      this.firmwareDtuList();
    },
    handleClose() {
      this.editVisible=false;
      this.firmwareDtuList();
    },
   },
 };
 </script>
 
 <style scoped>
 </style>