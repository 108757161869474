<template>
  <div class="datalog-container">
    <template v-if="chartData.length > 0">
      <div
        class="device-chart"
        v-for="(i, index) in chartData"
        :key="index + '_' + i.field"
      >
        <div class="chart-box">
          <div class="title">
            <span>{{ i.field }}</span>
          </div>
          <div class="chart">
            <div :id="'chart_' + index" style="width: 100%;height: 100%;"></div>
          </div>
        </div>
      </div>
    </template>
    <mr-empty v-else />
  </div>
</template>

<script>
import VHeaderBox from "../components/v-header-box.vue";
import DTitleBg from "./components/d-title-bg.vue";
import VFooter from "../components/v-footer.vue";
import chartTemp from "./chartOpt/chartTemp";
import VPageDate from "../components/v-page-date.vue";
import * as echarts from "echarts";
import MrEmpty from "@/components/public/mr-empty.vue";
export default {
  name: "MrDatalog",
  components: {
    VHeaderBox,
    DTitleBg,
    VFooter,
    VPageDate,
    MrEmpty,
  },
  props: {
    propsData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      value: "1",
      chartData: [],
      chartOptions: {}, // 存放图表设置参数
    };
  },
  watch: {
    propsData: {
      deep: true,
      handler() {
        this.getData();
      },
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      const { dateValue, deviceId } = this.propsData;
      if (dateValue && deviceId) {
        this.getDateParams({
          deviceId,
          date: dateValue,
          items: "",
        });
      }

      // this.getHoursParams({
      //   deviceId,
      //   end: new Date(dateValue).getTime() * 24,
      //   items: "",
      //   start: new Date(dateValue).getTime() * 24
      // })
    },
    // 按天
    getDateParams(params) {
      // 处理图表数据
      this.chartOptions = {};
      this.request.readDateParams(params).then((res) => {
        const resData = res.data;
        resData.forEach((item, index) => {
          // 处理图表曲线的数据
          // 克隆图表基础配置
          this.chartOptions['chart_' + index] = JSON.parse(JSON.stringify(chartTemp));
          const currentChartOptions = this.chartOptions['chart_' + index];
          const valueArr = item.data.map((i) => i.value * 1);

          const xAxisData = item.data.map((i) => this.fmtTime(i.time));
          const minData = Math.min.apply(null, valueArr);
          const maxData = Math.max.apply(null, valueArr);
         
          const legendData = [item.field];
          const seiesData = [
            {
              name: item.field,
              type: "line",
              stack: 'Total',
              smooth: true,
              showSymbol: false,
              data: item.data.map((i) => i.value * 1),
            },
          ];
          currentChartOptions.yAxis.min = minData;
          currentChartOptions.yAxis.max = maxData;
          currentChartOptions.legend.data = legendData;
          currentChartOptions.xAxis.data = xAxisData;
          currentChartOptions.series = seiesData;
          this.chartOptions['chart_' + index] = currentChartOptions;
        });
        this.chartData = res.data;

        this.$nextTick(() => {
          for (const key in this.chartOptions) {
            this.initChart(key, this.chartOptions[key])
          }
        })
      });

      // setTimeout(() => {
      //   this.getDateParams(params)
      // }, 60*1000)
    },
    // 按小时
    // getHoursParams() {
    //   this.request.readHoursParam(params).then((res) => {
    //     this.chartData = res.data;
    //   });
    // },
    fmtTime(time) {
      const h = new Date(time).getHours();
      const m = new Date(time).getMinutes();
      const s = new Date(time).getSeconds();
      return `${this.setZero(h)}:${this.setZero(m)}:${s}`;
    },
    setZero(val) {
      return val >= 10 ? val : "0" + val;
    },
    initChart(id, options) {
      const chartDom = document.getElementById(id);
      const myChart = echarts.init(chartDom);
      myChart.setOption(options)
    }
  },
};
</script>

<style scoped lang="scss">
.device-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  ::v-deep .el-select {
    min-width: 173.25px;
    height: 36px;
    line-height: 36px;
    background: url("~@/assets/images/visual/rect1.png") no-repeat;
    background-size: 100% 100%;
    .el-input input {
      background-color: transparent;
      border: none;
      color: #ffffff;
      font-size: 16px;
    }
    .el-input__suffix {
      right: 15%;
    }
  }
}

.datalog-container {
  display: flex;
  flex-wrap: wrap;
  height: 800px;
  overflow-y: auto;
}

.device-chart {
  flex: 1;
  display: flex;
  justify-content: space-between;
  margin-top: 46px;
  .chart-box {
    width: 896px;
    height: 244px;
    opacity: 1;
    background: linear-gradient(
      180deg,
      rgba(27, 53, 56, 0.52) 0%,
      rgba(21, 52, 73, 0) 41%,
      rgba(13, 51, 97, 0.09) 100%
    );
    box-sizing: border-box;
    border: 1px solid;
    border-image: linear-gradient(
        275deg,
        rgba(0, 255, 221, 0.26) 2%,
        rgba(0, 255, 221, 0) 19%,
        rgba(0, 229, 255, 0) 82%,
        rgba(39, 233, 255, 0.66) 98%
      )
      1;
    padding: 24px 32px;
    margin-right: 16px;
    margin-bottom: 24px;
    .title {
      width: 173.25px;
      height: 24px;
      background: url("~@/assets/images/visual/rect.png") no-repeat;
      background-size: 100% 100%;
      padding-left: 24px;
      > span {
        font-family: "microsoft yahei ui","microsoft yahei";
        color: #ffffff;
        font-size: 16px;
      }
    }
    .chart {
      height: 144px;
    }
  }
}
</style>
