<template>
  <!-- 日志维护 -->
  <div class="dilu-page">
    <!-- 搜索区域 -->
    <div class="search">
      <el-form ref="form" :model="searchParams" label-width="auto" class="search-form">
        <el-form-item label="日志名称">
          <el-input v-model="searchParams.apiName" style="width:200px;"/>
        </el-form-item>
        <el-form-item label="日志路径">
          <el-input v-model="searchParams.apiUrl" style="width:300px;"/>
        </el-form-item>
      </el-form>
      <div class="search-submit">
        <el-button icon="el-icon-search" type="primary" @click="search">搜索</el-button>
        <el-button icon="el-icon-refresh" type="primary" @click="reset">重置</el-button>
      </div>
    </div>

    <!-- 按钮区域 -->
    <div class="operation-div">
      <el-button icon="el-icon-plus" type="primary" @click="add">新增</el-button>
    </div>

    <!-- 表格 -->
    <DiluTable :list="tableData" :tableConfig="tableConfig">
      <el-table-column prop="date" label="#" width="55" align="center">
        <template slot-scope="scope">
          <span>{{ scope.$index + 1 }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="apiName" width="300" label="日志名称" align="left">
      </el-table-column>
      <el-table-column prop="requestUrl" width="500" label="URL" align="left">
      </el-table-column>
      <el-table-column prop="address" label="操作" align="center" fixed="right">
        <template slot-scope="scope">
          <div class="table-operation">
            <el-button @click="edit(scope.row)" type="text">编辑</el-button>
            <el-button @click="remove(scope.row)" type="text">删除</el-button>
          </div>
        </template>
      </el-table-column>
    </DiluTable>

    <AddLogInfo
      :addLogInfoVisible="addLogInfoVisible"
      :isAdd="isAdd"
      :currentRole="currentRole"
      @addLogInfoClose="addLogInfoClose"
      @apiList="apiList"
    />
  </div>
</template>


<script>
import { mapState } from "vuex";
import DiluTable from "@/components/public/dilu-table.vue";
import AddLogInfo from "./module/addLogInfo.vue";
export default {
  data() {
    return {
      addLogInfoVisible: false,
      searchParams: {
        apiUrl: "",  //日志路径
        apiName: "", //日志名称
      },
      tableData: [],
      tableConfig: {
        isTree: false,
        isPage: false,
      },
      currentRole: {},
      isAdd: 1, // 新增1，编辑2，
    };
  },
  components: {
    DiluTable,
    AddLogInfo,
  },
  mounted() {
    this.apiList();
  },
  computed: {
    ...mapState(["buttonTextColor"]),
  },
  methods: {
    addLogInfoClose() {
      this.addLogInfoVisible = false;
    },
    //搜索
    search() {
      this.total = 0;
      this.searchParams.pageNum = 1;
      this.apiList();
    },
    reset() {
      this.searchParams = {
        apiName: "", //日志类别
      };
      this.apiList();
    },
    //获取列表
    apiList() {
      this.request.apiList(this.searchParams).then((res) => {
        this.tableData = res.data;
        this.total = res.dataMaxCount;
      });
    },
    //新增
    add() {
      this.isAdd = 1;
      this.addLogInfoVisible = true;
    },
    //编辑
    edit(e) {
      this.isAdd = 2;
      this.currentRole = e;
      this.addLogInfoVisible = true;
    },
    //删除
    remove(e) {
      this.$confirm("此操作将永久删除此条日志信息,是否继续？", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.request.apiDelete({ id: e.id }).then(() => {
            this.$message({
              type: "success",
              message: "删除成功",
            });
            this.apiList();
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
</style>