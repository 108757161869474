<template>
  <div class="dilu-page">
    <DiluTable :list="tableData" :tableConfig="tableConfig" :pageSize="searchParams.pageSize" @currentChange="currentChange" :total="total">
      <el-table-column prop="id" label="ID" align="center"/>
      <el-table-column prop="userId" label="客户ID" align="center"/>
      <el-table-column prop="mobile" label="客户手机号" align="center"/>
      <el-table-column prop="projectCode" label="项目编码" align="center"/>
      <el-table-column prop="createdAt" label="创建时间" align="center"/>
      <el-table-column prop="remark" label="操作" align="center">
         <template slot-scope="scope">
           <div class="table-operation">
             <el-button @click="remove(scope.row)" type="text">移除</el-button>
           </div>
         </template>
      </el-table-column>
    </DiluTable>
  </div>
</template>

<script>
import { mapState } from "vuex";
import DiluTable from "@/components/public/dilu-table.vue";
export default {
  data() {
    return {
      searchParams: {
        project: null,
        pageNum: 1, 
        pageSize: 12,
      },
      tableConfig: {
        isTree: false,
        isPage: true, 
      },
      total: 0,
      tableData: [],
    };
  },
  components: {
    DiluTable
  },
  computed: {
    ...mapState(["projectCurrentRowData"]),
  },
  mounted() {
    this.searchParams.project=this.projectCurrentRowData.projectCode;
     this.projectUsers();
  },
  methods: {
     projectUsers() {
      this.request.listUser(this.searchParams).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data;
          this.total = res.dataMaxCount;
        }
      });
     },
     remove(e) {
      this.$confirm("确认要移除客户关注该项目么?", "提醒", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.request.removeUser({"id":e.id,"project":e.projectCode}).then((res) => {
          if (res.code==0) {
            this.projectUsers();
          }
        });
      });
     },
     //翻页
     currentChange(e) {
        this.searchParams.pageNum = e;
        this.projectUsers();
     },
  },
};
</script>

<style scoped>
</style>