<template>
  <div class="v-card">
    <v-arrow-bg>
      <template #header>全屋空气质量评价</template>
      <div class="v-card-container">
        <div class="chart-box">
          <mr-echarts :options="options" />
        </div>
        <div class="chart-label">
          <div class="title">空气质量评价</div>
          <p class="label great">优</p>
        </div>
      </div>
    </v-arrow-bg>
  </div>
</template>

<script lang="js">
import VArrowBg from "../v-arrow-bg.vue";
import MrEcharts from "@/components/public/mr-echarts.vue";

export default {
  name: 'VrightCard1',
  components: {
    VArrowBg,
    MrEcharts
  },
  props: {
    propsData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  watch: {
    propsData: {
      deep: true,
      handler() {
        this.screenData = Object.assign({}, this.propsData);
        this.renderChart()
      }
    }
  },
  data() {
    return {
      screenData: {},
      options: {}
    }
  },
  methods: {
    renderChart() {
      this.options = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          show: false
        },
        radar: {
          center: ['45%', '55%'],
          indicator: [
            { text: '温度', max: 100 },
            { text: '湿度', max: 100 },
            { text: 'CO2', max: 100 },
            { text: 'PM2.5', max: 100 },
            { text: 'TVOC', max: 100 }
          ],
          splitArea: {
            show: false,
          },
          name: {
            textStyle: {
              color: "rgba(255, 255, 255, 0.8)"
            }
          },
          label: {
            show: true,
          },
          splitLine: {
            lineStyle: {
              color: "rgba(255, 255, 255, 0.2)"
            }
          },
        },
        series: {
          type: 'radar',
          tooltip: {
            trigger: 'item'
          },
          symbol: "none",
          areaStyle: {
            color: "rgba(33, 248, 255, 0.4)"
          },
          lineStyle: {
            color: "rgba(255, 255, 255, 0.2)"
          },
          data: [
            {
              value: [
                this.screenData.temprature,
                this.screenData.humidity,
                this.screenData.co2,
                this.screenData.pm25,
                this.screenData.tvoc
              ],
              name: '空气质量评价',
            }
          ]
        }
      }
    }
  }
}
</script>
<style scoped lang="scss">
.v-card {
  width: 321.33px;
  height: 248px;
  border-radius: 2px;
  .v-card-container {
    width: 100%;
    height: calc(100% - 40px);
    padding-top: 8;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .chart-box {
      width: 213px;
      height: 164px;
    }
    .chart-label {
      width: 90px;
      height: 164px;
      background: rgba(162, 240, 223, 0.04);
      .title {
        height: 23px;
        opacity: 1;
        background: #0d3c41;
        line-height: 23px;
        font-family: "microsoft yahei ui","microsoft yahei";
        font-size: 12px;
        color: #9cf5ff;
        position: relative;
        padding-left: 8px;
        &::before {
          content: "";
          display: inline-block;
          width: 1px;
          height: 100%;
          opacity: 1;
          background: #32e8e2;
          position: absolute;
          left: 0;
          top: 0;
        }
      }

      .label {
        font-family: "microsoft yahei ui","microsoft yahei";
        font-size: 22px;
        text-align: center;
        line-height: 106px;

        // 根据不同等级 颜色不同
        &.great {
          background: linear-gradient(
            180deg,
            rgba(30, 255, 206, 0.6118) 0%,
            #88ff00 96%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
        }
      }
    }
  }
}
</style>
