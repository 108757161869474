<template>
  <div class="dilu-page">
   <div class="search">
      <el-form ref="form" :model="searchParams" label-width="auto" class="search-form">
        <el-form-item label="设备类型">
           <el-select v-model="searchParams.category" placeholder="请选择" style="width:130px;margin-right:10px;" @change="getModelList" clearable>
            <el-option v-for="(item, index) in categoryList" :key="index" :label="item.name" :value="item.id"/>
           </el-select>
        </el-form-item>
        <el-form-item label="设备型号">
           <el-select v-model="searchParams.model" placeholder="请选择" style="width:130px;margin-right:10px;" clearable>
            <el-option v-for="(item, index) in modelList" :key="index" :label="item.name" :value="item.id"/>
           </el-select>
        </el-form-item>
        <el-form-item label="制造商">
           <el-select v-model="searchParams.manufacturer" placeholder="请选择" style="width:130px;margin-right:10px;" clearable>
            <el-option v-for="(item, index) in manufacturerList" :key="index" :label="item.name" :value="item.id"/>
           </el-select>
        </el-form-item>
        <el-form-item label="固件名称">
          <el-input v-model="searchParams.name" style="width:130px;margin-right:10px;"/>
        </el-form-item>
        <el-form-item label="固件版本">
         <el-input v-model="searchParams.version" style="width:130px;margin-right:10px;"/>
        </el-form-item>
        <el-form-item label="文件名称">
         <el-input v-model="searchParams.fileUrl" style="width:130px;margin-right:10px;"/>
        </el-form-item>
        <el-form-item label="启用状态">
           <el-select v-model="searchParams.status" placeholder="请选择" style="width:130px;margin-right:10px;" clearable>
             <el-option label="启用" value=10 />
             <el-option label="禁用" value=20 />
           </el-select>
        </el-form-item>
        <el-form-item label="上传日期">
         <el-date-picker type="daterange" placeholder="选择日期" value-format="yyyy-MM-dd" format="yyyy-MM-dd" v-model="searchParams.createdAt" style="width:236px" clearable/>
        </el-form-item>
      </el-form>
      <div class="search-submit">
       <el-button icon="el-icon-plus" type="primary" @click="add" style="margin-right:10px;">新增固件</el-button>
       <el-button icon="el-icon-search" type="success" @click="search">搜索</el-button>
       <el-button icon="el-icon-refresh" type="reset" @click="reset">重置</el-button>
     </div>
    </div>
    <DiluTable :list="tableData" :tableConfig="tableConfig" :pageSize="searchParams.pageSize" @currentChange="currentChange" :total="total">
      <el-table-column prop="categoryName" label="设备类型" width="180" align="left"/>
      <el-table-column prop="modelName" label="设备型号" width="180" align="left"/>
      <el-table-column prop="manufacturerName" label="制造商" width="180" align="left"/>
      <el-table-column prop="name" label="固件名称" width="180" align="left"/>
      <el-table-column prop="version" label="固件版本" width="120" align="left"/>
      <el-table-column prop="fileUrl" label="固件地址" width="400" align="left"/>
      <el-table-column prop="createdAt" label="上传日期" width="150" align="center"/>
      <el-table-column prop="createdBy" label="上传人员" width="120" align="center"/>
      <el-table-column prop="status" label="启用状态" width="120" align="center">
         <template slot-scope="scope">
             <span v-if="scope.row.status == 10">启用</span>
             <span v-if="scope.row.status == 20">禁用</span>
         </template>
      </el-table-column>
      <el-table-column prop="remark" label="操作" align="center" fixed="right" min-width="150">
         <template slot-scope="scope">
           <div class="table-operation">
             <el-button @click="edit(scope.row)" type="text">编辑</el-button>
             <el-button v-if="scope.row.status == 10" @click="disableStatus(scope.row)" type="text" size="small">禁用</el-button>
             <el-button v-else @click="enableStatus(scope.row)" type="text" size="small">启用</el-button>
           </div>
         </template>
      </el-table-column>
    </DiluTable>

    <!--新增设备固件-->
    <AddFirmwareDevice :firmwareItem="firmwareItem" :editStatus="editStatus" :editVisible="editVisible" @handleClose="handleClose"/>

  </div>
</template>

<script>
import AddFirmwareDevice from "./module/addFirmwareDevice.vue";
import DiluTable from "@/components/public/dilu-table.vue";
export default {
  data() {
    return {
      tableConfig: {
        isTree: false,
        isPage: true, 
      },
      searchParams: {
        category: null,
        model: null,
        manufacturer: null,
        name: "",
        fileUrl: "", 
        version: "",
        status: "",
        createdAt: "",
        createdBy: "",
        pageNum: 1, 
        pageSize: 12,
      },
      editVisible: false, 
      editStatus: 0,
      firmwareItem: {},
      total: 0,
      tableData: [],
      editItem: {},
      categoryList: [],
      modelList: [],
      manufacturerList: [],
      
    };
  },
  components: {
    DiluTable,
    AddFirmwareDevice
  },
  mounted() {
    this.firmwareDeviceList();
    this.getCategoryList();
    this.getManufacturerList();
  },
  methods: {
    getCategoryList() {
      this.request.categoryList().then((res) => {
        if (res.code==0) {
          this.categoryList = res.data;
        }
      });
    },
    getModelList() {
      if (this.searchParams.category!=null) {
        var params={
          category: this.searchParams.category
        };
        this.request.modelList(params).then((res) => {
          if (res.code==0) {
            this.modelList = res.data;
          }
        });
      } else {
        this.modelList=[];
      }
    },
    getManufacturerList() {
      this.request.manufacturerList().then((res) => {
        if (res.code==0) {
          this.manufacturerList = res.data;
        }
      });
    },
    firmwareDeviceList() {
     this.request.firmwareDeviceList(this.searchParams).then((res) => {
       if (res.code == 0) {
         this.tableData=res.data;
         this.total=res.dataMaxCount;
       }
     });
    },
    search() {
      this.searchParams.pageNum = 1;
      this.total = 0;
      this.firmwareDeviceList();
    },
    disableStatus(e) {
     var params={
       "id":e.id,
       "status":20
     }
     this.request.firmwareDtuStatus(params).then((res) => {
       if (res.code == 0) {
         this.$message.success("操作成功");
         e.status=20;
       }
     });
    },
    enableStatus(e) {
     var params={
       "id":e.id,
       "status":10
     }
     this.request.firmwareDtuStatus(params).then((res) => {
       if (res.code == 0) {
         this.$message.success("操作成功");
         e.status=10;
       }
     });
    },
    //翻页
    currentChange(e) {
      this.searchParams.pageNum = e;
      this.firmwareDeviceList();
    },
    //关闭新增编辑弹窗
    dialogClose() {
      this.editVisible = false;
    },
    //新增
    add() {
      this.editStatus=0;
      this.editVisible = true;
      this.firmwareItem = {};
    },
    //编辑
    edit(e) {
      this.editStatus=1;
      this.firmwareItem = e;
      this.editVisible = true;
    },
    reset() {
     this.total = 0;
     this.searchParams.stype = "";
     this.searchParams.name = "";
     this.searchParams.fileUrl = "";
     this.searchParams.createdAt = "";
     this.searchParams.status = "";
     this.searchParams.version = "";
     this.firmwareDeviceList();
   },
   handleClose() {
     this.editVisible=false;
     this.firmwareDeviceList();
   },
  },
};
</script>

<style scoped>
</style>