<template>
  <!-- 左侧菜单 -->
  <div v-if="routerPath.children!=null&&routerPath.children.length > 0 && indexActive.length > 0" class="page-header">
    <div v-if="routerPath.children[indexActive[0]].children[indexActive[1]].hidden">
      <div class="title">
        <i class="el-icon-back back" @click="back"></i>
        {{routerPath.children[indexActive[0]].children[indexActive[1]].meta.title}}
      </div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>
          {{routerPath.children[indexActive[0]].meta.title}}
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          {{routerPath.children[indexActive[0]].children[indexActive[1]].meta.title}}
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div v-else>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>
          {{routerPath.children[indexActive[0]].meta.title}}
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          {{routerPath.children[indexActive[0]].children[indexActive[1]].meta.title}}
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {};
  },
  watch: {
    routerPath: {
      handler() {
       
      },
    },
  },
  computed: {
    ...mapState(["indexActive", "routerPath"]),
  },
  mounted() {
    
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.page-header {
  margin-bottom: 20px;
  border-bottom: 1px solid whitesmoke;
  padding-bottom: 20px;
  font-weight: bold;
}
.title {
  font-size: 14px;
  font-family: "microsoft yahei ui","microsoft yahei";
  font-weight: bold;
  color: #000000;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}
.back {
  font-size: 18px;
  margin-right: 16px;
  cursor: pointer;
}
</style>