<template>
  <div class="dilu-page">
    <div class="page-header">
      <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item> 项目管理 </el-breadcrumb-item>
          <el-breadcrumb-item>
            {{ setTitle }}
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="form-body">
      <div class="body-title">
        <div class="title">{{ setTitle }}</div>
        <div class="title-right">
          <div class="title-back" @click="back">
            <el-button type="text">返回</el-button>
          </div>
        </div>
      </div>

      <div class="form-container">
        <el-tabs v-model="activeName" >
          <el-tab-pane label="基本信息" name="baseInfo">
            <base-info />
          </el-tab-pane>
          <el-tab-pane label="建筑布局" name="second">
            <place-info  :msg.sync="activeName" />
          </el-tab-pane>
          <el-tab-pane label="设备管理" name="third">
            <device-info :msg.sync="activeName"  />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>

</template>

<script>
import BaseInfo from "./components/BaseInfo.vue";
import deviceInfo from "./components/deviceInfo.vue";
import placeInfo from "./components/placeInfo.vue";
import { mapState } from "vuex";

export default {
  components: {
    BaseInfo,
    placeInfo,
    deviceInfo,
  },
  data() {
    return {
      isAdd: "",
      currentData: "{}",
      activeName: "baseInfo"
    };
  },
  computed: {
    setTitle() {
      let title = "";
      if (this.isAdd == 1) {
        title = "新建项目"
      } else if (this.isAdd == 2) {
        title = "编辑项目"
      } else if (this.isAdd == 3) {
        title = "查看项目"
      }
      return title
    },
    ...mapState(["projectCurrentRowData"])
  },
  mounted() {
    this.isAdd = this.$route.query.isAdd;
    this.currentData = this.projectCurrentRowData;
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.form-body {
  padding: 20px;
  box-sizing: border-box;
  background-color: white;
  margin: 10px 0;
  padding-right: 0;
}

.body-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
}

.body-title .title-right>div {
  display: inline-block;
  margin-left: 24px;
}

.body-title .title-right .title-back .el-button--text {
  color: rgba(0, 0, 0, 0.6);
}

.dialog-footer {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 560px;
  text-align: right;
}

.page-header {
  margin: 10px 0;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 20px;
  font-weight: bold;
  background-color: white;
}

.title {
  font-size: 18px;
  font-family: "microsoft yahei ui","microsoft yahei";
  font-weight: bold;
  color: #000000;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.back {
  font-size: 26px;
  margin-right: 16px;
  cursor: pointer;
}

.form-container {
  height: 100%;
  overflow: hidden;
}
</style>
