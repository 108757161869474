export default {
  tooltip: {
    trigger: 'item'
  },
  legend: {
    show: false
  },
  series: [
    {
      name: '故障登等级',
      type: 'pie',
      radius: ['40%', '50%'],
      data: [
        { value: 1048, name: '一般', itemStyle: { }},
        { value: 735, name: '轻微' },
        { value: 580, name: '严重' }
      ],
      label: {
        color: "rgba(255, 255, 255, 0.6)"
      }
    }
  ]
}