<template>
  <ScaleBox>
  <div class="mr-energy">
    <v-header-box></v-header-box>
    <div class="mr-energy-container">
      <div class="tab-box">
        <div class="tab">
          <span :class="active == 0 ? 'active' : ''" @click="changeTab(0)">能耗统计</span>
          <span :class="active == 1 ? 'active' : ''" @click="changeTab(1)">能流统计</span>
        </div>
      </div>

      <template v-if="active == 0">
        <Page1 />
      </template>

      <template v-if="active == 1">
        <Page2 />
      </template>
    </div>
    <v-footer></v-footer>
  </div>
</ScaleBox>
</template>


<script>
import VHeaderBox from "../components/v-header-box.vue";
import VFooter from "../components/v-footer.vue";
import Page1 from "./page1.vue";
import Page2 from "./page2.vue";
import ScaleBox from "@/components/view/scaleBox.vue";
export default {
  name: "MrDatalog",
  components: {
    VHeaderBox,
    VFooter,
    Page1,
    Page2,
    ScaleBox
  },
  data() {
    return {
      list: [
        {
          name: "COP能效比",
          level: "一级",
          unit: "",
          link: "538",
          linkType: 1,
          grow: 454,
          growType: 0
        },
        {
          name: "碳减排统计",
          level: "4,890",
          unit: "kw/h",
          link: "538",
          linkType: 1,
          grow: 454,
          growType: 0
        },
        {
          name: "建筑总能耗",
          level: "4,890",
          unit: "kw/h",
          link: "538",
          linkType: 1,
          grow: 454,
          growType: 0
        },
        {
          name: "今日用电负荷率",
          level: "今日用电负荷率",
          unit: "",
          link: null,
          linkType: null,
          grow: null,
          growType: null,
          type: "高负荷"
        },
        {
          name: "碳排总面积",
          level: "890",
          unit: "m",
          link: "538",
          linkType: 1,
          grow: 454,
          growType: 0
        },
        {
          name: "单位面积能耗",
          level: "50",
          unit: "kwh/m2",
          link: "538",
          linkType: 1,
          grow: 454,
          growType: 0
        },
      ],
      chart: null,
      chart: null,
      active: 0
    }
  },
  mounted() {
  },
  methods: {
    changeTab(index) {
      if (this.active == index) return;
      this.active = index;
    }
  }
}
</script>

<style scoped lang="scss">
.mr-energy {
  height: 100%;
  background: #1b1c20;
  background-size: 100% 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  .mr-energy-container {
    padding: 24px;
    flex: 1;
    overflow: hidden;
    .tab-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .tab {
        >span {
          display: inline-block;
          width: 121px;
          height: 28px;
          border-radius: 1px;
          opacity: 1;
          box-sizing: border-box;
          font-size: 14px;
          text-align: center;
          line-height: 28px;
          color: #7C7C7C;
          cursor: pointer;
          &.active {
            color: #FFFFFF;
            background: url("~@/assets/images/visual/rect1.png") no-repeat;
            background-size: 100% 100%;
          }
        }
      }
    }

    .mr-energy-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .list {
        width: 610px;
        height: 127px;
        background: url('./images/card_bg.png') no-repeat;
        background-size: 100% 100%;
        margin-bottom: 24px;
        .count {
          height: 82px;
          display: flex;
          justify-content: space-around;
          align-items: center;
          span:nth-child(1) {
            font-family: "microsoft yahei ui","microsoft yahei";
            font-size: 16px;
            font-weight: 500;
            text-align: right;
            color: #FFFFFF;
          }
          span:nth-child(2) {
            font-family: "microsoft yahei ui","microsoft yahei";
            font-size: 32px;
            font-weight: 500;
            line-height: 36px;
            text-align: right;
            color: #FFFFFF;
          }
          span.unit {
            font-family: "microsoft yahei ui","microsoft yahei";
            font-size: 12px;
            color: #FFFFFF;
            margin-left: 24px;
          }
        }
        .rate {
          height: 45px;
          line-height: 45px;
          display: flex;
          align-items: flex-end;
          justify-content: space-around;
          span {
            font-size: 12px;
          }

          .num {
            font-size: 14px;
            margin: 0 8px;
            font-weight: bold;
            margin-left: 12px;
          }

        }

        span.type {
          font-size: 16px;
          color: #FF8400;
          text-align: right;
        }
      }
    }


    .mr-energy-chart {
      display: flex;
      justify-content: space-between;
      >div {
        width: 922px;
        height: 551px;
        opacity: 1;
        background: linear-gradient(180deg, rgba(27, 53, 56, 0.52) 0%, rgba(21, 52, 73, 0) 41%, rgba(13, 51, 97, 0.09) 100%);
        box-sizing: border-box;
        border: 1px solid ;
        border-image: linear-gradient(281deg, rgba(0, 255, 221, 0.26) 6%, rgba(0, 255, 221, 0) 22%, rgba(0, 229, 255, 0) 81%, rgba(39, 233, 255, 0.66) 96%) ;
        padding: 24px 12px;
      }
      .energy-info-box {
        .chart-top  {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .info {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .img {
            width: 78px;
            height: 78px;
            background: url('./images/bg.png') no-repeat;
            background-size: 100% 100%;
            text-align: center;
            position: relative;
            margin-right: 24px;
            img {
              width: 24px;
              height: 24px;
              position: absolute;
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
              margin: auto;
            }
          }

          .count-box {

            p:nth-child(1) {
              font-family: "microsoft yahei ui","microsoft yahei";
              font-size: 14px;
              color: rgba(255, 255, 255, 0.6);
              
            }
            p:nth-child(2) {
              margin: 28px 0 24px;
              .count {
                font-family: DIN Alternate;
                font-size: 22px;
                font-weight: bold;
                text-align: right;
                color: #FFFFFF;
              }
              .unit {
                font-family: "microsoft yahei ui","microsoft yahei";
                font-size: 12px;
                color: rgba(255, 255, 255, 0.5);
                margin-left: 24px;
              }
            }
            p.rate {
              display: flex;
              align-items: center;
              >span {
                font-family: "microsoft yahei ui","microsoft yahei";
                font-size: 12px;
                line-height: 20px;
                display: flex;
                align-items: center;
                color: rgba(255, 255, 255, 0.6);
                background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(52, 214, 187, 0.135) 100%);
                padding: 0 4px;
              }
              .num {
                font-family: "microsoft yahei ui","microsoft yahei";
                font-size: 12px;
                line-height: 20px;
                display: flex;
                align-items: center;
              }
            }
          }
        }


        .chart-box {
          .title {
            width: 238.38px;
            height: 24px;
            background: url("~@/assets/images/visual/rect.png") no-repeat;
            background-size: 100% 100%;
            padding-left: 24px;
            margin: 24px 0;
            >span {
              font-family: "microsoft yahei ui","microsoft yahei";
              color: #FFFFFF;
              font-size: 16px;
            }
          }
          .chart {
            height: 290px;
          }
        }
      }
    }
  }

  .up {
    color: #F53F3F;
    &::after {
      content: "";
      display: inline-block;
      width: 18px;
      height: 16px;
      background:url('~@/assets/images/visual/arrow_up.png') no-repeat;
      background-size: 100% 100%;
      margin-left: 8px;
    }
  }

  .down {
    color: #43CB75;
    &::after {
      content: "";
      display: inline-block;
      width: 18px;
      height: 16px;
      background:url('~@/assets/images/visual/arrow_down.png') no-repeat;
      background-size: 100% 100%;
      margin-left: 8px;
    }
  }
}
</style>