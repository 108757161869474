<template>
  <div class="v-card">
    <v-arrow-bg>
      <template #header>能源统计</template>
      <div class="v-card-container">
        <mr-echarts :options="options" />
      </div>
    </v-arrow-bg>
  </div>
</template>

<script lang="js">
import VArrowBg from "../v-arrow-bg.vue";
import MrEcharts from "@/components/public/mr-echarts.vue";

export default {
  name: 'VrightCard3',
  components: {
    VArrowBg,
    MrEcharts
  },
  props: {
    propsData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  watch: {
    propsData: {
      deep: true,
      handler() {
        this.screenData = Object.assign({}, this.propsData);
        this.renderChart()
      }
    }
  },
  data() {
    return {
      screenData: {},
      options: {}
    }
  },
  methods: {
    renderChart() {
      const value1 = [10, 20, 33, 44];
      const value2 = [22, 33, 45, 35];
      const value3 = [23, 34, 14, 25];
      const value4 = [56, 34, 78, 56];
      const axis = [1, 2, 3, 4];

      this.options = {
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: ['光伏发电', '电网补充电', '系统用电', '机组耗电'],
            icon: "rect",
            itemWidth: 10,
            itemHeight: 10,
            right: 0,
            textStyle: {
            color: "#fff"
            }
          },
          grid: {
            top: "20%",
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: axis,
            axisLabel: {
              color: "#fff",
            },
            axisLine: {
              lineStyle: {
                type: "dashed",
                color: "#fff",
                opacity: 0.4
              }
            },
            axisTick: {
              show: false
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                opacity: 0.4
              }
            }
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              color: "#fff",
              rotate: 30
            },
            axisLine: {
              show: false
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                opacity: 0.4
              }
            }
          },
          series: [
            {
              name: '光伏发电',
              type: 'line',
              stack: 'Total',
              data: value1,
              itemStyle: {
                color: "#02FFAF"
              }
            },
            {
              name: '电网补充点',
              type: 'line',
              stack: 'Total',
              data: value2,
              itemStyle: {
                color: "#FFBB32"
              }
            },
            {
              name: '系统用电',
              type: 'line',
              stack: 'Total',
              data: value3,
              itemStyle: {
                color: "#3284FF"
              }
            },
            {
              name: '机组耗电',
              type: 'line',
              stack: 'Total',
              data: value4,
              itemStyle: {
                color: "#FF326C"
              }
            },
          ]
        };
    }
  }
}
</script>
<style scoped lang="scss">
.v-card {
  width: 321.33px;
  height: 248px;
  border-radius: 2px;
  .v-card-container {
    width: 100%;
    height: calc(100% - 40px);
    padding: 16px 0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
