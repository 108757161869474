<template>
  <el-dialog title="项目二维码" :visible.sync="viewVisible" width="500px" :before-close="handleClose" :close-on-click-modal="false" center>
    <canvas ref="qrcodeCanvas" style="margin-left:25px;"/>
  </el-dialog>
</template>

<script lang="js">
import QRCode from 'qrcode';

export default {
  name: 'FormTitle',
  props: ["viewVisible","projectCode"],
  data() {
    return {}
  },
  watch: {
    viewVisible(newVal) {
      if (newVal) {
        setTimeout(()=>{
          this.generateQRCode();
        },1000);
      }
    },
  },
  methods: {
    generateQRCode() {
      const canvas = this.$refs.qrcodeCanvas;
      QRCode.toCanvas(canvas, this.projectCode, {width:400,height:400}, error => {if (error) console.error(error)});
    },
    handleClose() {
      this.$emit("handleClose");
    }
  }
}

</script>
<style scoped lang="scss">

</style>