<template>
  <div class="dilu-page">
    <div class="project-search">
      <el-form :inline="true" :model="searchParams" label-width="auto" class="search-form">
        <el-form-item label="事件状态">
          <el-select v-model="searchParams.status" style="width:120px;margin-right:20px;" clearable>
            <el-option label="未应答" value=10 />
            <el-option label="执行成功" value=20 />
            <el-option label="执行失败" value=30 />
            <el-option label="执行超时" value=40 />
            <el-option label="其他失败" value=50 />
          </el-select>
        </el-form-item>
        <el-form-item label="操作人ID">
          <el-input v-model="searchParams.userId" style="width:120px;margin-right:20px;" clearable/>
        </el-form-item>
        <el-form-item label="设备ID">
          <el-input v-model="searchParams.deviceId" style="width:120px;margin-right:20px;" clearable/>
        </el-form-item>
        <el-form-item label="事件日期">
          <el-date-picker type="daterange" placeholder="选择日期" value-format="yyyy-MM-dd" format="yyyy-MM-dd" v-model="searchParams.createdAt" style="width: 236px" clearable/>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="goSearch" style="width:70px;">查 询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="project-table">
      <DiluTable :list="tableData" :tableConfig="tableConfig" :total="total" :pageSize="searchParams.pageSize" @currentChange="currentChange">
        <el-table-column label="设备项目" align="left" width="300">
          <template slot-scope="scope">
            {{ scope.row.projectName + '-' + scope.row.categoryName }}
          </template>
        </el-table-column>
        <el-table-column prop="topic" label="执行主题" align="center" width="120"/>
        <el-table-column prop="status" label="执行结果" align="center" width="120">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 10">未应答</span>
            <span v-if="scope.row.status == 20">执行成功</span>
            <span v-if="scope.row.status == 30">执行失败</span>
            <span v-if="scope.row.status == 40">执行超时</span>
            <span v-if="scope.row.status == 50">其他失败</span>
          </template>
        </el-table-column>
        <el-table-column prop="createdAt" label="执行时间" align="center" width="180"/>
        <el-table-column prop="updatedAt" label="应答时间" align="center" width="180"/>
        <el-table-column prop="userId" label="执行人ID" align="center" width="100"/>
        <el-table-column prop="userName" label="执行人账号" align="center" width="150"/>
        <el-table-column prop="client" label="发起客户端" align="center" width="120">
          <template slot-scope="scope">
            <span v-if="scope.row.client == 'adm'">WEB后台</span>
            <span v-if="scope.row.client == 'mini'">C端小程序</span>
            <span v-if="scope.row.client == 'app'">C端APP</span>
            <span v-if="scope.row.client == 'miniTool'">B端小程序</span>
            <span v-if="scope.row.client == 'appTool'">B端APP</span>
          </template>
        </el-table-column>
        <el-table-column prop="deviceId" label="设备ID" align="center" width="100"/>
        <el-table-column prop="gateway" label="设备网关" align="center" width="180" />
        <el-table-column prop="lastOnline" label="最近在线时间" align="left" width="180"/>
        <el-table-column label="操作" align="center" width="130" fixed="right">
          <template slot-scope="scope">
            <div class="table-operation">
              <el-button @click="reqDetail(scope.row)" type="text">请求</el-button>
              <el-button @click="replyDetail(scope.row)" type="text">应答</el-button>
            </div>
          </template>
        </el-table-column>
      </DiluTable>
    </div>

     <!-- 数据详情 -->
     <el-dialog title="数据详情" :visible.sync="detailVisible" width="600px" padding-top="10px">
      <json-viewer :value="jsonData" :preview-mode="true" :show-array-index="false" copyable show-double-quotes />
    </el-dialog>

  </div>
</template>
 
 <script>
 import DiluTable from "@/components/public/dilu-table.vue";
 import { mapState } from "vuex";
 export default {
   data() {
     return {
      searchParams: {
        pageSize: 12,
        pageNum: 1,
        project: null,
        status: null,
        userId: null,
        deviceId: null,
        topic: null,
        cratedAt: []
      },
      tableConfig: {
        isTree: false,
        isPage: true,
      },
      projectInfo: {},
      tableData: [],
      total: 0,
      jsonData: {},
      detailVisible: false
     };
   },
   components: {
    DiluTable,
   },
   computed: {
    ...mapState(["projectCurrentRowData"]),
   },
   mounted() {
    this.projectInfo=this.projectCurrentRowData;
    this.searchParams.project=this.projectInfo.projectCode;
    this.goSearch();
   },
   methods: {
    goSearch() {
      this.request.deviceEvents(this.searchParams).then((res) => {
        if (res.code == 0) {
          this.tableData=res.data;
          this.total=res.dataMaxCount;
        }
      });
    },
    currentChange(e) {
      this.searchParams.pageNum = e;
      this.goSearch();
    },
    reqDetail(e) {
      if (e.send!=null&&e.send.length>10) {
        this.jsonData = JSON.parse(e.send);
        this.detailVisible = true;
      } else {
        this.$message.error("数据为空");
      }
    },
    replyDetail(e) {
      if (e.reply!=null&&e.reply.length>10) {
        this.jsonData = JSON.parse(e.reply);
        this.detailVisible = true;
      } else {
        this.$message.error("数据为空");
      }
    }
  }
 };
 </script>
 
 <style scoped>

 .project-search {
    margin: 12px 0;
  }

  .project-table {
    height: 100%;
  }

 </style>