<template>
  <div class="mr-break-top">
    <div class="break-top-card">
      <div class="break-status">
        <img src="../images/progress_bg1.png" alt="">
      </div>
      <div class="break-num">
        <p>设备故障总数</p>
        <p>{{ faultData.faultTotal || 0 }}</p>
      </div>
      <div class="break-chart">
        <div class="chart" v-for="i, index in faultData.faultLevels" :key="index">
          <div class="progress-box">
            <div :class="['progress', 'progress' + (index+1)] " :style="{ height: fmtRateForTotal(i.value) + '%'}"></div>
          </div>
          <p>{{ i.name }}</p>
        </div>
      </div>
    </div>
    <div class="break-top-card">
      <div class="break-status">
        <img src="../images/progress_bg2.png" alt="">
      </div>
      <div class="break-num">
        <p>未处理总数</p>
        <p>{{ faultData.unhandled || 0 }}</p>
      </div>
      <div class="break-chart">
        <div class="chart chart2">
          <p>{{ fmtRateForTotal(faultData.unhandled) }}%</p>
        </div>
      </div>
    </div>
    <div class="break-top-card">
      <div class="break-status">
        <img src="../images/progress_bg1.png" alt="">
      </div>
      <div class="break-num">
        <p>今日故障总数</p>
        <p>{{ faultData.todayTotal || 0 }}</p>
      </div>
      <div class="break-chart">
        <div class="chart chart2">
          <p>{{fmtRateForTotal(faultData.todayTotal)}}%</p>
        </div>
        <p class="tips">已处理<span>{{ faultData.todayHandled || 0}}</span></p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "BreakTop",
  props: {
    propsData: {
      type: Object, 
      default: () => {
        return {}
      }
    }
  },
  watch: {
    propsData: {
      deep: true,
      handler() {
        this.faultData = Object.assign({}, this.propsData);
      }
    }
  },
  data() {
    return {
      faultData: {},
    }
  },
  methods: {
    fmtNum(val) {
      if (val <= 0) {
        return 0
      } else if (val >= 100) {
        return 100
      } else {
        return val
      }
    },
    // 当前数值占总数的比例
    fmtRateForTotal(value) {
      const val = (value / (this.faultData.faultTotal || 1) * 100).toFixed(2);
      return this.fmtNum(val)
    }
  }
};
</script>

<style scoped lang="scss">
.mr-break-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  .break-top-card {
    width: 616px;
    height: 162.11px;
    background: linear-gradient(
      180deg,
      rgba(27, 53, 56, 0.52) 0%,
      rgba(21, 52, 73, 0) 41%,
      rgba(13, 51, 97, 0.09) 100%
    );
    box-sizing: border-box;
    border: 1px solid;
    border-image: linear-gradient(
        275deg,
        rgba(0, 255, 221, 0.26) 2%,
        rgba(0, 255, 221, 0) 19%,
        rgba(0, 229, 255, 0) 82%,
        rgba(39, 233, 255, 0.66) 98%
      )
      1;

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 54px;

    .break-status {
      width: 74px;
      height: 74px;
      img {
        width: 100%;
        height: 100%;
      } 
    }

    .break-num {
      p:nth-child(1) {
        font-family: "microsoft yahei ui","microsoft yahei";
        font-size: 14px;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.6);
        line-height: 36px;
      }
      p:nth-child(2) {
        font-family: DIN Alternate;
        font-size: 36px;
        font-weight: bold;
        color: rgba(255, 255, 255, 0.8);
      }
    }

    .break-chart {
      width: 120px;
      height: 75px;
      background: url('../images/bg.png') no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      position: relative;
      .chart {
        height: 100%;
        .progress-box {
          width: 28px;
          height: 56px;
          display: flex;
          align-items: flex-end;
          .progress {
            width: 8px;
            height: 100%;
            margin: 0 auto;
            &.progress1 {
              
              background: linear-gradient(180deg, rgba(90, 238, 255, 0.72) 0%, rgba(22, 222, 244, 0.11) 100%);
            }
            &.progress2 {
              background: linear-gradient(180deg, rgba(255, 195, 90, 0.72) 0%, rgba(244, 155, 22, 0.11) 100%);
            }
            &.progress3 {
              background: linear-gradient(180deg, rgba(225, 38, 91, 0.72) 0%, rgba(244, 22, 92, 0.11) 100%);
            }
          }
        }
        p {
          font-family: "microsoft yahei ui","microsoft yahei";
          font-size: 14px;
          color: rgba(255, 255, 255, 0.6);
        }
      }

      .chart2 {
        width: 56px;
        height: 56px;
        background: url('./../images/progress_bg3.png') no-repeat;
        background-size: 100% 100%;
        text-align: center;
        line-height: 56px;
      }

      .tips {
        position: absolute;
        bottom: 0;
        right: -48px;
        color: rgba(255, 255, 255, 0.6);
        span {
          color: #fff;
          font-size: 16px;
          margin-left: 8px;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
