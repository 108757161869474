<template>
  <div class="pro-info">
    <div class="info-menu">
      <div
        class="menu-item"
        v-for="i in menuList"
        :key="i.id"
        @click="clickMenu(i)"
      >
        {{ i.menu }}
      </div>
    </div>
    <div class="info-container">
      <div class="info-breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>项目管理</el-breadcrumb-item>
          <el-breadcrumb-item class="breadcrumb-active">项目详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="info-content">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "ProInfo",
  data() {
    return {
      menuList: [
        {
          id: 0,
          menu: "项目",
        },
        {
          id: 1,
          menu: "监控",
        },
        {
          id: 2,
          menu: "数据",
        },
        {
          id: 3,
          menu: "故障",
        },
        {
          id: 4,
          menu: "售后",
        },
      ],
      projectInfo: {},
    };
  },
  computed: {
    ...mapState(["projectCurrentRowData"]),
  },
  mounted() {
    this.getProjectInfo();
  },
  methods: {
    getProjectInfo() {
      this.request
        .projectInfo({ project: this.projectCurrentRowData.projectCode })
        .then((res) => {
          console.log("项目详情--", res);
        });
    },
    clickMenu(i) {
      console.log("menu---", i);
    },
  },
};
</script>

<style scoped lang="scss">
.pro-info {
  height: 100%;
  background: #f4f4f4;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .info-menu {
    width: 50px;
    height: 100%;
    background-color: #fff;
    margin-right: 8px;
    .menu-item {
      text-align: center;
      height: 46px;
      line-height: 46px;
      cursor: pointer;
      &.active {
        color: #3456ff;
      }
    }
  }

  .info-container {
    flex: 1;
    height: 100%;
    .info-breadcrumb {
      .el-breadcrumb {
        height: 36px;
        line-height: 36px;
        background-color: #fff;
        padding-left: 28px;
      }
    }

    .info-content {
      margin-top: 12px;
      overflow: hidden;
    }
  }
}
</style>