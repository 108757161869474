<template>
  <div class="upload">
    <el-upload
      :action="action"
      :accept="accept"
      :headers="headers"
      :file-list="fileList"
      :data="qiniuData"
      :before-upload="beforeupload"
      :on-exceed="exceed"
      :on-remove="handleRemove"
      :on-error="uploadErr"
      multiple
      :limit="limit"
      :on-success="(response, file, fileList) => uploadSuccess(response, file, fileList)"
    >
      <el-button size="small" type="primary">点击上传</el-button>
      <div slot="tip" class="el-upload__tip" v-if="size">{{ "只能上传" + accept + "文件，且不超过" + size + "M" }}</div>
    </el-upload>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      qiniuData: {},
      domain: null,
      headers: {},
      action: "",
    };
  },
  props: ["limit", "fileList", "type", "accept", "uploaDisabled", "size"],
  mounted() {
    this.getQiniuToken();
    this.headers = { Authorization: localStorage.getItem("token") };
  },
  computed: {
    ...mapState(["buttonTextColor"]),
  },
  methods: {
    //获取七牛token
    getQiniuToken() {
      this.request.qiniuUploadToken().then((res) => {
        this.qiniuData.token = res.data.uptoken;
        this.domain = res.data.domain;
        this.action = res.data.upload;
      });
    },
    //上传成功
    uploadSuccess(res, file, fileList) {
      this.$emit("success", {
        url: this.domain + res.key,
        hash: res.hash,
        name: file.name,
        size: file.size,
        fileList,
      });
    },
    uploadErr(e) {
      console.log(e);
      this.$message.error("文件上传异常");
    },
    exceed() {
      this.$message.error("文件数超出限制");
    },
    //删除文件
    handleRemove(file) {
      this.$emit("remove", file.name);
    },
    //格式化时间
    formatDate() {
        var date=new Date();
        const year = date.getFullYear().toString().padStart(4, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hour = date.getHours().toString().padStart(2, '0');
        const minute = date.getMinutes().toString().padStart(2, '0');
        const second = date.getSeconds().toString().padStart(2, '0');
        return year+""+month+""+day+""+hour+""+minute+""+second+""+Math.round(Math.random()*100);
    },
    //  上传前处理文件类型和大小
    beforeupload(file) {
      let suffix = "." + file.name.split(".")[file.name.split(".").length - 1];
      let isKon = file.name.indexOf(" ") != -1;
      if (isKon) {
        this.$message.error("文件名不能有空格");
        return false;
      }
      if (this.accept!=undefined&&this.accept!=null) {
        if (this.accept.indexOf(suffix)==-1) {
          this.$message.error("只能上传" + this.accept + "文件");
          return false;
        }
      }
      this.qiniuData.key=this.formatDate()+suffix; 
      if (this.size!=undefined&&this.size!=null) {
        const isSize = file.size / 1024 / 1024 < this.size;
        if (this.size && !isSize) {
          this.$message.error("上传文件要求不超过" + this.size + "M");
          return false;
        } 
      }
      return true;
    },
  },
};
</script>
<style scoped>
</style>