<template>
  <div class="dilu-page" style="background-color:#f4f6f9;">
    
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {};
  },
  mounted() {
    this.goEdit();
  },
  computed: { },
  components: { },
  methods: {
    goEdit() {
      this.$router.push({
        path: "/project/promgt/pronew",
        query: {
          isAdd: "2",
        },
      });
    },
  }
};
</script>

<style scoped>

</style>