<template>
   <div class="dilu-page">
    [数字孪生] 开发中...
   </div>
 </template>
 
 
 <script>
 import { mapState } from "vuex";
 export default {
   data() {
     return {
       
     };
   },
   components: {
   
   },
   computed: {
     ...mapState(["buttonTextColor"]),
   },
   mounted() {
    
   },
   methods: {
     
   },
 };
 </script>
 
 <style scoped>
 </style>