<template>
  <div class="v-card">
    <v-arrow-bg>
      <template #header>维保信息</template>
      <div class="btn">日</div>
      <div class="th">
        <div class="label">名称</div>
        <div class="time">时间</div>
        <div class="status">状态</div>
      </div>
      <div class="v-card-container">
        <div class="tbody">
          <div class="count" v-for="i in controlList" :key="'control_' + i.id">
            <div class="label">{{ i.label }}</div>
            <div class="time">{{ i.time }}</div>
            <div :class="['status', 'status' + i.status]">
              {{ i.statusName }}
            </div>
          </div>
        </div>
      </div>
    </v-arrow-bg>
  </div>
</template>

<script lang="js">
import VArrowBg from "../v-arrow-bg.vue";

export default {
  name: 'VrightCard2',
  components: {
    VArrowBg,
  },
  props: {
    propsData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  watch: {
    propsData: {
      deep: true,
      handler() {
        this.screenData = Object.assign({}, this.propsData);
      }
    }
  },
  data() {
    return {
      screenData: {},
      controlList: [
        {
          id: 0,
          label: "新风过滤网更...",
          time: "2024.01.02",
          statusName: "未处理",
          status: 0
        },
        {
          id: 1,
          label: "水流开关故障",
          time: "2024.01.02",
          statusName: "未处理",
          status: 0
        },
        {
          id: 2,
          label: "水流开关故障",
          time: "2024.01.02",
          statusName: "未处理",
          status: 0
        },
        {
          id: 3,
          label: "水流开关故障",
          time: "2024.01.02",
          statusName: "未处理",
          status: 1
        },
        {
          id: 4,
          label: "水流开关故障",
          time: "2024.01.02",
          statusName: "未处理",
          status: 0
        },
        {
          id: 5,
          label: "水流开关故障",
          time: "2024.01.02",
          statusName: "未处理",
          status: 1
        },
        {
          id: 6,
          label: "水流开关故障水流开关故障水流开关故障水流开关故障水流开关故障",
          time: "2024.01.02",
          statusName: "未处理",
          status: 1
        },
        {
          id: 7,
          label: "水流开关故障",
          time: "2024.01.02",
          statusName: "未处理",
          status: 1
        },
        {
          id: 8,
          label: "水流开关故障",
          time: "2024.01.02",
          statusName: "未处理",
          status: 1
        },
      ]
    }
  },
  methods: {}
}
</script>
<style scoped lang="scss">
.v-card {
  width: 321.33px;
  height: 248px;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  .btn {
    position: absolute;
    right: 24px;
    top: 18px;
    width: 28px;
    height: 24px;
    line-height: 24px;
    border-radius: 16px;
    text-align: center;
    font-family: "microsoft yahei ui","microsoft yahei";
    font-size: 12px;
    color: #7c7c7c;
    border: 1px solid rgba(255, 255, 255, 0.14);
    box-sizing: border-box;
    cursor: pointer;
  }

  .th,
  .count {
    height: 24px;
    background: rgba(74, 255, 243, 0.15);
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    > div {
      font-family: "microsoft yahei ui","microsoft yahei";
      font-size: 12px;
      color: #5cd098;
      &.label {
        width: calc(100% - 160px);
        text-align: left;
        padding-left: 12px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      &.time {
        width: 80px;
        text-align: center;
      }

      &.status {
        width: 80px;
        text-align: center;
      }
    }
  }

  .th {
    margin-top: 8px;
  }

  .v-card-container {
    width: 100%;
    height: calc(100% - 64px);
    overflow-y: auto;

    .count {
      background: transparent;
      &:nth-child(2n) {
        background: rgba(74, 255, 243, 0.05);
      }
      > div {
        color: rgba(255, 255, 255, 0.7);
        &.status1 {
          color: #5cd098;
        }
        &.status0 {
          color: rgba(216, 33, 97, 0.7);
        }
      }
    }
  }
}
</style>
