<template>
    <div class="placeInfo-page">
        <div class="view">
            <div class="form-body" v-if="currentData.id != undefined">
                <form-title required>建筑信息</form-title>
                <el-form :model="ruleForm" ref="ruleForm" :rules="rules" label-width="150px" class="demo-ruleForm">
                    <div class="project-box">
                        <el-form-item label="总建筑面积（平米）" prop="buildingArea" style="width:50%;">
                            <el-input v-model.number="ruleForm.buildingArea" :disabled="isAdd == 3" />
                        </el-form-item>
                        <el-form-item label="总空调面积（平米）" prop="kongtiaoArea" style="width:50%;">
                            <el-input v-model.number="ruleForm.kongtiaoArea" :disabled="isAdd == 3" />
                        </el-form-item>
                        <el-form-item label="总供暖面积（平米）" prop="cainuanArea" style="width:50%;">
                            <el-input v-model.number="ruleForm.cainuanArea" :disabled="isAdd == 3" />
                        </el-form-item>
                    </div>
                </el-form>

                <form-title required>建筑设置</form-title>
                <el-tree class="tree" ref="tree" :data="dataList" node-key="id" :props="defaultProps"
                    @node-click="treeClick" :expand-on-click-node="false" default-expand-all>
                    <span class="custom-tree-node show-hide" slot-scope="{ node, data }">
                        <span>{{ node.label }}</span>
                        <span style="display:none;">
                            <i v-if="isAdd != 3" @click="() => appendNode(data)" class="el-icon-plus" style="margin-right:10px;"
                                title="增加"></i>
                            <i v-if="data.id !== 1 &&isAdd != 3" @click="() => editNode(data)" class="el-icon-edit"
                                style="margin-right:10px;" title="编辑"></i>
                            <i v-if="data.id !== 1 &&isAdd != 3" @click="() => removeNode(data)" class="el-icon-delete"
                                title="删除"></i>
                        </span>
                    </span>
                </el-tree>

                <form-title>客户档案</form-title>
                <dilu-uploadfile v-show="isAdd != 3" :limit="1" @success="getfileUrl" style="margin: 20px 10px;width: 15vw;" />
                <span v-if="isAdd == '1' || isAdd == '2'" slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="submit">保存</el-button>
                    <el-button type="text" @click="handleClose">重置</el-button>
                </span>
            </div>
            <div v-else>
                <el-empty>
                    <span>项目未新建，需新建项目后操作</span>

                    <el-button type="text" @click="goProjectTab()">去新建项目</el-button>

                </el-empty>
            </div>
        </div>

        <!-- 查看和编辑建筑列表 -->
        <BuildAddModule :id="id" :parentId="parentId" :name="name" :orgVisible="orgVisible"
            :project="ruleForm.projectCode" @handleClose2="handleClose2" @listOrg="listOrg" />

    </div>
</template>


<script>
import { mapState } from "vuex";
import BuildAddModule from "./buildAddModule.vue";
import DiluUploadfile from "@/components/public/dilu-uploadfile.vue";
import FormTitle from "../components/FormTitle.vue";

export default {
    data() {
        return {
            title: "新建项目",
            ruleForm: {
                id: null,           //自增ID
                projectCode: "",    //项目编号
                projectName: "",    //项目名称
                status: 10,         //项目状态（10：正常使用，20：在建，30：交付）
                orgId: "",          //归属部门
                contactPerson: "",  //客户姓名
                contactPhone: "",   //联系电话
                buildingType: "",   //建筑类型
                buildingArea: 0,    //住宅面积
                kongtiaoArea: 0,    //空调面积
                cainuanArea: 0,     //供暖面积
                country: "中国",    //国家
                province: "",       //省份
                city: "",           //城市
                area: "",           //区县
                address: "",        //详细地址
                priceNormal: null,  //正常电价
                priceMax: null,     //波峰电价
                priceMin: null,     //波谷电价
                priceDiscount: null,//优惠电价
                remark: "",         //租户备注
            },
            isAdd: 1,
            currentData: {},
            dataList: [],
            parentId: null,
            id: null,
            name: null,
            orgVisible: false,
            defaultProps: {
                children: "nodeList",
                label: "name"
            },
            rules: {
                projectName: [
                    {
                        required: true,
                        message: "请填写项目名称",
                        trigger: "blur",
                    }
                ]
            },
        };
    },
    components: {
        BuildAddModule,
        DiluUploadfile,
        FormTitle
    },
    computed: {
        ...mapState(["projectCurrentRowData"])
    },
    mounted() {
        const { isAdd } = this.$route.query;
        this.isAdd = isAdd;
        this.currentData = this.projectCurrentRowData;
        if (this.currentData.id != undefined) {
            this.showInit();
            this.listOrg();
        }
    },
    methods: {
        goProjectTab() {
            this.$emit('update:msg', 'baseInfo');
        },
        //初始显示
        showInit() {
            if (this.isAdd == 1) {
                this.title = "新建项目";
                this.ruleForm.projectCode = this.currentData.projectCode;    //项目编号
                this.ruleForm.id = null;           //自增ID
                this.ruleForm.projectName = "";    //项目名称
                this.ruleForm.status = 20;         //项目状态（10：正常使用，20：在建，30：交付）
                this.ruleForm.orgId = [];          //归属部门
                this.ruleForm.contactPerson = "";  //客户姓名
                this.ruleForm.contactPhone = "";   //联系电话
                this.ruleForm.buildingType = [];   //建筑类型
                this.ruleForm.buildingArea = 0;    //住宅面积
                this.ruleForm.kongtiaoArea = 0;    //空调面积
                this.ruleForm.cainuanArea = 0;     //供暖面积
                this.ruleForm.country = "中国";    //国家
                this.ruleForm.province = "";       //省份
                this.ruleForm.city = "";           //城市
                this.ruleForm.area = "";           //区县
                this.ruleForm.address = "";        //详细地址
                this.ruleForm.priceNormal = null;  //正常电价
                this.ruleForm.priceMax = null;     //波峰电价
                this.ruleForm.priceMin = null;     //波谷电价
                this.ruleForm.priceDiscount = null;//优惠电价
                this.ruleForm.remark = "";         //项目备注

            } else if (this.isAdd == 2 || this.isAdd == 3) {
                if (this.isAdd == 2) {
                    this.title = "编辑项目";
                } else {
                    this.title = "查看项目";
                }
                this.ruleForm.id = this.currentData.id;                        //自增ID
                this.ruleForm.projectCode = this.currentData.projectCode;      //项目编号
                this.ruleForm.projectName = this.currentData.projectName;      //项目名称
                this.ruleForm.status = this.currentData.status;                //项目状态（10：正常使用，20：在建，30：交付）
                this.ruleForm.orgId = this.currentData.orgId;                  //归属部门
                this.ruleForm.contactPerson = this.currentData.contactPerson;  //客户姓名
                this.ruleForm.contactPhone = this.currentData.contactPhone;    //联系电话
                this.ruleForm.buildingType = this.currentData.buildingType;    //建筑类型
                this.ruleForm.buildingArea = this.currentData.buildingArea;    //建筑面积
                this.ruleForm.kongtiaoArea = this.currentData.kongtiaoArea;    //空调面积
                this.ruleForm.cainuanArea = this.currentData.cainuanArea;      //供暖面积
                this.ruleForm.country = this.currentData.country;              //国家
                this.ruleForm.province = this.currentData.province;            //省份
                this.ruleForm.city = this.currentData.city;                    //城市
                this.ruleForm.area = this.currentData.area;                    //区县
                this.ruleForm.address = this.currentData.address;              //详细地址
                this.ruleForm.priceNormal = this.currentData.priceNormal;      //正常电价
                this.ruleForm.priceMax = this.currentData.priceMax;            //波峰电价
                this.ruleForm.priceMin = this.currentData.priceMin;            //波谷电价
                this.ruleForm.priceDiscount = this.currentData.priceDiscount;  //优惠电价
                this.ruleForm.remark = this.currentData.remark;                      //归属部门
            }
            // this.ruleForm.projectCode = 'P10000KWDTDVDNNY';      //项目编号

        },
        getfileUrl(val) {
            //console.log(val);
            this.ruleForm.cadFile = val.url;
        },

        listOrg() {
            // { project: this.ruleForm.projectCode }
            this.request.placeList({ project: this.ruleForm.projectCode }).then((res) => {
                this.dataList = [res.data];
                //console.log(res.data)
            });
        },

        //点击节点触发
        treeClick(e) {
            this.params.orgId = e.id;
            this.accountManagementList();
        },

        // 新增树节点
        appendNode(data) {
            this.id = null;
            this.name = null;
            this.parentId = data.id;
            this.orgVisible = true;
        },
        // 编辑树节点
        editNode(data) {
            this.id = data.id;
            this.parentId = null;
            this.name = data.name;
            this.orgVisible = true;
        },
        // 删除树节点
        removeNode(data) {
            this.$confirm("确定删除选中建筑？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                var params = {};
                params['id'] = data.id;
                params['project'] = this.ruleForm.projectCode;

                this.request.placeDel(params).then((res) => {
                    if (res.code == 0) {
                        this.$message({
                            type: "success",
                            message: "删除成功",
                        });
                        this.listOrg();
                    }
                });
            });
        },



        handleClose() {
            this.$refs["ruleForm"].resetFields();
            // this.$router.go(-1);
        },
        handleClose2() {
            this.orgVisible = false;
        },
        //确定
        submit() {
            this.$refs["ruleForm"].validate((valid) => {
                if (valid) {
                    if (this.isAdd == 1) {
                        this.request.projectAdd(this.ruleForm).then((res) => {
                            if (res.code == 0) {
                                this.$message.success("新增成功");
                                setTimeout(() => {
                                    this.$emit("projectList");
                                    this.handleClose();
                                }, 1000);
                            }
                        });
                    } else {
                        this.request.projectEdit(this.ruleForm).then((res) => {
                            if (res.code == 0) {
                                this.$message.success("编辑成功");
                                setTimeout(() => {
                                    this.$emit("projectList");
                                    this.handleClose();
                                }, 1000);
                            }
                        });
                    }
                }
            });
        },
    },
};
</script>


<style scoped lang="scss">
.tree {
    height: 25vh;
    overflow: auto;
    width: 15vw;
    background: #F8F8FA;
}

.form-body {
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    background-color: white;
    overflow-y: auto;
}

.form-title {
    margin: 20px 0;
}

.body-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.body-title .title-right>div {
    display: inline-block;
    margin-left: 24px;
}

.body-title .title-right .title-back .el-button--text {
    color: rgba(0, 0, 0, 0.6);
}

.dialog-footer {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 560px;
    text-align: right;
}

.page-header {
    margin: 10px;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 20px;
    font-weight: bold;
    background-color: white;
}

.title {
    font-size: 18px;
    font-family: "microsoft yahei ui","microsoft yahei";
    font-weight: bold;
    color: #000000;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
}

.back {
    font-size: 26px;
    margin-right: 16px;
    cursor: pointer;
}

.custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
}

.el-tree ::v-deep.el-tree-node>.el-tree-node__content {
    height: 40px;
}

.show-hide:hover :nth-child(2) {
    display: inline-block !important;
}

.dialog-footer {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 560px;
    text-align: left;

    > :nth-child(1) {
        width: 300px;
        height: 32px;
        line-height: 32px;
        border-radius: 2px;
        opacity: 1;
        padding: 0;
        background: #3456ff;
    }

    > :nth-child(2) {
        font-family: "microsoft yahei ui","microsoft yahei";
        font-size: 14px;
        color: #666666;
        margin-left: 56px;
    }
}
</style>