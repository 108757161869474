export default {
  tooltip: {
    trigger: "axis",
  },
  legend: {
    data: ["低谷时段", "平谷时段", "高峰时段", "尖峰时段"],
    icon: "circle",
    itemWidth: 10,
    itemHeight: 10,
    bottom: 0,
    textStyle: {
      color: "#fff",
    },
  },
  grid: {
    top: 0,
    left: "3%",
    right: "4%",
    bottom: "13%",
    containLabel: true,
  },
  xAxis: {
    type: "category",
    boundaryGap: false,
    data: [],
    axisLabel: {
      color: "#fff",
    },
    axisLine: {
      lineStyle: {
        color: "#fff",
        opacity: 0.4,
      },
    },
    axisTick: {
      show: false,
    },
  },
  yAxis: {
    type: "value",
    axisLabel: {
      color: "#fff",
      rotate: 30,
    },
    axisLine: {
      show: false,
    },
    splitLine: {
      show: true,
      lineStyle: {
        opacity: 0.4,
      },
    },
  },
  series: [],
};
