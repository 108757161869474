<template>
  <div class="chart">
    <mr-echarts :options="options"></mr-echarts>
  </div>
</template>

<script>
import MrEcharts from "@/components/public/mr-echarts.vue";
import chartBar from "../chartOpt/chartBar";
export default {
  name: "SummaryBar",
  components: {
    MrEcharts,
  },
  props: {
    propsData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      options: {},
    };
  },
  watch: {
    propsData: {
      deep: true,
      handler() {
        this.initChart();
      },
    },
  },
  methods: {
    initChart() {
      const seriesData = (this.propsData.cityList || []).map(i => i.value);
      let xAxisData = [];
      this.propsData.cityList.forEach(item => {
        const nameArr = item.name.split(",");
        const name = nameArr.length == 2  ? nameArr[1] : nameArr[0]
        xAxisData.push(name)
      })
      chartBar.xAxis.data = xAxisData;
      chartBar.series[0].data = seriesData;
      setTimeout(() => {
        this.options = chartBar;
      }, 10);
    },
  },
};
</script>

<style scoped lang="scss">
.chart {
  flex: 1;
  height: 100%;
  border-radius: 4px;
  background-color: #fff;
}
</style>
