<template>
    <div class="dilu-page">
        <div class="depart-view" v-if="currentData.id != undefined">
            <div class="depart-view-left">
                <div class="tree">
                    <el-row style="margin: 10px 0 20px 0;">
                        <el-col :span="18" class="wgtitle">网关列表</el-col>
                        <el-col :span="6">
                            <el-button v-show="isAdd != 3" class="addwg" type="text" icon="el-icon-document-add" @click="() => appendNode(data)">新增</el-button>
                        </el-col>
                    </el-row>
                    <div> </div>
                    <el-tree ref="tree" :data="dataList" node-key="id" :props="defaultProps" @node-click="treeClick" :expand-on-click-node="false" default-expand-all>
                        <span class="custom-tree-node show-hide" slot-scope="{ node, data }">
                            <span>{{ node.label }}</span>
                            <span style="display:none;">
                                <i v-if="isAdd != 3 && node.label != '全部'" @click="() => editNode(data)"
                                    class="el-icon-edit" style="margin-right:10px;" title="编辑"></i>
                                <i v-if="isAdd != 3 && node.label != '全部'" @click="() => removeNode(data)"
                                    class="el-icon-delete" title="删除"></i>
                            </span>
                        </span>
                    </el-tree>
                </div>
            </div>
            <div class="depart-view-right">
                <div class="page-right">
                    <el-row style="margin: 0 0 20px 0;background: #F8F8FA;" class="sbcard" v-show="deviceObj.gateway != undefined">
                        <el-col :span="5">
                            <span class="cardlabel">网关编码</span>
                            <span class="cardval">{{ deviceObj2.gateway }}</span>
                        </el-col>
                        <el-col :span="5">
                            <span class="cardlabel">网关类型</span>
                            <span class="cardval">
                                {{ deviceObj2.stype == 10 ? '设备' : deviceObj2.stype == 20 ? '网关' : deviceObj2.stype == 30 ? '边缘服务器' : '未知' }}
                            </span>
                        </el-col>
                        <el-col :span="5">
                            <span class="cardlabel">波特率</span>
                            <span class="cardval">
                                {{ baudRate }}
                            </span>
                        </el-col>  
                        <el-col :span="5">
                            <span class="cardlabel">心跳间隔</span>
                            <span class="cardval">
                                {{ heartBeat }}
                            </span>
                        </el-col>    
                    </el-row>
                    <el-row> 
                        <el-col :span="18">
                            <el-form>
                                <el-select v-model="ruleForm.deviceId" style="width:200px;" @change="selectChanged" placeholder="请选择设备" filterable clearable>
                                    <el-option v-for="item in deviceList" :key="item.id" :label="item.categoryName" :value="item.id"/>
                                </el-select>
                                <el-select v-model="ruleForm.paramId" style="width:200px;margin-left:20px;margin-right:20px;" placeholder="请选择参数" filterable clearable>
                                    <el-option v-for="item in paramList" :key="item.id" :label="item.name" :value="item.id"/>
                                </el-select>
                                <el-button  @click="() => projectChartSet()" type="primary" class="addsb" style="margin-right:20px;" size="small" icon="el-icon-picture-outline">设置曲线</el-button>
                                <el-button  @click="() => gatewaySetting()" type="primary" class="addsb" style="margin-right:20px;" size="small" icon="el-icon-picture-outline">设置波特率</el-button>
                                <el-button v-show="isAdd != 3" @click="() => add()" type="primary" class="addsb" size="small" icon="el-icon-document-add" :disabled="deviceObj.gateway == undefined">新增设备</el-button>
                            </el-form>
                        </el-col>
                    </el-row>
                    <DiluTable :list="tableData" :tableConfig="tableConfig">
                        <el-table-column prop="address" label="从机地址" width="100" align="left" />
                        <el-table-column prop="categoryName" label="类型名称" width="150" align="left" />
                        <el-table-column prop="modelName" label="型号名称" width="180" align="left" />                        
                        <el-table-column prop="manufacturerName" label="制造商" width="130" align="center" />
                        <el-table-column prop="codec" label="解码协议" width="300" align="center" />
                        <el-table-column prop="place" label="安装位置" width="220" align="center">
                            <template slot-scope="scope">{{ selectCascaderLabelpage(scope.row.place) }}</template>
                        </el-table-column>
                        <el-table-column prop="position" label="显示顺序" width="100" align="center" />
                        <el-table-column label="操作" align="center" width="200" v-if="isAdd != 3" fixed="right">
                            <template slot-scope="scope">
                                <div class="table-operation">
                                    <el-button @click="edit(scope.row)" type="text">编辑</el-button>
                                    <el-button @click="remove(scope.row)" type="text">删除</el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </DiluTable>
                </div>
            </div>
        </div>
        <div v-else>
            <el-empty>
                <span>项目未新建，需新建项目后操作</span>
                <el-button type="text" @click="goProjectTab()">去新建项目</el-button>
            </el-empty>
        </div>

        <!-- 查看和编辑网关 -->
        <GatewayAdd 
            :id="id" 
            :gateway="gateway" 
            :stype="stype" 
            :category="category" 
            :manufacturer="manufacturer" 
            :model="model" 
            :place="place"
            :orgVisible="orgVisible" 
            :project="ruleForm.project" 
            @handleClose="handleClose" 
            @listgateway="listgateway" />

        <!-- 查看和编辑设备 -->
        <DeviceAdd 
            :deviceVisible="deviceVisible" 
            :projectCode="ruleForm.project" 
            :deviceObj="deviceObj"
            @deviceClose="deviceClose"
            @getDeviceList="getDeviceList"
            :deviceAddState="deviceAddState" 
            :currentDevice="currentDevice" />

        <!-- 设置波特率 -->
        <BaudRate 
            :editVisible="editVisible" 
            :gateway="gateway" 
            :baudRate="baudRate" 
            :heartBeat="heartBeat" 
            @handleClose="handleClose"/>    

    </div>
</template>


<script>
import { mapState } from "vuex";
import DiluTable from "@/components/public/dilu-table.vue";
import GatewayAdd from "./gatewayAdd.vue";
import DeviceAdd from "./deviceAdd.vue";
import BaudRate from "./baudRate.vue";
import { selectCascaderLabel } from "@/utils/util/util";

export default {
    data() {
        return {
            tableConfig: {
                isTree: false,
                isPage: true,
            },
            defaultProps: {
                children: "nodeList",
                label: "gateway"
            },
            ruleForm: {
                project: "",
                deviceId: null,
                paramId: null
            },
            placeList: [],
            deviceObj: {},
            deviceObj2: {},
            currentData: {},
            parentId: null,
            id: null,
            gateway: null,
            stype: null,
            manufacturer: null,
            model: null,
            category: null,
            place: [],
            orgVisible: false,
            currentDevice: {}, //当前设备
            deviceAddState: 0, //弹窗类型 1：编辑 2：查看
            deviceVisible: false, //新增设备
            tableData: [],
            total: 0,
            dataList: [],
            deviceList: [],
            paramList: [],
            postition: null,
            editVisible: false,
            baudRate: 9600,
            heartBeat: 600,
        };
    },
    computed: {
        ...mapState(["buttonTextColor"]),
        ...mapState(["projectCurrentRowData"])
    },
    components: {
        DiluTable,
        GatewayAdd,
        DeviceAdd,
        BaudRate
    },
    mounted() {
        const { isAdd } = this.$route.query;
        this.isAdd = isAdd;
        this.currentData = this.projectCurrentRowData;
        this.ruleForm.project = this.currentData.projectCode;
        if (this.currentData.realChart!=null) {
            let array=this.currentData.realChart.split(",");
            if (array!=null) {
                this.ruleForm.deviceId=parseInt(array[0]);
                this.ruleForm.paramId=parseInt(array[1]);
            }
        }
        if (this.currentData.id != undefined) {
            this.placelist();
            this.listgateway();
            this.getDeviceList();
        }
    },
    methods: {
        placelist() {
            this.request.placeList({ project: this.ruleForm.project }).then((res) => {
                this.placeList = res.data;
            });
        },
        selectCascaderLabelpage(value) {
            let arr = selectCascaderLabel(this.placeList, value, 'nodeList', 'name');
            if (arr[0] == '摩尔创新研发中心') {
                arr = arr.slice(1);
            }
            return arr.toString();
        },
        goProjectTab() {
            this.$emit('update:msg', 'baseInfo');
        },
        selectChanged(deviceId) {
            var params = {};
            params["deviceId"] = deviceId;
            this.request.deviceParams(params).then((res) => {
                if (res.code == 0) {
                    this.paramList=res.data;
                }
            });
        },
        gatewaySetting() {
            if (this.gateway==null) {
                this.$message.error("请选择要设备波特率的网关");
            } else {
                this.request.productProtocol({gateway:this.gateway}).then((res) => {
                    if (res.code==0) {
                        this.editVisible=true;
                        this.baudRate=res.data.baudRate;
                        this.heartBeat=res.data.heartBeat;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            }
        },
        projectChartSet() {
            if (this.ruleForm.deviceId==null) {
                this.$message.error("请选择设备");
            } else if (this.ruleForm.paramId==null) {
                this.$message.error("请选择参数");
            } else {
                this.request.projectChartSet(this.ruleForm).then((res) => {
                    if (res.code == 0) {
                        this.$message.success("设置成功");
                    }
                });
            }
        },
        //添加
        add() {
            this.deviceAddState = 0;
            this.deviceVisible = true;
        },
        //编辑
        edit(e) {
            this.currentDevice = e;
            this.deviceAddState = 1;
            this.deviceVisible = true;
        },
        //删除
        remove(e) {
            this.$confirm("确认删除该设备？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                var params = {};
                params["id"] = e.id;
                params['project'] = this.ruleForm.project;
                this.request.deviceDel(params).then((res) => {
                    if (res.code == 0) {
                        this.$message.success("删除成功");
                        this.getDeviceList();
                    }
                });
            })
            .catch(() => { });
        },
        // 新增树节点
        appendNode(data) {
            this.id = null;
            this.gateway = null;
            this.orgVisible = true;
        },
        // 编辑树节点
        editNode(data) {
            this.id = data.id;
            this.gateway = data.gateway;
            this.stype = data.stype;
            this.baudRate = data.baudRate;
            this.heartBeat = data.heartBeat;
            if (data.stype==10) {
                var params = {};
                params["gateway"] = data.gateway;
                params['project'] = this.ruleForm.project;
                this.request.deviceList(params).then((res) => {
                    if (res.code == 0) {
                        let list=res.data;
                        if (list.length>0) {
                            let item=list[0];
                            this.manufacturer = item.manufacturer;
                            this.model = item.model;
                            this.category = item.category;
                            this.place = item.place;
                            this.orgVisible = true;                            
                        } else {
                            this.$message.error("该设备已不存在");
                        }
                    }
                });
            } else {
                this.orgVisible = true;
            }
        },
        // 删除树节点
        removeNode(data) {
            this.$confirm("确定删除选中网关？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                var params = {};
                params['gateway'] = data.gateway;
                params['project'] = this.ruleForm.project;
                this.request.gatewayDel(params).then((res) => {
                    if (res.code == 0) {
                        this.$message({
                            type: "success",
                            message: "删除成功",
                        });
                        this.listgateway();
                    }
                });
            });
        },
        handleClose() {
            this.orgVisible = false;
            this.editVisible = false;
        },
        //关闭新增设备弹窗
        deviceClose() {
            this.deviceVisible = false;
        },
        //点击节点触发
        treeClick(e) {
            this.gateway = e.gateway;
            if (e.gateway == '全部') {
                this.gateway = undefined;
            } else {
                if (e.baudRate!=null) {
                    this.baudRate = e.baudRate;
                }
                if (e.heartBeat!=null) {
                    this.heartBeat = e.heartBeat;
                }
            }
            this.deviceObj2 = e;
            this.deviceObj = {
                gateway: e.gateway,
                category: e.category,
                manufacturer: e.manufacturer,
                model: e.model,
                project: e.project,
            }
            this.getDeviceList();
        },
        //设备管理-列表（分页）
        getDeviceList() {
            var params={
                gateway: this.gateway,
                project: this.ruleForm.project
            }
            this.request.deviceList(params).then((res) => {
                this.tableData = res.data;
                if (this.deviceList.length==0) {
                    this.deviceList = res.data;
                    if (this.ruleForm.deviceId!=null) {
                        this.selectChanged(this.ruleForm.deviceId);
                    }
                }
            });
        },
        //查询网关
        listgateway() {
            this.request.gatewayList({ project: this.ruleForm.project }).then((res) => {
                this.dataList = res.data;
            });
        },
    },
};
</script>


<style scoped>
.dilu-page {
    overflow: hidden;
}

.page-row {
    height: 100%;
}

.page-col {
    border-right: 1px solid #eee;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.page-col:last-child {
    border: none;
}

.tree {
    flex: 1;
    overflow: auto;
}

.page-right {
    height: 100%;
    padding: 0 20px;
    box-sizing: border-box;
}

.depart-view {
    width: 100%;
    display: flex;
    height: 100%;
}

.depart-view-left {
    width: 18%;
    padding-right: 10px;
    box-sizing: border-box;
    flex-shrink: 0;
}

.depart-view-right {
    flex: 1;
    width: 77%;
    padding-left: 10px;
    margin-left: 10px;
    box-sizing: border-box;
    border-left: 1px solid #d7ddeb;
}

.custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
}

.el-tree ::v-deep.el-tree-node>.el-tree-node__content {
    height: 40px;
}

.show-hide:hover :nth-child(2) {
    display: inline-block !important;
}

.wgtitle {
    /* 网关列表 */
    opacity: 0.9;
    font-family: "microsoft yahei ui","microsoft yahei";
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0px;
    padding: 0 5px;
    color: #000000;
    border-left: 4px solid #326FFF;
}

.addwg {
    width: 24px;
    height: 12px;
    opacity: 1;
    font-family: "microsoft yahei ui","microsoft yahei";
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0px;
    color: #326FFF;
    padding: 0;
}

.addsb {
    border-radius: 4px;
    opacity: 1;
    background: #326FFF;
    margin: 10px 0;
}

.sbcard {
    padding: 15px 20px;
    line-height: 30px;
}

.cardval {
    opacity: 1;
    font-family: "microsoft yahei ui","microsoft yahei";
    font-size: 14px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0px;
    padding-left: 15px;
    font-variation-settings: "opsz" auto;
    color: rgba(0, 0, 0, 0.9);

}

.cardlabel {
    opacity: 1;
    text-align: right;
    font-family: "microsoft yahei ui","microsoft yahei";
    font-size: 12px;
    font-weight: normal;
    line-height: 12px;
    letter-spacing: 0px;
    color: rgba(0, 0, 0, 0.4);
}
</style>