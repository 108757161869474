<template>
  <div class="d-card0">
    <el-select
      size="mini"
      v-model="value"
      placeholder="请选择"
      @change="changeDevice"
    >
      <el-option
        v-for="item,index in optionsList"
        :key="index +'_' + item.id"
        :label="item.categoryName"
        :value="item.id"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
import DTitleBg from "./d-title-bg.vue";
import { mapState } from "vuex"
export default {
  name: "DCard0",
  comments: {
    DTitleBg,
  },
  data() {
    return {
      value: "",
      optionsList: [],
    };
  },
  computed: {
    ...mapState(["projectCurrentRowData"])
  },
  watch: {
    projectCurrentRowData: {
      deep: true,
      handler() {
        console.log("切换项目的数据监听--获取设备", this.projectCurrentRowData)
        this.getDeviceList();
      }
    }
  },
  mounted() {
    this.getDeviceList();
  },
  methods: {
    // 获取该项目下的设备列表
    getDeviceList() {
      const currentData = this.projectCurrentRowData;
      this.request
        .deviceList({ project: currentData.projectCode })
        .then((res) => {
          this.optionsList = res.data || [];
          const len = this.optionsList.length;
          this.value = len > 0 ? this.optionsList[0].id : "";
          this.$emit("changeDevice", this.value);
        });
    },
    // 切换设备
    changeDevice(e) {
      this.$emit("changeDevice", e);
    },
  },
};
</script>
<style scoped lang="scss">
.d-card0 {
  ::v-deep .el-select {
    min-width: 173.25px;
    height: 28px;
    line-height: 28px;
    background: url("~@/assets/images/visual/rect1.png") no-repeat;
    background-size: 100% 100%;
    margin-bottom: 12px;
    .el-input input {
      background-color: transparent;
      border: none;
      color: #ffffff;
      font-size: 14px;
    }
    .el-input__suffix {
      right: 15%;
    }
  }

}
</style>
