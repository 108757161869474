<template>
  <div class="dilu-page">
    <div class="search">
      <el-form :inline="true" :model="searchParams" size="mini">
        <el-form-item label="状态">
          <el-select v-model="searchParams.status" style="width:120px;margin-right:10px;" clearable>
            <el-option label="发生" value=10 />
            <el-option label="消除" value=20 />
          </el-select>
        </el-form-item>
        <el-form-item label="等级">
          <el-select v-model="searchParams.level" style="width:120px;margin-right:10px;" clearable>
            <el-option label="轻微" value=10 />
            <el-option label="一般" value=20 />
            <el-option label="严重" value=20 />
          </el-select>
        </el-form-item>
        <el-form-item label="名称">
          <el-input v-model="searchParams.name" style="width:120px;margin-right:10px;" clearable/>
        </el-form-item>
        <el-form-item label="网关">
          <el-input v-model="searchParams.gateway" style="width:150px;margin-right:10px;" clearable/>
        </el-form-item>
        <el-form-item label="日期">
          <el-date-picker type="daterange" placeholder="选择日期" value-format="yyyy-MM-dd" format="yyyy-MM-dd" v-model="searchParams.createdAt" style="width: 236px" clearable/>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="goSearch" style="width:80px;margin-left:20px;">查 询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="project-table">
      <DiluTable :list="tableData" :tableConfig="tableConfig" :total="total" :pageSize="searchParams.pageSize" @currentChange="currentChange">
        <el-table-column prop="projectCode" label="项目编码" width="200" align="center">
          <template slot-scope="scope">
            <span @click="goProject(scope.row.projectCode)">{{scope.row.projectCode}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createdAt" label="发生时间" align="center" width="160"/>
        <el-table-column prop="name" label="故障名称" align="left" width="180"/>
        <el-table-column prop="status" label="故障等级" align="center" width="90">
          <template slot-scope="scope">
            <span v-if="scope.row.level == 10">轻微</span>
            <span v-if="scope.row.level == 20">一般</span>
            <span v-if="scope.row.level == 30">严重</span>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" align="center" width="60">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 10">发生</span>
            <span v-if="scope.row.status == 20">消除</span>
          </template>
        </el-table-column>
        <el-table-column prop="code" label="代码" align="center" width="60"/>
        <el-table-column prop="categoryName" label="设备名称" align="center" width="150"/>
        <el-table-column prop="gateway" label="设备网关" align="center" width="150" />
        <el-table-column prop="modelName" label="设备型号" align="center" width="150"/>
      </DiluTable>
    </div>
  </div>
</template>
 
 <script>
 import DiluTable from "@/components/public/dilu-table.vue";
 import { mapState } from "vuex";
 export default {
   data() {
     return {
      searchParams: {
        pageSize: 12,
        pageNum: 1,
        project: null,
        status: null,
        level: null,
        name: null,
        gateway: null,
        cratedAt: []
      },
      tableConfig: {
        isTree: false,
        isPage: true,
      },
      projectInfo: {},
      tableData: [],
      total: 0
     };
   },
   components: {
    DiluTable,
   },
   computed: {
    ...mapState(["projectCurrentRowData"]),
   },
   mounted() {
    this.projectInfo=this.projectCurrentRowData;
    this.searchParams.project=this.projectInfo.projectCode;
    this.goSearch();
   },
   methods: {
    goSearch() {
      this.request.deviceFaults(this.searchParams).then((res) => {
        if (res.code == 0) {
          this.tableData=res.data;
          this.total=res.dataMaxCount;
        }
      });
    },
    currentChange(e) {
      this.searchParams.pageNum = e;
      this.goSearch();
    }
  }
 };
 </script>
 
 <style lang="scss" scoped>

  .search {
    margin-bottom: 15px;
    ::v-deep(.el-form) {
      .el-form-item--mini.el-form-item {
        margin-bottom: 0;
      }
      .el-form-item__label {
        line-height: 40px;
      }
    }
  }

  .project-table {
    height: 100%;
  }

 </style>