<template>
  <div class="video" style="width: 100%; height: 100%;background-color: #fff;position: relative;z-index: 999;">
    <dilu-video style="width: 100%; height: 100%;" />
  </div>
</template>

<script>
import DiluVideo from "@/components/public/dilu-video.vue"
export default {
  components: {
    DiluVideo
  }
}
</script>

<style>

</style>