<template>
  <div class="mr-device">
    <v-header-box @changeProject="changeProject"></v-header-box>
    <div class="mr-device-container">
      <div class="tab-box">
        <div class="tab">
          <span :class="active == 0 ? 'active' : ''" @click="active = 0"
            >设备参数</span
          >
          <span :class="active == 1 ? 'active' : ''" @click="active = 1"
            >数据记录</span
          >
        </div>
        <div class="tab-device">
          <d-card0 @changeDevice="changeDevice"></d-card0>
          <v-page-date
            @changeData="changeData"
            :showBtn="false"
            v-if="active == 1"
            :download="false"
          ></v-page-date>
        </div>
      </div>

      <template v-if="active == 0">
        <data-params :deviceIdStr="deviceId"></data-params>
      </template>
      <template v-if="active == 1">
        <data-log
          :propsData="{
            deviceId,
            dateType,
            dateValue,
          }"
        ></data-log>
      </template>
    </div>
    <v-footer></v-footer>
  </div>
</template>

<script>
import VHeaderBox from "../components/v-header-box.vue";
import DTitleBg from "./components/d-title-bg.vue";
import VFooter from "../components/v-footer.vue";
import DCard0 from "./components/d-card0.vue";
import DataLog from "./datalog.vue";
import DataParams from "./dataparams.vue";
import VPageDate from "../components/v-page-date.vue";
import { mapState } from "vuex";

export default {
  name: "VDevice",
  components: {
    VHeaderBox,
    DTitleBg,
    VFooter,
    DCard0,
    DataLog,
    DataParams,
    VPageDate,
  },
  data() {
    return {
      projectCode: "",
      deviceId: "",
      active: 0,
      dateType: "10",
      dateValue: "",
    };
  },
  computed: {
    ...mapState(["projectCurrentRowData"]),
  },
  watch: {
    projectCurrentRowData: {
      deep: true,
      handler() {
        console.log("切换项目的数据监听--", this.projectCurrentRowData);
      },
    },
  },
  mounted() {
    const currentData = this.projectCurrentRowData;
    this.projectCode = currentData.projectCode;
  },
  methods: {
    // 切换项目
    changeProject(e) {
      this.projectCode = e.projectCode;
    },
    // 切换设备
    changeDevice(e) {
      console.log("切换设备---", e);
      this.deviceId = e;
    },
    changeData(e) {
      this.dateType = e.type;
      this.dateValue = e.dateVal;
    },
  },
};
</script>

<style scoped lang="scss">
.mr-device {
  height: 100%;
  background: #1b1c20;
  background-size: 100% 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;

  .mr-device-container {
    padding: 24px;
    padding-bottom: 0;
    flex: 1;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    .tab-box {
      .tab {
        > span {
          display: inline-block;
          width: 121px;
          height: 28px;
          border-radius: 1px;
          opacity: 1;
          box-sizing: border-box;
          font-size: 14px;
          padding-left: 12px;
          line-height: 28px;
          color: #7c7c7c;
          cursor: pointer;
          &.active {
            color: #ffffff;
            background: url("~@/assets/images/visual/rect1.png") no-repeat;
            background-size: 100% 100%;
          }
        }
      }

      .tab-device {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 24px;
      }
    }
    // .mr-device-title {
    //   display: flex;
    //   align-items: center;
    //   >span {
    //     font-family: "microsoft yahei ui","microsoft yahei";
    //     font-size: 14px;
    //     color: rgba(255, 255, 255, 0.5);
    //     &:nth-child(3) {
    //       position: relative;
    //       &::before {
    //         content: "";
    //         display: inline-block;
    //         height: 1px;
    //         background: url('./images/title_line.png') no-repeat;
    //         background-size: 100% 100%;
    //         position: absolute;
    //         left: 0;
    //         right: 0;
    //         bottom: -4px;
    //       }
    //     }
    //   }
    //   i {
    //     display: inline-block;
    //     width: 12px;
    //     height: 12px;
    //     line-height: 12px;
    //     text-align: center;
    //     margin: 0 12px;
    //     img {
    //       vertical-align: middle;
    //     }
    //   }
    // }

    .device-container {
      flex: 1;
      display: flex;
      justify-content: space-between;
      margin-top: 46px;
      .left {
        height: 100%;
        flex: 2;
      }
      .right {
        flex: 1;
        height: 100%;
        padding-left: 34px;
        box-sizing: border-box;
      }
      .driver40 {
        height: 40px;
      }

      .driver52 {
        height: 52px;
      }
    }
  }
}
</style>
