<template>
  <div class="dilu-page">
      <div class="form-body">
        <el-form :model="ruleForm" ref="ruleForm" :rules="rules" label-width="auto" class="demo-ruleForm">
          <el-form-item label="公司全称" prop="fullName" style="width:500px;">
            <el-input v-model="ruleForm.fullName" disabled/>
          </el-form-item>
          <el-form-item label="平台域名" prop="platformDomain" style="width:500px;">
            <el-input v-model="ruleForm.platformDomain" disabled/>
          </el-form-item>
          <el-form-item label="公司简称" prop="shortName" style="width:500px;">
            <el-input v-model="ruleForm.shortName" clearable/>
          </el-form-item>
          <el-form-item label="平台名称" prop="platformName" style="width:500px;">
            <el-input v-model="ruleForm.platformName" clearable/>
          </el-form-item>
          <el-form-item label="所属行业">
            <el-select v-model="ruleForm.industry" style="width:430px;" clearable>
              <el-option label="暖通" value="100"/>
              <el-option label="光伏" value="150"/>
              <el-option label="储能" value="200"/>
              <el-option label="其他" value="250"/>
            </el-select>
          </el-form-item>
          <el-form-item label="租户类型">
            <el-select v-model="ruleForm.tenantType" style="width:430px;" clearable>
              <el-option label="工程公司" value="100"/>
              <el-option label="热力公司" value="150"/>
              <el-option label="能源公司" value="200"/>
              <el-option label="其他" value="250"/>
            </el-select>
          </el-form-item>
          <el-form-item label="所在国家">
            <el-select v-model="ruleForm.country" style="width:430px;">
              <el-option v-for="(item, index) in countryList" :key="index" :label="item.name" :value="item.name"/>
            </el-select>
          </el-form-item>
          <el-form-item label="所在省份">
            <el-select v-model="ruleForm.province" style="width:430px;" @change="provinceChange" filterable clearable>
              <el-option v-for="(item, index) in provinceList" :key="index" :label="item.name" :value="item.name"/>
            </el-select>
          </el-form-item>
          <el-form-item label="所在城市">
            <el-select v-model="ruleForm.city" style="width:430px;" @change="cityChange" filterable clearable>
              <el-option v-for="(item, index) in cityList" :key="index" :label="item.name" :value="item.name"/>
            </el-select>
          </el-form-item>
          <el-form-item label="所在区县">
            <el-select v-model="ruleForm.area" style="width:430px;" filterable clearable>
              <el-option v-for="(item, index) in areaList" :key="index" :label="item.name" :value="item.name"/>
            </el-select>
          </el-form-item>
          <el-form-item label="详细地址" prop="address" style="width:500px;">
            <el-input v-model="ruleForm.address" clearable/>
          </el-form-item>
          <el-form-item label="联系姓名" prop="contactPerson" style="width:500px;">
            <el-input v-model="ruleForm.contactPerson" clearable/>
          </el-form-item>
          <el-form-item label="联系电话" prop="contactPhone" style="width:500px;">
            <el-input v-model="ruleForm.contactPhone" clearable/>
          </el-form-item>
          <el-form-item label="联系邮箱" prop="contactEmail" style="width:500px;">
            <el-input v-model="ruleForm.contactEmail" clearable/>
          </el-form-item>
          <el-form-item label="营业执照" prop="imageLicense">
            <DiluUploadImg accept=".png;.jpg"  :limit="1" :fileList="licenseList"  @remove="removeLicense" @success="successLicense"/>
          </el-form-item>
          <el-form-item label="登录图片" prop="imageLogin">
            <DiluUploadImg accept=".png;.jpg"  :limit="1" :fileList="loginList"  @remove="removeLogin" @success="successLogin"/>
          </el-form-item>
          <el-form-item label="后台图标" prop="imageLogo">
            <DiluUploadImg accept=".png;.jpg"  :limit="1" :fileList="logoList"  @remove="removeLogo" @success="successLogo"/>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button style="width:420px;" type="primary" @click="submit">提交保存</el-button>
      </span>
  </div>
</template>

<script>
import DiluUploadImg from "@/components/public/dilu-uploadImg.vue";
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      title: "平台信息配置",
      ruleForm: {
        id: null,           //自增ID
        tenantId: null,     //租户ID
        fullName: "",       //公司全称
        shortName: "",      //公司简称
        platformName: "",   //平台名称
        platformDomain: "", //平台域名
        country: "中国",    //国家
        province: "",       //省份
        city: "",           //城市
        area: "",           //区县
        address: "",        //详细地址
        industry: "250",    //所属行业
        tenantType: "250",  //租户类型
        contactPerson: "",  //客户姓名
        contactPhone: "",   //联系电话
        contactEmail: "",   //联系邮箱
        imageLicense: "",   //营业执照
        imageLogin: "",     //登录页图片
        imageLogo: "",      //后台图标
      },
      province: "",     //当前省份    
      city: "",         //当前城市
      licenseList: [],  //营业执照
      loginList: [],    //登录图片
      logoList: [],     //后台图标
      provinceList: [], //省份列表
      cityList: [],     //城市列表
      areaList: [],     //区县列表
      countryList: [{"name":"中国"}], 
      currentData: {},
      rules: {},
    };
  },
  components: {
    DiluUploadImg
  },
  computed: {
    ...mapState(["buttonTextColor"]),
  },
  mounted() {
    this.getProvince();
    this.getTenantInfo();
  },
  methods: {
    ...mapActions(["getInfoByDomain"]),
    getTenantInfo() {
      var params={};
      this.request.tenantInfo(params).then((res) => {
          if (res.code==0) {
            this.ruleForm.id=res.data.id;                            //自增ID
            this.ruleForm.tenantId=res.data.tenantId;                //租户ID
            this.ruleForm.fullName=res.data.fullName;                //公司全称
            this.ruleForm.shortName=res.data.shortName;              //公司简称
            this.ruleForm.platformName=res.data.platformName;        //平台名称
            this.ruleForm.platformDomain=res.data.platformDomain;    //平台域名
            this.ruleForm.country=res.data.country;                  //国家
            this.ruleForm.province=res.data.province;                //省份
            this.ruleForm.city=res.data.city;                        //城市
            this.ruleForm.area=res.data.area;                        //区县
            this.ruleForm.address=res.data.address;                  //详细地址
            this.ruleForm.industry=res.data.industry;                //所属行业
            this.ruleForm.tenantType=res.data.tenantType;            //租户类型
            this.ruleForm.contactPerson=res.data.contactPerson;      //客户姓名
            this.ruleForm.contactPhone=res.data.contactPhone;        //联系电话
            this.ruleForm.contactEmail=res.data.contactEmail;        //联系邮箱
            this.ruleForm.imageLicense=res.data.imageLicense;        //营业执照
            this.ruleForm.imageLogin=res.data.imageLogin;            //登录图片
            this.ruleForm.imageLogo=res.data.imageLogo;              //后台图标
            //营业执照
            let license = [];
            license.push({
              url: this.ruleForm.imageLicense,
              name: "license"
            });
            this.licenseList = license;
            //登录图片
            let login = [];
            login.push({
              url: this.ruleForm.imageLogin,
              name: "login"
            });
            this.loginList = login;
            //后台图标
            let logo = [];
            logo.push({
              url: this.ruleForm.imageLogo,
              name: "logo"
            });
            this.logoList = logo;
          } else {
            this.$message.error(res.msg);
          }
      });
    },
    getProvince() {
      this.request.getProvince().then((res) => {
        this.provinceList = res.data;
      });
    },
    getCity() {
      let params={};
      params['province']=this.province;
      this.request.getCity(params).then((res) => {
        this.cityList = res.data;
      });
    },
    getArea() {
      let params={};
      params['city']=this.city;
      this.request.getArea(params).then((res) => {
        this.areaList = res.data;
      });
    },
    removeLicense(e) {
      const i = this.licenseList.findIndex((x) => x.name == e);
      this.licenseList.splice(i, 1);
      this.ruleForm.imageLicense = null;
    },
    successLicense(e) {
      this.ruleForm.imageLicense = e.url;
      this.licenseList = e.fileList;
    },
    removeLogin(e) {
      const i = this.loginList.findIndex((x) => x.name == e);
      this.loginList.splice(i, 1);
      this.ruleForm.imageLogin = null;
    },
    successLogin(e) {
      this.ruleForm.imageLogin = e.url;
      this.loginList = e.fileList;
    },
    removeLogo(e) {
      const i = this.logoList.findIndex((x) => x.name == e);
      this.logoList.splice(i, 1);
      this.ruleForm.imageLogo = null;
    },
    successLogo(e) {
      this.ruleForm.imageLogo = e.url;
      this.logoList = e.fileList;
    },
    provinceChange(value) {
      for (let item of this.provinceList) {
        if (item['name']==value) {
          this.province=item['id'];
        }
      }
      this.getCity();
    },
    cityChange(value) {
      for (let item of this.cityList) {
        if (item['name']==value) {
          this.city=item['id'];
        }
      }
      this.getArea();
    },
    cancel() {
      this.$refs["ruleForm"].resetFields();
    },
    //确定
    submit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.request.tenantEdit(this.ruleForm).then((res) => {
            if (res.code==0) {
              this.$message.success("编辑成功");
              this.getInfoByDomain();
            }
          });
        }
      });
    },
  },
};
</script>


<style scoped>
.form-body {
  padding: 0 60px;
  box-sizing: border-box;
}
.dialog-footer {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 500px;
  text-align: center;
}
</style>