<template>
  <el-dialog title="设置通讯参数" :visible.sync="editVisible" width="500px" :before-close="handleClose" :close-on-click-modal="false" center>
    <div class="from-body">
      <el-form :model="ruleForm" ref="ruleForm" :rules="rules" label-width="auto" class="demo-ruleForm">
        <el-form-item label="波特率" prop="baud">
          <el-input v-model="ruleForm.baud" style="width:300px;"/>
        </el-form-item>
        <el-form-item label="心跳率" prop="heart">
          <el-input v-model="ruleForm.heart" style="width:300px;"/>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      ruleForm: {
        baud: null,
        heart: null,
      },
      rules: {
        baud: [
          {
            required: true,
            message: "请设置波特率",
            trigger: "blur",
          }
        ],
        heart: [
          {
            required: true,
            message: "请设置心跳间隔",
            trigger: "blur",
          }
        ]
      }
    };
  },
  computed: {
    ...mapState(["buttonTextColor"]),
  },
  props: ["editVisible","gateway","baudRate","heartBeat"],
  watch: {
    editVisible(newValue) {
      if (newValue) {
        this.ruleForm.baud=this.baudRate;
        this.ruleForm.heart=this.heartBeat;
      }
    },
  },
  methods: {
    onInput() {
      this.$forceUpdate();
    },
    handleClose() {
      this.ruleForm = {
        baud: null,
        heart: null,
      };
      this.$emit("handleClose");
    },
    submit() {
      if (this.ruleForm.baud==null||this.ruleForm.baud=="") {
        this.$message.error("请设置波特率");
        return;
      } 
      if (this.ruleForm.heart==null||this.ruleForm.heart=="") {
        this.$message.error("请设置心跳间隔");
        return;
      } 
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          var param={
            "gateway": this.gateway,
            "baudRate": this.ruleForm.baud,
            "heartBeat": this.ruleForm.heart,
          };
          this.request.baudRate(param).then((res) => {
            if (res.code==0) {
              this.$message.success("修改成功");
              this.handleClose();
            }
          });
        }
      });
    },
  },
};
</script>
<style scoped>
.from-body {
  padding: 0 60px;
  box-sizing: border-box;
}
</style>