<template>
  <div class="dilu-page" style="background-color:#f4f6f9;">
    
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      
    };
  },
  mounted() {
    this.goScreen();
  },
  computed: {
    ...mapState(["platformData"]),
  },
  components: {
   
  },
  methods: {
    goScreen() {
      window.location.href ="http://"+this.platformData.platformDomain+":7236/screen/vsmain/"+localStorage.getItem("token");
    },
  }
};
</script>

<style scoped>

</style>