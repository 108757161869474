<template>
  <div style="height:100%;">
    <el-row :gutter="0" class="layout-row">
      <el-col :span="3" class="layout-col">
        <LeftMenu/>
      </el-col>
      <el-col :span="21" class="layout-col layout-right">
        <transition name="fold-left">
          <div class="view">
            <PageHeader/>
            <router-view class="app-view"></router-view>
          </div>
        </transition>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import LeftMenu from "../../../components/layout/left-menu.vue";
import PageHeader from "../../../components/layout/page-header.vue";
export default {
  data() {
    return {
    };
  },
  components: {
    LeftMenu,
    PageHeader,
  },
  mounted() {
    
  }
};
</script>

<style scoped>
.layout-row {
  flex: 1;
  overflow: hidden;
  height: 100%;
}
.layout-col {
  height: 100%;
  overflow: hidden;
}
.layout-right {
  box-sizing: border-box;
  height: 99.5%;
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  display: flex;
  flex-direction: column;
}
.view {
  background-color: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  box-sizing: border-box;
}
</style>