<template>
    <div>
        <el-dialog title="当前点击材质" :visible="dialogVisible" width="45%" @close="handleClose">
            <p v-for=" (val, key, i) in attrs" :key="key">
                {{ key }}:
                {{ val }}

            </p>
        </el-dialog>
    </div>
</template>
​
<script>
export default {
    name: 'DialogForm',
    props: {
        dialogFlag: {
            default: false
        },
        attrs: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            // 开关
            dialogVisible: false,
        }
    },
    watch: {
        dialogFlag() {
            this.dialogVisible = this.dialogFlag
        }
    },
    methods: {
        handleClose() {
            this.$emit('update:dialogFlag', false)
        },
    }
}
</script>