<template>
  <div class="break-bottom-right">
    <div class="box">
      <div class="title">
        <span>故障详情</span>
      </div>
      <div class="list">
        <div class="list-box">
          <div
            :class="['list-item', 'list-item_' + i.level]"
            v-for="(i, index) in faultDetail"
            :key="'faultDetail_' + index"
          >
            <span class="status">{{ fmtLevelName(i.level) }}</span>
            <p class="p1">{{ i.name }}</p>
            <p class="p2">{{ i.from }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BreakBottomRight",
  props: {
    propsData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    propsData: {
      deep: true,
      handler() {
        const faultData = JSON.parse(JSON.stringify(this.propsData));
        const faultDetail = faultData.faultDetail;
        faultDetail.sort((a, b) => b.level - a.level)
        this.faultDetail = faultDetail;
      },
    },
  },
  data() {
    return {
      faultDetail: [],
    };
  },
  methods: {
    fmtLevelName(val) {
      if (val == 10) {
        return "一般";
      } else if (val == 20) {
        return "中等";
      } else {
        return "严重";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.break-bottom-right {
  .box {
    width: 821px;
    height: 653px;
    opacity: 1;
    background: linear-gradient(
      180deg,
      rgba(27, 53, 56, 0.52) 0%,
      rgba(21, 52, 73, 0) 41%,
      rgba(13, 51, 97, 0.09) 100%
    );
    box-sizing: border-box;
    padding: 12px;
    border: 1px solid;
    border-image: linear-gradient(
        283deg,
        rgba(0, 255, 221, 0.26) 8%,
        rgba(0, 255, 221, 0) 24%,
        rgba(0, 229, 255, 0) 81%,
        rgba(39, 233, 255, 0.66) 96%
      )
      1;
    display: flex;
    flex-direction: column;
    .title {
      width: 128px;
      height: 28px;
      background: url("~@/assets/images/visual/rect.png") no-repeat;
      background-size: 100% 100%;
      padding-left: 24px;
      margin-top: 24px;
      line-height: 28px;
      > span {
        font-family: "microsoft yahei ui","microsoft yahei";
        color: #ffffff;
        font-size: 16px;
      }
    }

    .list {
      flex: 1;
      overflow-y: auto;
      .list-box {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        flex-direction: row;
        align-items: flex-start;
      }
      .list-item {
        width: 388px;
        height: 71px;
        position: relative;
        padding: 22px;
        box-sizing: border-box;
        margin-top: 22px;
        .status {
          position: absolute;
          left: 0;
          top: 0;
          font-family: "microsoft yahei ui","microsoft yahei";
          font-size: 12px;
          color: rgba(255, 255, 255, 0.7);
          text-align: center;
          display: inline-block;
          width: 42px;
          height: 16px;
          background-repeat: no-repeat;
          background-size: 100% 100%;
        }
        .p1 {
          font-family: "microsoft yahei ui","microsoft yahei";
          font-size: 14px;
          font-weight: 500;
          color: rgba(255, 255, 255, 0.9);
        }
        .p2 {
          font-family: "microsoft yahei ui","microsoft yahei";
          font-size: 14px;
          color: rgba(255, 255, 255, 0.7);
        }
        &.list-item_10 {
          background: linear-gradient(
            180deg,
            rgba(33, 231, 231, 0.2) 0%,
            rgba(61, 122, 124, 0.06) 97%
          );

          box-sizing: border-box;
          border: 1px solid;
          border-image: linear-gradient(
              180deg,
              rgba(99, 255, 216, 0.19) 4%,
              rgba(99, 255, 216, 0.6) 51%,
              rgba(33, 114, 123, 0.12) 100%
            )
            1;
          .status {
            background-image: url("./../images/status_1.png");
          }
        }
        &.list-item_20 {
          background: linear-gradient(
            180deg,
            rgba(231, 185, 33, 0.2) 0%,
            rgba(124, 114, 61, 0.06) 94%
          );

          box-sizing: border-box;
          border: 1px solid;
          border-image: linear-gradient(
              180deg,
              rgba(255, 224, 99, 0.19) 4%,
              rgba(255, 213, 99, 0.6) 51%,
              rgba(162, 157, 48, 0.12) 100%
            )
            1;
          .status {
            background-image: url("./../images/status_2.png");
          }
        }

        &.list-item_30 {
          background: linear-gradient(
            180deg,
            rgba(231, 66, 33, 0.2) 0%,
            rgba(124, 61, 67, 0.06) 94%
          );

          box-sizing: border-box;
          border: 1px solid;
          border-image: linear-gradient(
              180deg,
              rgba(255, 99, 138, 0.19) 4%,
              rgba(255, 99, 138, 0.6) 51%,
              rgba(123, 33, 55, 0.12) 100%
            )
            1;
          .status {
            background-image: url("./../images/status_3.png");
          }
        }
      }
    }
  }
}
</style>
