<template>
  <div class="dilu-page">
    <div class="summary-box">
      <summary-pie :propsData="summaryData" />
      <summary-count :propsData="summaryData" />
      <summary-bar :propsData="summaryData" />
    </div>
    <div class="project-table-box">
      <div class="project-search">
        <el-form :inline="true" :model="searchParams" size="mini">
          <el-form-item>
            <el-button size="mini" icon="el-icon-plus" type="primary" @click="add" style="background-color:#409eff;">新增</el-button>
          </el-form-item> 
          <el-form-item label="名称">
            <el-input v-model="searchParams.projectName" placeholder="请输入" style="width:180px;"/>
          </el-form-item>
          <el-form-item label="编号">
            <el-input v-model="searchParams.projectCode" placeholder="请输入" style="width:180px;"/>
          </el-form-item>
          <el-form-item label="区域" prop="province">
            <mr-region ref="mrRegion" @changeRegion="changeRegion" />
          </el-form-item>
          <el-form-item label="日期">
            <el-date-picker type="daterange" placeholder="选择日期" value-format="yyyy-MM-dd" format="yyyy-MM-dd" v-model="searchParams.createdAt" style="width: 236px" clearable/>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSearch" style="width:80px;">查 询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="project-table">
        <DiluTable :list="tableData" :tableConfig="tableConfig" :total="total" :pageSize="searchParams.pageSize" @currentChange="currentChange">
          <el-table-column prop="projectCode" label="项目编号" align="left" width="180">
            <template slot-scope="scope">
              <span @click="detail(scope.row)">{{ scope.row.projectCode }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="projectName" label="项目名称" align="left">
            <template slot-scope="scope">
              <span @click="detail(scope.row)">{{ scope.row.projectName }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="orgId" label="组织名称" align="left">
            <template slot-scope="scope">
              <span @click="detail(scope.row)">{{ orgName(scope.row.orgId) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="项目地址" align="left" width="130">
            <template slot-scope="scope">
              <span @click="detail(scope.row)">{{scope.row.province.split(',')[1]+' '+scope.row.city.split(',')[1]+' '+scope.row.area.split(',')[1]}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="buildingType" label="建筑类型" align="center" width="130">
            <template slot-scope="scope">
              <span>{{ buildName(scope.row) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="deviceCount" label="设备" align="center" width="90"/>
          <el-table-column prop="status" label="状态" align="center" width="90">
            <template slot-scope="scope">
              <span v-if="scope.row.status == 10">在建</span>
              <span v-if="scope.row.status == 20">试运行</span>
              <span v-if="scope.row.status == 30">已交付</span>
            </template>
          </el-table-column>
          <el-table-column prop="createdAt" label="创建日期" align="center" width="150">
            <template slot-scope="scope">
              <span v-if="scope.row.createdAt != undefined">{{
                scope.row.createdAt.substring(0, 10)
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="160" fixed="right">
            <template slot-scope="scope">
              <div class="table-operation">
                <el-button @click="detail(scope.row)" icon="el-icon-view" type="text"></el-button>
                <el-button @click="edit(scope.row)" icon="el-icon-edit" type="text"></el-button>
                <el-button @click="remove(scope.row)" icon="el-icon-delete" type="text"></el-button>
                <el-button @click="screen(scope.row)" icon="el-icon-data-board" type="text"></el-button>
                <el-button @click="ercode(scope.row)" icon="el-icon-full-screen" type="text"></el-button>
              </div>
            </template>
          </el-table-column>
        </DiluTable>
      </div>
    </div>
    <QrCodeShow :projectCode="projectCode" :viewVisible="viewVisible" @handleClose="handleClose"/>
  </div>
</template>

<script>
import DiluTable from "@/components/public/dilu-table.vue";
import { randomString } from "@/utils/util/validate";
import SummaryPie from "./components/summaryPie.vue";
import SummaryBar from "./components/summaryBar.vue";
import SummaryCount from "./components/summaryCount.vue";
import QrCodeShow from "./components/QrCodeShow.vue";
import MrRegion from "./components/regionSelect.vue";
import store from "@/store";
import build from "./js/build";
import { mapState } from "vuex";
export default {
  data() {
    return {
      tableData: [],
      addVisible: false,
      total: 0,
      tableConfig: {
        isTree: false,
        isPage: true,
      },
      searchParams: {
        address: "", // 详细地址
        area: "", // 区
        buildingType: "", // 建筑类型
        city: "", // 市
        country: "", // 国家
        pageNum: 1, // 页码
        pageSize: 12, // 每页条数
        projectCode: "", // 项目编码
        projectName: "", // 项目名称
        province: "", // 省
        status: "", // 状态
        systemType: "", // 系统类型
        town: "", // 乡镇街道 
        createdAt: []// 创建日期
      },
      orgList: [], // 所属组织数据列表
      summaryData: {},
      typeOptions: [],
      buildStatic: build.buildStatic,
      searchAll: {
        key: "",
        pageNum: 1, 
        pageSize: 12
      },
      projectCode: "",  //当前项目编码
      viewVisible: false, //二维码显示框
    };
  },
  mounted() {
    this.listOrg();
    this.setTypeOptions();
    this.projectList();
    this.getSummaryData();
  },
  computed: {
    ...mapState(["platformData"]),
  },
  components: {
    DiluTable,
    SummaryPie,
    SummaryBar,
    SummaryCount,
    MrRegion,
    QrCodeShow
  },
  methods: {
    //获取归属组织名称
    orgName(array) {
      if (this.orgList.length>0) {
        var orgName="";
        var item=this.orgList[0];
        if (item!=null) {
          orgName=item.name;
          for (var i=1; i<array.length; i++) {
            for (var j=0; j<item.orgList.length; j++) {
              if (array[i]==item.orgList[j].id) {
                item=item.orgList[j];
                orgName=orgName+"-"+item.name;
                break;
              }
            }
          }  
        }
        return orgName;
      } else {
        return "--";
      }
    },
    buildName(row) {
      var key='';
      for (var i=0; i<row.buildingType.length; i++) {
        if (key=='') {
          key=row.buildingType[i];
        } else {
          key=key+','+row.buildingType[i];
        }
      }
      if (this.buildStatic.find(item => item.id === key)!=null) {
        return this.buildStatic.find(item => item.id === key).name;
      } else {
        return '';
      }
    },
    //部门管理-查询所有部门
    listOrg() {
      this.request.listOrg().then((res) => {
        if (res.code == 0) {
          this.orgList = [res.data];
        }
      });
    },
    setTypeOptions() {
      const { civilBuild, commercialBuild, publicBuild } = build;
      this.typeOptions = [civilBuild]
        .concat([commercialBuild])
        .concat([publicBuild]);
    },
    // 翻页
    currentChange(e) {
      if (this.searchAll.key) {
        this.searchAll.pageNum = e;
        this.searchAll();
      } else {
        if (!this.isSerch) {
          this.searchParams.pageNum = 1;
          this.searchParams.pageSize = 10;
        }
        this.searchParams.pageNum = e;
        this.projectList();
      }
    },
    projectList() {
      this.request.projectList(this.searchParams).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data;
          this.total = res.dataMaxCount;
        }
      });
    },
    add() {
      var projectCode = "P" + this.platformData.id + randomString(10);
      this.$router.push({
        path: "/project/promgt/pronew",
        query: {
          isAdd: "1",
        },
      });
      this.setCurrentData({ projectCode: projectCode });
    },
    detail(e) {
      this.$router.push({
        path: "/project/proinfo/info",
      });
      this.setCurrentData(e);
    },
    edit(e) {
      this.$router.push({
        path: "/project/promgt/pronew",
        query: {
          isAdd: "2",
        },
      });
      this.setCurrentData(e);
    },
    remove(e) {
      this.$confirm("确认要删除该项目么?", "提醒", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.request.projectDelete({"project":e.projectCode}).then((res) => {
          if (res.code==0) {
            this.projectList();
          }
        });
      });
    },
    ercode(e) {
      this.projectCode=e.projectCode;
      this.viewVisible=true;
    },
    screen(e) {
      if (e.screen!=''&&e.screen!=null&&e.screen!=undefined) {
        this.$router.push({
          path: e.screen,
          query: {
            projectCode: e.projectCode,
          },
        });
        this.setCurrentData(e);
      } else {
        this.$message({
          type: "error",
          message: "该项目没有大屏",
        });
      }
    },
    handleClose() {
      this.viewVisible=false;
    },
    getSummaryData() {
      this.request.projectSummary().then((res) => {
        this.summaryData = res.data || {};
      });
    },
    setCurrentData(data) {
      store.commit("setProjectCurrentRowData", data);
    },
    onReset() {
      this.searchParams = {
        address: "",
        area: "",
        buildingType: "",
        city: "",
        country: "",
        pageNum: 1,
        pageSize: 12,
        projectCode: "",
        projectName: "",
        province: "",
        status: "",
        systemType: "",
        town: "",
        createdAt: []
      }
      this.$refs.mrRegion.reset();
      this.projectList();
    },
    onSearch() {
      this.projectList()
    },
    changeRegion({ province, city, area }) {
      this.searchParams.province = province
      this.searchParams.city = city;
      this.searchParams.area = area;
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-cascader .el-input .is-disabled .el-input__inner {
  color: black!important;
  background-color: white!important;
}
.dilu-page {
  background-color: #f4f4f4;
  padding-bottom: 20px;
  box-sizing: border-box;
}

.summary-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 230px;
  margin-top: 10px;
  margin-bottom: 12px;
}

.project-table-box {
  flex: 1;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding-left: 12px;
  padding-top: 12px;
  padding-bottom: 12px;
  .project-search {
    margin: 12px 0;
    ::v-deep(.el-form) {
      .el-form-item--mini.el-form-item {
        margin-bottom: 0;
      }
      .el-form-item__label {
        line-height: 40px;
      }
    }
  }
  .project-table {
    height: 100%;
  }
}
</style>
