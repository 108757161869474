<template>
  <!-- 左侧菜单 -->
  <div class="left-menu" :style="{background:bgColor}">
    <div class="left-menu-li">
      <div v-for="(item, index) in routerPath.children" :key="index">
        <div class="left-menu-title" v-if="!item.hidden" :style="{color:txtColor}">{{ item.meta.title }}</div>
        <div class="left-menu-list" v-for="(items, indexs) in item.children" :key="indexs">
          <router-link v-if="!items.hidden"
            :class="['left-menu-item', index == indexActive[0] && indexs == indexActive[1] ? 'menu-active' : '',]"
            :to="{path: '/' + routerPath.path + '/' + item.path + '/' + items.path,}"
            :style="{color:txtColor}">
            {{ items.meta.title }} 
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      bgColor: '#1F285A',
      txtColor: 'white'
    };
  },
  watch: {
    styleMode: {
      handler() {
        if (this.styleMode=='dark') {
          this.bgColor='#1F285A';
          this.txtColor='white';
        } else {
          this.bgColor='white';
          this.txtColor='black';
        }
      },
    },
  },
  computed: {
    ...mapState(["indexActive", "routerPath", "styleMode"]),
  },
  mounted() {
    if (this.styleMode=='dark') {
      this.bgColor='#1F285A';
      this.txtColor='white';
    } else {
      this.bgColor='white';
      this.txtColor='black';
    }
  },
};
</script>

<style scoped>
/*
.left-menu {
  background-color: #fff;
  box-shadow: 0px 3px 6px #f4f6f9;
  padding: 10px;
  margin: 10px;
  height: 97%;
  box-sizing: border-box;
  overflow: auto;
}
*/
.left-menu {
  padding: 15px;
  height: 98%;
  overflow: auto;
}  
.left-menu-title {
  font-size: 14px;
  font-family: "microsoft yahei ui","microsoft yahei";
  font-weight: bold;
  color: #000000;
  padding: 10px 20px;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.left-menu-list {
  display: flex;
  flex-direction: column;
}
.left-menu-item {
  font-size: 13px;
  font-family: "microsoft yahei ui","microsoft yahei";
  font-weight: normal;
  color: black;
  padding: 6px 25px;
  box-sizing: border-box;
  cursor: pointer;
  margin: 5px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-left: 6px solid transparent;
  transition: all 0.2s;
}
.menu-active {
  color: white !important;
  background: #1f5bcc;
  border-left: 6px solid #1f5bcc;
}
</style>