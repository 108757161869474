<template>
  <div class="d-card4">
    <div class="title">
      <span>用户设置</span>
    </div>
    <div class="d-card-container">
      <template v-if="userParams && userParams.length > 0">
        <template v-for="i, index in userParams">
          <div class="dv-box" v-if="i.runStatus == 10" :key="index + i.name +'_' + i.id">
            <p class="line1">
              <span>{{ i.name }}</span>
              <span>{{ i.num }}</span>
            </p>
            <p class="line2">
              <span>实时</span>
              <span>设定</span>
            </p>
            <p class="line3">
              <!-- 只读 -->
              <template v-if="i.readWrite == 10 || i.readWrite == 30">
                <span>{{ i.value }}</span>
              </template>
              <!-- 可读写 -->
              <template v-if="i.readWrite == 20 || i.readWrite == 30">
                <!-- 操作区域 -->
                <!--   ;可选值   -->
                <template v-if="i.type === 'select'">
                  <el-select
                    v-model="i.selectValue"
                    size="mini"
                    style="width: 80px"
                    clearable
                    @change="setParamms(i)"
                  >
                    <el-option
                      v-for="item in i.selectList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </template>
                <!-- ～操作范围 "" 输入 -->
                <template v-if="i.type === 'input'">
                  <el-input
                    v-model.trim.number="i.inputValue"
                    size="mini"
                    style="width: 80px"
                    @blur="setInput(i)"
                  >
                  </el-input>
                </template>
              </template>
            </p>
          </div>
        </template>
      </template>

      <mr-empty v-else />
    </div>
  </div>
</template>

<script>
import MrEmpty from "@/components/public/mr-empty.vue";
export default {
  name: "DCard4",
  components: {
    MrEmpty,
  },
  props: {
    propsData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    propsData: {
      deep: true,
      handler() {
        this.userParams = [];
        const { dictParams, paramData } = this.propsData;
        if (dictParams && paramData) {
          const cloneData = JSON.parse(JSON.stringify(dictParams));
          const cloneParamData = JSON.parse(JSON.stringify(paramData));
          this.initParams(cloneData, cloneParamData);
        }
      },
    },
  },
  data() {
    return {
      userParams: [], // 用户设置参数
    };
  },
  mounted() {},
  methods: {
    initParams(cloneParams, paramData) {
      for (let i = 0; i < cloneParams.length; i++) {
        const item = cloneParams[i];
        this.fmtParams(item, paramData);
        this.renderComponent(item);
        this.fmtEdit(item)
      }
    },
    fmtEdit(item) {
      if (item.userEdit == 10 || item.userEdit == 20) {
        this.userParams.push(item)
      }
    },
    fmtParams(item, paramData) {
      const fi = paramData.find((i) => i.name === item.name);
      item.value = fi ? fi.value + "" : "-";
    },
    // 处理可操作区域渲染的组件
    renderComponent(i) {
      if (i.readWrite != 10) {
        const fanwei = i.fanwei;
        if (!fanwei) {
          this.fmtInput(i);
        } else if (fanwei.indexOf(";") > -1) {
          this.fmtSelect(i, ";");
        } else if (fanwei.indexOf("~") > -1) {
          this.fmtInput(i, "~");
        }
      }
    },
    // 处理成下拉数据
    fmtSelect(i, fh) {
      const fanwei = i.fanwei;
      const fanweiArr = fanwei.split(fh);
      let selectList = [];
      fanweiArr.forEach((el) => {
        const itemArr = el.split(":");
        selectList.push({
          label: itemArr[1],
          value: itemArr[0] + "",
        });
      });
      i.selectList = selectList;
      i.type = "select";
      i.selectValue = i.value + "";
    },
    // 处理input
    fmtInput(i, fh) {
      i.type = "input";
      i.inputValue = "";
      if (i.fh) {
        const range = i.split(fh);
        i.range = range;
      }
    },
    setParamms(i) {
      if (i.selectValue == undefined) {
        this.$message({
          type: "warning",
          message: "设置值不能为空",
        });
        return
      }
      const params = {
        name: i.name,
        value: i.selectValue
      }
      this.$emit("setParams", params)
    },
    // 设置输入值
    setInput(i) {
      const value = i.inputValue;
      console.log("设置值--", value)
      if (value === "") {
        this.$message({
          type: "warning",
          message: "设置值不能为空",
        });
        return
      }
      const fanwei = i.fanwei;
      const range = fanwei.split("~");
      if (value < range[0] || value > range[1]) {
        this.$message({
          type: "warning",
          message: `可设置范围：${range[0]}~${range[1]}`,
        });
        return
      }
      const params = {
        name: i.name,
        value: i.inputValue
      }
      this.$emit("setParams", params)
    }
  },
};
</script>

<style scoped lang="scss">
.d-card4 {
  height: calc((calc(100% - 88px)) / 2);
  .title {
    width: 173.25px;
    height: 24px;
    background: url("~@/assets/images/visual/rect.png") no-repeat;
    background-size: 100% 100%;
    padding-left: 18px;
    > span {
      font-family: "microsoft yahei ui","microsoft yahei";
      color: #ffffff;
      font-size: 16px;
    }
  }
  .d-card-container {
    display: flex;
    flex-wrap: wrap;
    height: 329px;
    overflow-y: auto;
    .dv-box {
      width: 185px;
      height: 103px;
      padding: 8px 12px;
      opacity: 1;
      background: linear-gradient(
        180deg,
        rgba(27, 53, 56, 0.52) 0%,
        rgba(21, 52, 73, 0) 41%,
        rgba(13, 51, 97, 0.09) 100%
      );
      box-sizing: border-box;
      border: 1px solid;
      border-image: linear-gradient(
          280deg,
          rgba(0, 255, 221, 0.26) 5%,
          rgba(0, 255, 221, 0) 22%,
          rgba(0, 229, 255, 0) 81%,
          rgba(39, 233, 255, 0.66) 97%
        )
        1;
      margin-right: 16px;
      margin-top: 24px;
      > p {
        display: flex;
        justify-content: space-between;
        align-items: center;
        > span {
          font-family: "microsoft yahei ui","microsoft yahei";
          font-size: 14px;
          color: rgba(255, 255, 255, 0.9);
        }
      }
      p.line3 span:nth-child(1) {
        font-size: 28px;
        display: inline-block;
        width: 100px;
      }

      p.line2 span {
        color: #646464;
        font-size: 12px;
        margin: 12px 0 4px;
      }
      p.line3 span:nth-child(2) {
        display: inline-block;
        width: 62.2px;
        height: 28px;
        opacity: 1;
        background: linear-gradient(
          180deg,
          rgba(211, 218, 255, 0.1) 9%,
          rgba(255, 255, 255, 0.03) 100%
        );
        box-sizing: border-box;
        border: 1px solid rgba(255, 255, 255, 0.15);
        text-align: center;
        line-height: 28px;
        &.open::before {
          content: "";
          display: inline-block;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background: #41dc65;
          margin-right: 8px;
        }
        &.stop::before {
          content: "";
          display: inline-block;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background: #ff0000;
          margin-right: 8px;
        }
      }

      ::v-deep(.el-select),
      ::v-deep(.el-input) {
        .el-input__inner {
          background-color: transparent;
          border: 1px solid rgba(255, 255, 255, 0.15);
          color: #fff;
        }
      }
    }
  }
}
</style>
