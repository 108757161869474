<template>
  <div>
    <v-page-date @changeData="changeData"></v-page-date>
    <div class="mr-energy-list">
      <div class="list" v-for="(i, index) in list" :key="index">
        <div class="count">
          <span>{{ i.name }}</span>
          <span
            >{{ energyStaticData[i.total]
            }}<span class="unit">{{ i.unit }}</span></span
          >
        </div>
        <div class="rate">
          <span v-if="!i.type">
            <span class="rate-label">环比</span>
            <span :class="['num', setIcon(energyStaticData[i.hb])]">{{
              fmtAbs(energyStaticData[i.hb])
            }}</span>
          </span>
          <span v-if="!i.type">
            <span class="rate-label">同比</span>
            <span :class="['num', setIcon(energyStaticData[i.tb])]">{{
              fmtAbs(energyStaticData[i.tb])
            }}</span>
          </span>
          <span :class="['type', setIcon(energyStaticData[i.total])]">{{
            energyStaticData[i.type]
          }}</span>
        </div>
      </div>
    </div>

    <div class="mr-energy-chart">
      <div class="energy-info-box">
        <div class="chart-top">
          <div class="info">
            <div class="img"><img src="./images/img1.png" alt="" /></div>
            <div class="count-box">
              <p>用电总能耗</p>
              <p>
                <span class="count">{{
                  fmtAbs(energyStaticData.ydEnergyTotal)
                }}</span
                ><span class="unit">kw/h</span>
              </p>
              <p class="rate">
                <span>
                  <span class="rate-label">环比</span>
                  <span
                    :class="['num', setIcon(energyStaticData.ydEnergyQOQ)]"
                    >{{ fmtAbs(energyStaticData.ydEnergyQOQ) }}</span
                  >
                </span>
                <span>
                  <span class="rate-label">同比</span>
                  <span
                    :class="['num', setIcon(energyStaticData.ydEnergyYOY)]"
                    >{{ fmtAbs(energyStaticData.ydEnergyYOY) }}</span
                  >
                </span>
              </p>
            </div>
          </div>
          <div class="info">
            <div class="img"><img src="./images/img1.png" alt="" /></div>
            <div class="count-box">
              <p>用电总价</p>
              <p>
                <span class="count">{{ energyStaticData.ydMoneyTotal }}</span
                ><span class="unit">kw/h</span>
              </p>
              <p class="rate">
                <span>
                  <span class="rate-label">环比</span>
                  <span
                    :class="['num', setIcon(energyStaticData.ydMoneyQOQ)]"
                    >{{ fmtAbs(energyStaticData.ydMoneyQOQ) }}</span
                  >
                </span>
                <span>
                  <span class="rate-label">同比</span>
                  <span
                    :class="['num', setIcon(energyStaticData.ydMoneyYOY)]"
                    >{{ fmtAbs(energyStaticData.ydMoneyYOY) }}</span
                  >
                </span>
              </p>
            </div>
          </div>
        </div>
        <div class="chart-box">
          <div class="title">
            <span>用电量统计</span>
          </div>
          <div class="chart">
            <mr-echarts :options="options1"></mr-echarts>
          </div>
        </div>
      </div>
      <div class="energy-info-box">
        <div class="chart-top">
          <div class="info">
            <div class="img"><img src="./images/img1.png" alt="" /></div>
            <div class="count-box">
              <p>谷时用电总价</p>
              <p>
                <span class="count">{{ energyStaticData.boguTotal }}</span
                ><span class="unit">kw/h</span>
              </p>
              <p class="rate">
                <span>
                  <span class="rate-label">环比</span>
                  <span :class="['num', setIcon(energyStaticData.boguQOQ)]">{{
                    fmtAbs(energyStaticData.boguQOQ)
                  }}</span>
                </span>
                <span>
                  <span class="rate-label">同比</span>
                  <span :class="['num', setIcon(energyStaticData.boguYOY)]">{{
                    fmtAbs(energyStaticData.boguYOY)
                  }}</span>
                </span>
              </p>
            </div>
          </div>
          <div class="info">
            <div class="img"><img src="./images/img1.png" alt="" /></div>
            <div class="count-box">
              <p>平时用电总价</p>
              <p>
                <span class="count">{{ energyStaticData.normalTotal }}</span
                ><span class="unit">kw/h</span>
              </p>
              <p class="rate">
                <span>
                  <span class="rate-label">环比</span>
                  <span :class="['num', setIcon(energyStaticData.normalQOQ)]">{{
                    fmtAbs(energyStaticData.normalQOQ)
                  }}</span>
                </span>
                <span>
                  <span class="rate-label">同比</span>
                  <span :class="['num', setIcon(energyStaticData.normalYOY)]">{{
                    fmtAbs(energyStaticData.normalYOY)
                  }}</span>
                </span>
              </p>
            </div>
          </div>
          <div class="info">
            <div class="img"><img src="./images/img1.png" alt="" /></div>
            <div class="count-box">
              <p>峰时用电总价</p>
              <p>
                <span class="count">{{ energyStaticData.bofengTotal }}</span
                ><span class="unit">kw/h</span>
              </p>
              <p class="rate">
                <span>
                  <span class="rate-label">环比</span>
                  <span :class="['num', setIcon(energyStaticData.bofengQOQ)]">{{
                    fmtAbs(energyStaticData.bofengQOQ)
                  }}</span>
                </span>
                <span>
                  <span class="rate-label">同比</span>
                  <span :class="['num', setIcon(energyStaticData.bofengYOY)]">{{
                    fmtAbs(energyStaticData.bofengYOY)
                  }}</span>
                </span>
              </p>
            </div>
          </div>
        </div>
        <div class="chart-box">
          <div class="title">
            <span>峰谷平时电能耗监测统计</span>
          </div>
          <div class="chart">
            <mr-echarts :options="options2"></mr-echarts>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import chart1Opt from "./chartOpt/chart1.js";
import chart2Opt from "./chartOpt/chart2.js";
import MrEcharts from "@/components/public/mr-echarts.vue";
import { mapState } from "vuex";
import VPageDate from "./../components/v-page-date.vue";
export default {
  name: "MrDatalog",
  components: {
    MrEcharts,
    VPageDate,
  },
  data() {
    return {
      list: [
        {
          name: "COP能效比",
          unit: "",
          total: "copLevel",
          hb: "copQOQ",
          tb: "copYOY",
        },
        {
          name: "碳减排统计",
          unit: "kw/h",
          total: "carbonReduction",
          hb: "carbonQOQ",
          tb: "carbonYOY",
        },
        {
          name: "建筑总能耗",
          unit: "kw/h",
          total: "jianzhuTotal",
          hb: "jianzhuQOQ",
          tb: "jianzhuYOY",
        },
        {
          name: "今日用电负荷率",
          unit: "",
          type: "loadLevel",
          total: "loadToday",
        },
        {
          name: "碳排总面积",
          unit: "m",
          total: "tanpaiTotal",
          hb: "tanpaiQOQ",
          tb: "tanpaiYOY",
        },
        {
          name: "单位面积能耗",
          unit: "kwh/m2",
          total: "averageTotal",
          hb: "averageQOQ",
          tb: "averageYOY",
        },
      ],
      chart: null,
      chart: null,
      active: 0,
      options1: {},
      options2: {},
      energyStaticData: {},
      type: null,
      dateVal: null,
    };
  },
  computed: {
    ...mapState(["projectCurrentRowData"]),
  },
  watch: {
    projectCurrentRowData: {
      deep: true,
      handler() {
        this.getData()
      }
    }
  },
  methods: {
    changeData({ type, dateVal }) {
      this.type = type;
      this.dateVal = dateVal;
      this.getData();
    },
    changeTab(index) {
      if (this.active == index) return;
      this.active = index;
    },
    initChart() {
      this.ydlChart();
      this.fpgChart();
    },
    ydlChart() {
      const { ydlList } = this.energyStaticData;
      let xAxisData = [],
        reBengData = [],
        shuiBengData = [],
        bianPinData = [],
        otherData = [];
      ydlList.forEach((i) => {
        xAxisData.push(i.date);
        reBengData.push(i.reBeng * 1);
        shuiBengData.push(i.shuiBeng * 1);
        bianPinData.push(i.bianPin * 1);
        otherData.push(i.other * 1);
      });
      const series = [
        {
          name: "热泵",
          type: "bar",
          stack: "Total",
          data: reBengData,
          itemStyle: {
            color: "#81E2FF",
          },
        },
        {
          name: "水泵",
          type: "bar",
          stack: "Total",
          data: shuiBengData,
          itemStyle: {
            color: "#00B2FF",
          },
        },
        {
          name: "变频器",
          type: "bar",
          stack: "Total",
          data: bianPinData,
          itemStyle: {
            color: "#0256FF",
          },
        },
        {
          name: "其他",
          type: "bar",
          stack: "Total",
          data: otherData,
          itemStyle: {
            color: "#43CB75",
          },
        },
      ];
      chart1Opt.xAxis.data = xAxisData;
      chart1Opt.series = series;
      this.options1 = chart1Opt;
    },
    fpgChart() {
      const { fpgList } = this.energyStaticData;
      let xAxisData = [],
        normalData = [],
        boFengData = [],
        boGuData = [];

      fpgList.forEach((i) => {
        xAxisData.push(i.date);
        normalData.push(i.normal * 1);
        boFengData.push(i.boFeng * 1);
        boGuData.push(i.boGu * 1);
      });
      console.log("用电量--", fpgList);
      const series = [
        {
          name: "低谷时段",
          type: "line",
          stack: "Total",
          data: boGuData,
          itemStyle: {
            color: "#1DCA99",
          },
        },
        {
          name: "平谷时段",
          type: "line",
          stack: "Total",
          data: normalData,
          itemStyle: {
            color: "#0256FF",
          },
        },
        {
          name: "高峰时段",
          type: "line",
          stack: "Total",
          data: boFengData,
          itemStyle: {
            color: "#7700FF",
          },
        },
      ];
      chart2Opt.xAxis.data = xAxisData;
      chart2Opt.series = series;
      this.options2 = chart2Opt;
    },
    getData() {
      this.request
        .energyStatic({
          project: this.projectCurrentRowData.projectCode,
          stype: this.type,
          date: this.dateVal,
        })
        .then((res) => {
          this.energyStaticData = res.data;
          this.initChart();
        });
    },
    // 根据值的正负区分样式
    setIcon(val) {
      if (val > 0) {
        return "up";
      } else if (val < 0) {
        return "down";
      } else {
        return "";
      }
    },
    fmtAbs(val) {
      if (val) {
        return Math.abs(val);
      }
      return 0;
    },
  },
};
</script>

<style scoped lang="scss">
    .mr-energy-list {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 24px;
      .list {
        width: 610px;
        background: url("./images/card_bg.png") no-repeat;
        background-size: 100% 100%;
        margin-bottom: 24px;
        .count {
          height: 90px;
          display: flex;
          justify-content: space-around;
          align-items: center;
          span:nth-child(1) {
            font-family: "microsoft yahei ui","microsoft yahei";
            font-size: 16px;
            font-weight: 500;
            text-align: right;
            color: #ffffff;
          }
          span:nth-child(2) {
            font-family: "microsoft yahei ui","microsoft yahei";
            font-size: 32px;
            font-weight: 500;
            line-height: 36px;
            text-align: right;
            color: #ffffff;
          }
          span.unit {
            font-family: "microsoft yahei ui","microsoft yahei";
            font-size: 12px;
            color: #ffffff;
            margin-left: 24px;
          }
        }
        .rate {
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: space-around;

          .num {
            font-size: 14px;
            margin: 0 8px;
            font-weight: bold;
            margin-left: 12px;
          }
        }

        span.type {
          font-size: 16px;
          text-align: right;
          color: #7c7c7c;
          &.up {
            color: #f53f3f;
          }
          &.down {
            color: #43cb75;
          }
        }
      }
    }

    .mr-energy-chart {
      flex: 1;
      display: flex;
      justify-content: space-between;
      > div {
        width: 922px;
        height: 100%;
        opacity: 1;
        background: linear-gradient(
          180deg,
          rgba(27, 53, 56, 0.52) 0%,
          rgba(21, 52, 73, 0) 41%,
          rgba(13, 51, 97, 0.09) 100%
        );
        box-sizing: border-box;
        border: 1px solid;
        border-image: linear-gradient(
          281deg,
          rgba(0, 255, 221, 0.26) 6%,
          rgba(0, 255, 221, 0) 22%,
          rgba(0, 229, 255, 0) 81%,
          rgba(39, 233, 255, 0.66) 96%
        );
        padding: 24px 12px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      .energy-info-box {
        .chart-top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 24px;
          padding: 0 24px;
          .info {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .img {
            width: 78px;
            height: 78px;
            background: url("./images/bg.png") no-repeat;
            background-size: 100% 100%;
            text-align: center;
            position: relative;
            margin-right: 24px;
            img {
              width: 24px;
              height: 24px;
              position: absolute;
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
              margin: auto;
            }
          }

          .count-box {
            p:nth-child(1) {
              font-family: "microsoft yahei ui","microsoft yahei";
              font-size: 14px;
              color: rgba(255, 255, 255, 0.6);
            }
            p:nth-child(2) {
              margin: 28px 0 24px;
              .count {
                font-family: DIN Alternate;
                font-size: 22px;
                font-weight: bold;
                text-align: right;
                color: #ffffff;
              }
              .unit {
                font-family: "microsoft yahei ui","microsoft yahei";
                font-size: 12px;
                color: rgba(255, 255, 255, 0.5);
                margin-left: 24px;
              }
            }
            p.rate {
              display: flex;
              align-items: center;
              > span {
                font-family: "microsoft yahei ui","microsoft yahei";
                font-size: 12px;
                line-height: 20px;
                display: flex;
                align-items: center;
                color: rgba(255, 255, 255, 0.6);
                background: linear-gradient(
                  270deg,
                  rgba(0, 0, 0, 0) 0%,
                  rgba(52, 214, 187, 0.135) 100%
                );
                padding: 0 4px;
              }
              .num {
                font-family: "microsoft yahei ui","microsoft yahei";
                font-size: 12px;
                line-height: 20px;
                display: flex;
                align-items: center;
              }
            }
          }
        }

        .chart-box {
          .title {
            width: 238.38px;
            height: 24px;
            background: url("~@/assets/images/visual/rect.png") no-repeat;
            background-size: 100% 100%;
            padding-left: 24px;
            margin: 24px 0;
            > span {
              font-family: "microsoft yahei ui","microsoft yahei";
              color: #ffffff;
              font-size: 16px;
            }
          }
          .chart {
            height: 290px;
          }
        }
      }
    }

  .rate-label {
    font-size: 12px;
    color: #7c7c7c;
  }

  .num.up {
    color: #f53f3f;
    &::after {
      content: "";
      display: inline-block;
      width: 18px;
      height: 16px;
      background: url("~@/assets/images/visual/arrow_up.png") no-repeat;
      background-size: 100% 100%;
      margin-left: 8px;
    }
  }

  .num.down {
    color: #43cb75;
    &::after {
      content: "";
      display: inline-block;
      width: 18px;
      height: 16px;
      background: url("~@/assets/images/visual/arrow_down.png") no-repeat;
      background-size: 100% 100%;
      margin-left: 8px;
    }
  }
</style>
