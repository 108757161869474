<template>
  <div class="mr-visual">
    <div class="mr-visual-container">
      <div class="mr-visual-header">
        <v-header />
        <v-card :propsData="projectCode" />
      </div>
      <div class="title">宁夏链杞烘干车间智慧控制系统</div>
      <div class="operation">
        <div style="width:68.5%;margin-left:1.5%;">
          <el-form ref="form" :model="searchParams" label-width="auto" class="search-form" style="height:50px;line-height:50px;display:flex;align-items:center;margin-top:5px;">
            <el-form-item label="设备列表">
              <el-select v-model="searchParams.deviceId" placeholder="请选择查看设备" style="width:350px;margin-top:5px;" @change="getDeviceInfo">
                <el-option v-for="(item, index) in deviceList" :key="index" :label="item.name" :value="item.id" />
              </el-select>
            </el-form-item>
            <el-form-item label="参数属性">
              <el-select v-model="searchParams.readWrite" placeholder="全部属性" style="width:150px;margin-top:5px;" clearable>
                <el-option label="可读" value=10 />
                <el-option label="可写" value=20 />
                <el-option label="可读可写" value=30 />
              </el-select>
            </el-form-item>
            <el-form-item label="参数名称">
              <el-input v-model="searchParams.dictName" style="width:150px;margin-top:5px;" clearable />
            </el-form-item>
          </el-form>
        </div>
        <div style="width:29%;margin-right:1%;display:flex;flex-direction:row;justify-content:flex-end;">
          <div class="btnHome" @click="backHome"><img :src="zhuye" style="width:20px;height:20px;margin-right:5px;" />主页
          </div>
          <div class="btnBack" @click="backPrev"><img :src="back" style="width:16px;height:16px;margin-right:5px;" />返回
          </div>
        </div>
      </div>
      <div class="body1" :style="`--rowHeight: ${rowHeight}`">
        <div class="row1" v-if="showRow1">
          <template  v-for="(itemValue, itemKey, index) in groupData">
            <div class="param-data" v-if="itemKey != 'null'" :key="index">
              <div class="group-title">{{ itemKey }}</div>
              <div class="param-header">
                <div style="width:50%;text-align:left;padding-left:1%;">参数名称</div>
                <div style="width:30%;text-align:center;">参数值</div>
                <div style="width:19%;text-align:center;">操作</div>
              </div>
              <div class="param-list">
                <div style="width:100%;" v-for="(item, index) in itemValue" :key="index">
                  <div v-if="showLine(item)">
                    <div class="param-item">
                      <div style="width:50%;text-align:left;padding-left:1%;">
                        {{ item.chinese != null ? item.chinese : item.name }}</div>
                      <div style="width:30%;text-align:center;">{{ getValue(item) }}</div>
                      <div style="width:19%;text-align:center;">
                        <div v-if="item != null && item.readWrite == 10">无</div>
                        <div v-else @click="showEdit(item)" style="display:flex;justify-content:center;">
                          <img :src="iconEdit" style="width:16px;height:16px;">
                        </div>
                      </div>
                    </div>
                    <div style="width:100%;height:2px;background-color:whitesmoke;">&nbsp;</div>
                  </div>
                </div>
              </div>
            </div>  
          </template>
          <div class="param-data">
            <div class="group-title">其他参数</div>
            <div class="param-header">
              <div style="width:50%;text-align:left;padding-left:1%;">参数名称</div>
              <div style="width:30%;text-align:center;">参数值</div>
              <div style="width:19%;text-align:center;">操作</div>
            </div>
            <div class="param-list">
              <div style="width:100%;" v-for="(item, index) in groupData.null" :key="index">
                <div v-if="showLine(item)">
                  <div class="param-item">
                    <div style="width:50%;text-align:left;padding-left:1%;">
                      {{ item.chinese != null ? item.chinese : item.name }}</div>
                    <div style="width:30%;text-align:center;">{{ getValue(item) }}</div>
                    <div style="width:19%;text-align:center;">
                      <div v-if="item != null && item.readWrite == 10">无</div>
                      <div v-else @click="showEdit(item)" style="display:flex;justify-content:center;">
                        <img :src="iconEdit" style="width:16px;height:16px;">
                      </div>
                    </div>
                  </div>
                  <div style="width:100%;height:2px;background-color:whitesmoke;">&nbsp;</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ParamSet :paramItem="paramItem" :deviceId="current.id" :editVisible="editVisible" @handleClose="handleClose" />
  </div>
</template>

<script lang="js">
import VHeader from "./components/v-header.vue";
import VCard from "./components/v-card.vue";
import ParamSet from "./components/paramSet.vue";
import { handleGroupData } from "@/utils/util/util";
export default {
  name: "nxhg",
  components: {
    VCard,
    VHeader,
    ParamSet
  },
  data() {
    return {
      searchParams: {
        deviceId: null,
        readWrite: null,
        dictName: null,
        pageSize: 20,
        pageNum: 1,
      },
      current: {},
      paramItem: {},
      showRow1: true,
      showRow2: false,
      showRow3: false,
      rowHeight: "100%",
      dictParams1: [],
      dictParams2: [],
      dictParams3: [],
      dictParams4: [],
      dictParams5: [],
      dictParams6: [],
      dictParams7: [],
      dictParams8: [],
      dictParams9: [],
      dictParams10: [],
      dictParams11: [],
      dictParams12: [],
      groupName1: "其他",
      groupName2: "其他",
      groupName3: "其他",
      groupName4: "其他",
      groupName5: "其他",
      groupName6: "其他",
      groupName7: "其他",
      groupName8: "其他",
      groupName9: "其他",
      groupName10: "其他",
      groupName11: "其他",
      groupName12: "其他",
      deviceData: {},
      dictParams: [],
      paramData: [],
      placeList: [],
      deviceList: [],
      itemList: [],
      projectCode: "",
      editVisible: false,
      first: true,
      iconEdit: require("@/assets/images/icon_edit.png"),
      zhuye: require("@/assets/images/nxhg/zhuye.png"),
      back: require("@/assets/images/nxhg/back.png"),
      groupData: {}
    }
  },
  computed: {},
  mounted() {
    this.projectCode = this.$route.query.projectCode;
    this.getPlaceList();
  },
  methods: {
    getDeviceList() {
      this.request.deviceList({ project: this.projectCode }).then((res) => {
        this.deviceList = [];
        var itemList = res.data || [];
        for (var i = 0; i < itemList.length; i++) {
          var name = itemList[i].categoryName + "|" + itemList[i].modelName + "|" + itemList[i].manufacturerName;
          var place = this.getPlace(this.placeList, itemList[i].place[itemList[i].place.length - 1]);
          if (place != null) {
            name = name + "|" + place;
          }
          if (itemList[i].online == 'yes') {
            name = name + "|在线";
          } else {
            name = name + "|离线";
          }
          var item = {
            "id": itemList[i].id,
            "name": name
          };
          if (itemList[i].categoryName=="PLC") {
            this.deviceList.push(item);
            if (i!=0) {
              var temp=this.deviceList[0];
              this.deviceList[0]=item;
              this.deviceList[i]=temp;
            }
          } else {
            this.deviceList.push(item);
          }
        }
        this.itemList = itemList;
        if (this.deviceList.length > 0) {
          this.searchParams.deviceId = this.deviceList[0].id;
          this.getDeviceInfo();
        }
      });
    },
    getPlaceList() {
      this.request.placeList({ project: this.projectCode }).then((res) => {
        if (res.code == 0) {
          this.placeList = [res.data];
          if (this.first) {
            this.first = false;
            this.getDeviceList();
          }
        }
      });
    },
    getPlace(list, placeId) {
      for (var j = 0; j < list.length; j++) {
        if (placeId == list[j].id) {
          return list[j].name;
        }
        if (list[j].nodeList != null && list[j].nodeList.length > 0) {
          return this.getPlace(list[j].nodeList, placeId);
        }
      }
      return null;
    },
    getDeviceInfo() {
      for (var i = 0; i < this.itemList.length; i++) {
        if (this.searchParams.deviceId == this.itemList[i].id) {
          this.current = this.itemList[i];
        }
      }
      var params = {
        deviceId: this.searchParams.deviceId,
        project: this.projectCode
      }
      this.request.deviceScreenInfo(params).then(res => {
        const products = res.data.dictParams;
        this.groupData = handleGroupData(products, 'groupName');
        console.log(this.groupData);
        this.dictParams1 = [];
        this.dictParams2 = [];
        this.dictParams3 = [];
        this.dictParams4 = [];
        this.dictParams5 = [];
        this.dictParams6 = [];
        this.dictParams7 = [];
        this.dictParams8 = [];
        this.dictParams9 = [];
        this.dictParams10 = [];
        this.dictParams11 = [];
        this.dictParams12 = [];
        this.deviceData = res.data || {};
        this.dictParams = this.deviceData.dictParams || [];
        this.paramData = this.deviceData.paramData || [];
        //先进行分组
        var max = 0;
        for (var i = 0; i < this.dictParams.length; i++) {
          var item = this.dictParams[i];
          if (item.groupName != null && item.groupName != '') {
            if (this.groupName1 == "其他" || this.groupName1 == item.groupName) {
              this.groupName1 = item.groupName;
              this.dictParams1.push(item);
              this.dictParams.splice(i, 1);
              i--;
              if (max == 0) max = 1;
            } else if (this.groupName2 == "其他" || this.groupName2 == item.groupName) {
              this.groupName2 = item.groupName;
              this.dictParams2.push(item);
              this.dictParams.splice(i, 1);
              i--;
              if (max == 1) max = 2;
            } else if (this.groupName3 == "其他" || this.groupName3 == item.groupName) {
              this.groupName3 = item.groupName;
              this.dictParams3.push(item);
              this.dictParams.splice(i, 1);
              i--;
              if (max == 2) max = 3;
            } else if (this.groupName4 == "其他" || this.groupName4 == item.groupName) {
              this.groupName4 = item.groupName;
              this.dictParams4.push(item);
              this.dictParams.splice(i, 1);
              i--;
              if (max == 3) max = 4;
            } else if (this.groupName5 == "其他" || this.groupName5 == item.groupName) {
              this.groupName5 = item.groupName;
              this.dictParams5.push(item);
              this.dictParams.splice(i, 1);
              i--;
              if (max == 4) max = 5;
            } else if (this.groupName6 == "其他" || this.groupName6 == item.groupName) {
              this.groupName6 = item.groupName;
              this.dictParams6.push(item);
              this.dictParams.splice(i, 1);
              i--;
              if (max == 5) max = 6;
            } else if (this.groupName7 == "其他" || this.groupName7 == item.groupName) {
              this.groupName7 = item.groupName;
              this.dictParams7.push(item);
              this.dictParams.splice(i, 1);
              i--;
              if (max == 6) max = 7;
            } else if (this.groupName8 == "其他" || this.groupName8 == item.groupName) {
              this.groupName8 = item.groupName;
              this.dictParams8.push(item);
              this.dictParams.splice(i, 1);
              i--;
              if (max == 7) max = 8;
            } else if (this.groupName9 == "其他" || this.groupName9 == item.groupName) {
              this.groupName9 = item.groupName;
              this.dictParams9.push(item);
              this.dictParams.splice(i, 1);
              i--;
              if (max == 8) max = 9;
            } else if (this.groupName10 == "其他" || this.groupName10 == item.groupName) {
              this.groupName10 = item.groupName;
              this.dictParams10.push(item);
              this.dictParams.splice(i, 1);
              i--;
              if (max == 9) max = 10;
            } else if (this.groupName11 == "其他" || this.groupName11 == item.groupName) {
              this.groupName11 = item.groupName;
              this.dictParams11.push(item);
              this.dictParams.splice(i, 1);
              i--;
              if (max == 10) max = 11;
            }
          }
        }
        if (max < 4) {
          var step = 4 - max;
          for (var i = 0; i < this.dictParams.length; i = i + step) {
            if (step == 4) {
              if (i < this.dictParams.length) {
                this.dictParams1.push(this.dictParams[i]);
              }
              if (i < (this.dictParams.length - 1)) {
                this.dictParams2.push(this.dictParams[i + 1]);
              }
              if (i < (this.dictParams.length - 2)) {
                this.dictParams3.push(this.dictParams[i + 2]);
              }
              if (i < (this.dictParams.length - 3)) {
                this.dictParams4.push(this.dictParams[i + 3]);
              }
            } else if (step == 3) {
              if (i < this.dictParams.length) {
                this.dictParams2.push(this.dictParams[i]);
              }
              if (i < (this.dictParams.length - 1)) {
                this.dictParams3.push(this.dictParams[i + 1]);
              }
              if (i < (this.dictParams.length - 2)) {
                this.dictParams4.push(this.dictParams[i + 2]);
              }
            } else if (step == 2) {
              if (i < this.dictParams.length) {
                this.dictParams3.push(this.dictParams[i]);
              }
              if (i < (this.dictParams.length - 1)) {
                this.dictParams4.push(this.dictParams[i + 1]);
              }
            } else {
              this.dictParams4.push(this.dictParams[i]);
            }
          }
          this.showRow1 = true;
          this.showRow2 = false;
          this.showRow3 = false;
          this.rowHeight = "100%";
        } else if (max < 8) {
          var step = 8 - max;
          for (var i = 0; i < this.dictParams.length; i = i + step) {
            if (step == 4) {
              if (i < this.dictParams.length) {
                this.dictParams5.push(this.dictParams[i]);
              }
              if (i < (this.dictParams.length - 1)) {
                this.dictParams6.push(this.dictParams[i + 1]);
              }
              if (i < (this.dictParams.length - 2)) {
                this.dictParams7.push(this.dictParams[i + 2]);
              }
              if (i < (this.dictParams.length - 3)) {
                this.dictParams8.push(this.dictParams[i + 3]);
              }
            } else if (step == 3) {
              if (i < this.dictParams.length) {
                this.dictParams6.push(this.dictParams[i]);
              }
              if (i < (this.dictParams.length - 1)) {
                this.dictParams7.push(this.dictParams[i + 1]);
              }
              if (i < (this.dictParams.length - 2)) {
                this.dictParams8.push(this.dictParams[i + 2]);
              }
            } else if (step == 2) {
              if (i < this.dictParams.length) {
                this.dictParams7.push(this.dictParams[i]);
              }
              if (i < (this.dictParams.length - 1)) {
                this.dictParams8.push(this.dictParams[i + 1]);
              }
            } else {
              this.dictParams8.push(this.dictParams[i]);
            }
          }
          this.showRow1 = true;
          this.showRow2 = true;
          this.showRow3 = false;
          this.rowHeight = "50%";
        } else if (max < 12) {
          var step = 12 - max;
          for (var i = 0; i < this.dictParams.length; i = i + step) {
            if (step == 4) {
              if (i < this.dictParams.length) {
                this.dictParams9.push(this.dictParams[i]);
              }
              if (i < (this.dictParams.length - 1)) {
                this.dictParams10.push(this.dictParams[i + 1]);
              }
              if (i < (this.dictParams.length - 2)) {
                this.dictParams11.push(this.dictParams[i + 2]);
              }
              if (i < (this.dictParams.length - 3)) {
                this.dictParams12.push(this.dictParams[i + 3]);
              }
            } else if (step == 3) {
              if (i < this.dictParams.length) {
                this.dictParams10.push(this.dictParams[i]);
              }
              if (i < (this.dictParams.length - 1)) {
                this.dictParams11.push(this.dictParams[i + 1]);
              }
              if (i < (this.dictParams.length - 2)) {
                this.dictParams12.push(this.dictParams[i + 2]);
              }
            } else if (step == 2) {
              if (i < this.dictParams.length) {
                this.dictParams11.push(this.dictParams[i]);
              }
              if (i < (this.dictParams.length - 1)) {
                this.dictParams12.push(this.dictParams[i + 1]);
              }
            } else {
              this.dictParams12.push(this.dictParams[i]);
            }
          }
          this.showRow1 = true;
          this.showRow2 = true;
          this.showRow3 = true;
          this.rowHeight = "33%";
        }

      });
    },





    showLine(item) {
      if (this.searchParams.readWrite == null || this.searchParams.readWrite == '') {
        if (this.searchParams.dictName == null || this.searchParams.dictName == '') {
          return true;
        } else {
          if (item.name.includes(this.searchParams.dictName)) {
            return true;
          } else {
            return false;
          }
        }
      } else {
        if (this.searchParams.readWrite == item.readWrite) {
          if (this.searchParams.dictName == null || this.searchParams.dictName == '') {
            return true;
          } else {
            if (item.name.includes(this.searchParams.dictName)) {
              return true;
            } else {
              return false;
            }
          }
        } else {
          return false;
        }
      }
    },
    getValue(item) {
      if (item != null && item != undefined) {
        let num = null;
        if (this.paramData != null && this.paramData != undefined) {
          for (var i = 0; i < this.paramData.length; i++) {
            if (item.name == this.paramData[i].name) {
              item.value = this.paramData[i].value;
              num = this.paramData[i].value;
            }
          }
        }
        if (num != null) {
          let fanwei = item.fanwei;
          if (fanwei == "" || fanwei == null || fanwei == undefined) {
            return this.formatNum(num);
          } else {
            if (fanwei.includes(":")) {
              let array = fanwei.split(";");
              for (var i = 0; i < array.length; i++) {
                let data = array[i].split(":");
                if (("" + num) == data[0]) {
                  return data[1];
                }
              }
            }
          }
          return this.formatNum(num);
        }
      }
      return "";
    },
    formatNum(num) {
      if (num == null || num == undefined) {
        return "";
      } else {
        let array = ("" + num).split(".");
        if (array.length != 2) {
          return num;
        } else if (array[1].length > 4) {
          return array[0] + "." + array[1].substring(0, 4);
        } else {
          return num;
        }
      }
    },
    showEdit(item) {
      if (this.current.online == "yes") {
        this.paramItem = item;
        this.editVisible = true;
      } else {
        this.$message.error("当前设备不在线");
      }
    },
    handleClose() {
      this.editVisible = false;
    },
    backHome() {
      this.$router.replace({
        path: "/project"
      });
    },
    backPrev() {
      this.$router.replace({
        path: "/nxhg",
        query: {
          projectCode: this.projectCode,
        },
      });
    }
  }
}
</script>
<style scoped lang="scss">
.mr-visual {
  height: 100%;
  background: #EFEFEF;
  overflow: hidden;
  position: relative;

  .mr-visual-container {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .mr-visual-header {
    height: 52px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    background: black;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 24px;
    box-sizing: border-box;
  }

  .title {
    width: 100%;
    height: 52px;
    line-height: 52px;
    text-align: center;
    color: white;
    font-size: 20px;
    top: 0px;
    position: absolute;
    z-index: 200;
  }

  .operation {
    margin-top: 65px;
    width: 100%;
    height: 50px;
    line-height: 50px;
    display: flex;
    flex-direction: row;
  }

  .btnSwitch {
    width: 90px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    margin-left: 15px;
    margin-right: 10px;
    color: #2D76F6;
    background: white;
    border-radius: 10px;
  }

  .labelStatus {
    width: 80px;
    height: 36px;
    line-height: 36px;
    font-size: 16px;
    font-weight: bold;
    margin-left: 15px;
  }

  .btnHome {
    width: 90px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    margin-right: 10px;
    color: black;
    background: white;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btnBack {
    width: 90px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    margin-left: 15px;
    color: black;
    background: white;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .body1 {
    width: 100%;
    height: 86%;
    display: flex;
    flex-direction: column;
  }

  .row1 {
    width: 98%;
    height: var(--rowHeight);
    min-height: 85vh;
    margin-left: 1%;
    margin-right: 1%;
    background: white;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    overflow: auto;
  }

  .row2 {
    width: 98%;
    height: var(--rowHeight);
    margin-left: 1%;
    margin-right: 1%;
    margin-top: 1%;
    background: white;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
  }

  .row3 {
    width: 98%;
    height: var(--rowHeight);
    margin-left: 1%;
    margin-right: 1%;
    margin-top: 1%;
    background: white;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
  }

  .param-item {
    display: flex;
    align-items: center;
    width: 100%;
    height: 36px;
  }

  .param-data {
    min-width: 24%;
    margin-left: 0.5%;
    margin-right: 0.5%;
    margin-top: 10px;
  
    border-radius: 5px;
    flex-shrink: 0;
    flex-grow: 1;
  }
  .group-title {
      font-weight: 400;
      color: #1f2f3d;
      font-size: 22px;
      margin: 0 0 10px 6px;
    }
  .param-header {
    display: flex;
    align-items: center;
    width: 100%;
    height: 36px;
    background-color: whitesmoke;
  }
  .param-list{
    height: calc(100% - 100px);

    overflow-y: auto;
  }

  .param-select {
    margin-top: 15px;
    width: 100%;
    display: flex;
  }

  .select-title {
    width: 28%;
    margin-left: 2%;
    text-align: left;
  }

  .select-check {
    width: 67%;
    text-align: right;
    margin-right: 2%;
  }

  .pick-date {
    ::v-deep .el-input__inner {
      height: 30px;
      line-height: 30px;
    }

    ::v-deep .el-input__icon {
      height: 30px;
      line-height: 30px;
    }
  }

  .param-setting {
    display: flex;
    align-items: center;
    width: 97%;
    height: 40px;
    margin-left: 1.5%;
    margin-right: 1.5%;
    margin-top: 6px;
    margin-bottom: 6px;
    background-color: azure;
  }

  .param-title {
    font-size: 16px;
    width: 49%;
    padding-left: 1%;
    text-align: left;
  }

  .param-date {
    width: 49%;
    padding-right: 1%;
    text-align: right;
  }

}
</style>
