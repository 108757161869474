<template>
  <div class="d-title-bg">

  </div>
</template>

<script>
export default {
  name: "DTitleBg",
  data() {
    return {}
  }
}
</script>
<style scoped>
.d-title-bg {
  background: url("~@/assets/images/visual/rect.png") no-repeat;
  background-size: 100% 100%;
  padding: 12px;
}
</style>