export default {
  tooltip: {
    trigger: 'axis'
  },
  color: ['#0958D9', '#DCA341', '#0EEFFF', '#41DC65', '#FF4D4F', '#94E1FF', '#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
  legend: {
    data: [],
    icon: "circle",
    itemWidth: 10,
    itemHeight: 10,
    right: 0,
    top: 0,
    textStyle: {
     color: "#fff"
    }
  },
  grid: {
    top: "20%",
    left: '0%',
    right: '4%',
    bottom: 0,
    containLabel: true
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    data: [],
    axisLabel: {
      color: "#fff",
    },
    axisLine: {
      lineStyle: {
        color: "#fff",
        opacity: 0.4
      }
    },
    axisTick: {
      show: false,
    },
  },
  yAxis: {
    type: 'value',
    axisLabel: {
      color: "#fff",
      rotate: 30
    },
    axisLine: {
      show: true
    },
    splitLine: {
      show: true,
      lineStyle: {
        opacity: 0.4
      }
    },
    interval: 20,
    min: val => {
      console.log("y--min:", val)
      return val.min
    },
  },
  series: [
    // {
    //   name: '室内温度',
    //   type: 'line',
    //   stack: 'Total',
    //   data: [120, 132, 101, 134, 90, 230, 210],
    //   itemStyle: {
    //     color: "#DCA341"
    //   }
    // },
    // {
    //   name: '进水温度',
    //   type: 'line',
    //   stack: 'Total',
    //   data: [20, 12, 301, 144, 190, 130, 240],
    //   itemStyle: {
    //     color: "#0958D9"
    //   }
    // },
    // {
    //   name: '电流',
    //   type: 'line',
    //   stack: 'Total',
    //   data: [20, 12, 301, 144, 190, 130, 240],
    //   itemStyle: {
    //     color: "#0EEFFF"
    //   }
    // },
  ]
};