<template>
  <div :class="['form-sub-title', required ? 'form-sub-title__required' : '']">
    <slot name="prefixIcon"></slot>
    <slot></slot>
    <slot name="suffixIcon"></slot>
  </div>
</template>

<script lang="js">

export default {
  name: 'FormTitle',
  props: {
    required: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  }
}
</script>
<style scoped lang="scss">
.form-sub-title {
  font-family: "microsoft yahei ui","microsoft yahei";
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
  &__required::before {
    content: "*";
    color: red;
    margin-right: 8px;
    vertical-align: middle;
  }
}
</style>
