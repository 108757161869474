<template>
  <el-dialog :title="title" :visible.sync="editVisible" width="1024px" :before-close="handleClose" :close-on-click-modal="false" center>
    <div class="from-body" style="width:100%;display:flex;flex-direction:column;">
      <div class="search">
       <el-form ref="form" :model="searchParams" label-width="auto" class="search-form">
        <el-form-item label="设备类型">
           <el-select v-model="searchParams.category" placeholder="请选择" style="width:150px;" disabled>
            <el-option v-for="(item, index) in categoryList" :key="index" :label="item.name" :value="item.id"/>
           </el-select>
        </el-form-item>
        <el-form-item label="设备型号">
           <el-select v-model="searchParams.model" placeholder="请选择" style="width:150px;" disabled>
            <el-option v-for="(item, index) in modelList" :key="index" :label="item.name" :value="item.id"/>
           </el-select>
        </el-form-item>
        <el-form-item label="制造厂商">
           <el-select v-model="searchParams.manufacturer" placeholder="请选择" style="width:150px;" disabled>
            <el-option v-for="(item, index) in manufacturerList" :key="index" :label="item.name" :value="item.id"/>
           </el-select>
        </el-form-item>
       </el-form>
      </div>
      <DiluTable :list="tableData" :tableConfig="tableConfig" :pageSize="searchParams.pageSize" @currentChange="currentChange" :total="total">
       <el-table-column prop="id" label="设备ID" width="100" align="center"/>       
       <el-table-column prop="status" label="在线状态" width="120" align="center">
          <template slot-scope="scope">
              <span v-if="scope.row.status == 'yes'">在线</span>
              <span v-if="scope.row.status == 'no'">离线</span>
          </template>
       </el-table-column>
       <el-table-column prop="gateway" label="网关编号" width="150" align="left"/>
       <el-table-column prop="address" label="从机地址" width="100" align="left"/>
       <el-table-column prop="categoryName" label="设备类型" width="180" align="left"/>
       <el-table-column prop="modelName" label="设备型号" width="180" align="left"/>
       <el-table-column prop="manufacturerName" label="制造商" width="180" align="left"/>
       <el-table-column prop="projectCode" label="关联项目" width="200" align="left"/>
       <el-table-column prop="projectName" label="项目名称" width="200" align="left"/>
       <el-table-column prop="remark" label="操作" align="center" fixed="right" min-width="120">
          <template slot-scope="scope">
            <div class="table-operation">
              <el-button @click="joinGray(scope.row)" type="text">加入测试</el-button>
            </div>
          </template>
       </el-table-column>
      </DiluTable>
    </div>  
  </el-dialog>
</template>

<script>
import DiluTable from "@/components/public/dilu-table.vue";
import { mapState } from "vuex";
export default {
  data() {
    return {
      title: "选择测试设备",
      tableConfig: {
         isTree: false,
         isPage: true, 
      },
      searchParams: {
         category: null,
         model: null,
         manufacturer: null,
         taskId: null,
         pageNum: 1, 
         pageSize: 12,
      },
      total: 0,
      tableData: [],
      categoryList: [],
      modelList: [],
      manufacturerList: [],
    };
  },
  components: {
    DiluTable,
  },
  computed: {
    ...mapState(["buttonTextColor"]),
  },
  props: ["editVisible","grayTask"],
  watch: {
    editVisible(newValue) {
      if (newValue) {
        this.searchParams.category=this.grayTask.category;
        this.searchParams.model=this.grayTask.model;
        this.searchParams.manufacturer=this.grayTask.manufacturer;
        this.searchParams.taskId=this.grayTask.id;
        this.getCategoryList();
        this.getManufacturerList();
        this.getModelList();
        this.getDeviceList();
      }
    },
  },
  methods: {
    getCategoryList() {
      this.request.categoryList().then((res) => {
        if (res.code==0) {
          this.categoryList = res.data;
        }
      });
    },
    getModelList() {
      if (this.searchParams.category!=null) {
        var params={
          category: this.searchParams.category
        };
        this.request.modelList(params).then((res) => {
          if (res.code==0) {
            this.modelList = res.data;
          }
        });
      } else {
        this.modelList=[];
      }
    },
    getManufacturerList() {
      this.request.manufacturerList().then((res) => {
        if (res.code==0) {
          this.manufacturerList = res.data;
        }
      });
    },
    getDeviceList() {
      this.request.searchDeviceList(this.searchParams).then((res) => {
        if (res.code == 0) {
          this.tableData=res.data;
          this.total=res.dataMaxCount;
        }
      });
    },
    joinGray(e){
      var params={
        taskId: this.searchParams.taskId,
        deviceId: e.id
      };
      this.request.addOta(params).then((res) => {
        if (res.code == 0) {
          this.handleClose();
        }
      });
    },
    handleClose() {
      this.$emit("handleClose");
    },
    currentChange(e) {
       this.searchParams.pageNum = e;
       this.getDeviceList();
    },
  },
};
</script>
<style scoped>
</style>