<template>
  <!-- 组织结构 -->
  <div class="dilu-page">
    <div class="depart-view">
      <div class="depart-view-left">
        <div class="tree">
          <el-tree ref="tree" 
          :data="dataList" 
          node-key="id" 
          :props="defaultProps" 
          @node-click="treeClick" 
          :expand-on-click-node="false" 
          default-expand-all>
            <span class="custom-tree-node show-hide" slot-scope="{ node, data }">
                <span>{{ node.label }}（{{ data.userCount }}）</span>
                <span style="display:none;">
                    <i @click="() => appendNode(data)" class="el-icon-plus" style="margin-right:10px;" title="增加"></i>
                    <i v-if="data.id !== 1" @click="() => editNode(data)" class="el-icon-edit" style="margin-right:10px;" title="编辑"></i>
                    <i v-if="data.id !== 1" @click="() => removeNode(data)" class="el-icon-delete" title="删除"></i>
                </span>
            </span>
          </el-tree>
        </div>
      </div>
      <div class="depart-view-right">
        <div class="page-right">
          <DiluTable :list="tableData" :tableConfig="tableConfig" :total="total" :pageSize="params.pageSize" @currentChange="currentChange">
            <el-table-column prop="name" label="员工姓名" align="left"/>
            <el-table-column prop="account" label="登录账号" align="left"/>
            <el-table-column prop="orgName" label="所属部门" align="left"/>
            <el-table-column prop="mobile" label="手机号码" align="center"/>
            <el-table-column prop="status" label="在职状态" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.status==10">在职</span>
                <span v-if="scope.row.status==20">离职</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" fixed="right">
              <template slot-scope="scope">
                <div class="table-operation">
                  <el-button @click="see(scope.row)" type="text">查看</el-button>
                  <el-button @click="edit(scope.row)" type="text">编辑</el-button>
                  <el-button @click="remove(scope.row)" type="text">删除</el-button>
                </div>
              </template>
            </el-table-column>
          </DiluTable>
        </div>
      </div>
    </div>

    <!-- 查看和编辑组织 -->
    <DepartMentAddModule :id="id" :parentId="parentId" :name="name" :orgVisible="orgVisible" @handleClose="handleClose" @listOrg="listOrg"/>

     <!-- 查看和编辑账号 -->
     <AccountManagementAdd :accountVisible="accountVisible" @accountClose="accountClose" @accountManagementList="accountManagementList" :accountManagementAddState="accountManagementAddState" :currentAccount="currentAccount"/>

  </div>
</template>


<script>
import { mapState } from "vuex";
import DiluTable from "@/components/public/dilu-table.vue";
import DepartMentAddModule from "./module/departMentAddModule.vue";
import AccountManagementAdd from "./module/accountManagementAdd.vue";
export default {
  data() {
    return {
      parentId: null,
      id: null,
      name: null,
      orgVisible: false,
      currentAccount: {}, //当前账号
      accountManagementAddState: 0, //弹窗类型 1：编辑 2：查看
      accountVisible: false, //新增账号
      params: {
        orgId: null,
        pageNum: 1,
        pageSize: 12,
      },
      tableConfig: {
        isTree: false,
        isPage: true,
      },
      tableData: [],
      total: 0,
      dataList: [],
      defaultProps: {
        children: "orgList",
        label: "name"
      },
    };
  },
  computed: {
    ...mapState(["buttonTextColor"]),
  },
  components: {
    DiluTable,
    DepartMentAddModule,
    AccountManagementAdd
  },
  mounted() {
    this.listOrg();
    this.accountManagementList();
  },
  methods: {
     //查看
     see(e) {
      var params={};
      params['id']=e.id;
      this.request.accountManagementInfo(params).then((res) => {
        if (res.code==0) {
          this.currentAccount = res.data;
          this.accountManagementAddState = 2;
          this.accountVisible = true;
        }
      });
    },
    //编辑
    edit(e) {
      var params={};
      params['id']=e.id;
      this.request.accountManagementInfo(params).then((res) => {
        if (res.code==0) {
          this.currentAccount = res.data;
          this.accountManagementAddState = 1;
          this.accountVisible = true;
        }
      });
    },
    //删除
    remove(e) {
      this.$confirm("确认删除该账号？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var params={};
          params["id"]=e.id;
          this.request.accountManagementDelete(params).then((res) => {
            if (res.code==0) {
              this.$message.success("删除成功");
              this.accountManagementList();
            }
          });
        })
        .catch(() => {});
    },
    // 新增树节点
    appendNode(data) {
      this.id = null;
      this.name = null;
      this.parentId = data.id;
      this.orgVisible = true;
    },
    // 编辑树节点
    editNode(data) {
      this.id = data.id;
      this.parentId = null;
      this.name = data.name;
      this.orgVisible = true;
    },
     // 删除树节点
     removeNode(data) {
      this.$confirm("确定删除选中部门？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        var params={};
        params['id']=data.id;
        this.request.deleteOrg(params).then((res) => {
          if (res.code==0) {
            this.$message({
              type: "success",
              message: "删除成功",
            });
            this.listOrg();
          }
        });
      });
    },
    handleClose() {
      this.orgVisible = false;
    },
    //关闭新增账号弹窗
    accountClose() {
      this.accountVisible = false;
    },
    //翻页
    currentChange(e) {
      this.params.pageNum = e;
      this.accountManagementList();
    },
    //点击节点触发
    treeClick(e) {
      this.params.orgId=e.id;
      this.accountManagementList();
    },
    //账号管理-列表（分页）
    accountManagementList() {
      this.request.accountManagementList(this.params).then((res) => {
        this.tableData = res.data;
        this.total = res.dataMaxCount;
      });
    },
    //部门管理-查询所有部门
    listOrg() {
      this.request.listOrg().then((res) => {
        this.dataList = [res.data];
      });
    },
  },
};
</script>


<style scoped>
.page-row {
  height: 100%;
  /* margin: 0 !important; */
}

.page-col {
  border-right: 1px solid #eee;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.page-col:last-child {
  border: none;
}
.tree {
  flex: 1;
  overflow: auto;
}
.page-right {
  height: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}

.depart-view {
  width: 100%;
  display: flex;
  height: 100%;
}
.depart-view-left {
  width: 260px;
  padding-right: 10px;
  box-sizing: border-box;
  flex-shrink: 0;
}
.depart-view-right {
  flex: 1;
  padding-left: 10px;
  margin-left: 10px;
  box-sizing: border-box;
  border-left: 1px solid #d7ddeb;
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
.el-tree ::v-deep.el-tree-node > .el-tree-node__content{
    height: 40px;
}
.show-hide:hover :nth-child(2) {
  display: inline-block !important;
}

</style>