<template>
  <el-dialog :title="title" :visible.sync="editVisible" width="450px" :before-close="handleClose" :close-on-click-modal="false" center>
    <div class="from-body">
      <el-form :model="ruleForm" ref="ruleForm" :rules="rules" label-width="auto" class="demo-ruleForm">
        <el-form-item label="设备类型">
           <el-select v-model="ruleForm.category" placeholder="请选择" style="width:300px;" @change="getModelList" clearable>
            <el-option v-for="(item, index) in categoryList" :key="index" :label="item.name" :value="item.id"/>
           </el-select>
        </el-form-item>
        <el-form-item label="设备型号">
           <el-select v-model="ruleForm.model" placeholder="请选择" style="width:300px;" clearable>
            <el-option v-for="(item, index) in modelList" :key="index" :label="item.name" :value="item.id"/>
           </el-select>
        </el-form-item>
        <el-form-item label="制造厂商">
           <el-select v-model="ruleForm.manufacturer" placeholder="请选择" style="width:300px;" clearable>
            <el-option v-for="(item, index) in manufacturerList" :key="index" :label="item.name" :value="item.id"/>
           </el-select>
        </el-form-item>
        <el-form-item label="固件名称" prop="name">
          <el-input v-model="ruleForm.name" style="width:300px;" clearable/>
        </el-form-item>
        <el-form-item label="固件版本" prop="version">
          <el-input v-model="ruleForm.version" style="width:300px;" clearable/>
        </el-form-item>
        <el-form-item label="启用状态" prop="status">
          <el-select v-model="ruleForm.status" placeholder="请选择" style="width:300px;">
            <el-option label="启用" :value=10 />
            <el-option label="禁用" :value=20 />
          </el-select>
        </el-form-item>
        <el-form-item label="上传固件" prop="fileUrl">
          <DiluUploadFile :limit="2" :fileList="fileList"  @remove="removeFile" @success="successFile"/>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button @click="submit" type="primary">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import DiluUploadFile from "@/components/public/dilu-uploadfile.vue";
import { mapState } from "vuex";
export default {
  data() {
    return {
      title: "新增固件",
      ruleForm: {
         id: null,
         category: null,
         model: null,
         manufacturer: null,
         name: "",
         version: "",
         status: 10,
         fileUrl: ""
      },
      rules: {
        category: [
          {
            required: true,
            message: "请设置设备类型",
            trigger: "blur",
          },
        ],
        model: [
          {
            required: true,
            message: "请设置设备型号",
            trigger: "blur",
          },
        ],
        manufacturer: [
          {
            required: true,
            message: "请设置设备制造商",
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: "请填写固件名称",
            trigger: "blur",
          },
        ],
        version: [
          {
            required: true,
            message: "请填写固件版本号",
            trigger: "blur",
          },
        ],
        fileUrl: [
          {
            required: true,
            message: "请上传升级固件",
            trigger: "blur",
          },
        ],
        status: [
          {
            required: true,
            message: "请填设置固件状态",
            trigger: "blur",
          },
        ],
      },
      fileList: [],
      categoryList: [],
      modelList: [],
      manufacturerList: [],
    };
  },
  components: {
    DiluUploadFile
  },
  computed: {
    ...mapState(["buttonTextColor"]),
  },
  props: ["editVisible","firmwareItem","editStatus"],
  watch: {
    editVisible(newValue) {
      if (newValue) {
        this.getCategoryList();
        this.getManufacturerList();
        if (this.editStatus==0) {
          this.title="新增固件";
          this.ruleForm={
            id: null,
            category: null,
            model: null,
            manufacturer: null,
            name: "",
            version: "",
            status: 10,
            fileUrl: "[]"
          };
          this.fileList = [];
        } else {
          this.title="编辑固件";
          this.ruleForm={
            id: this.firmwareItem.id,
            category: this.firmwareItem.category,
            model: this.firmwareItem.model,
            manufacturer: this.firmwareItem.manufacturer,
            name: this.firmwareItem.name,
            version: this.firmwareItem.version,
            status: this.firmwareItem.status,
            fileUrl: this.firmwareItem.fileUrl
          };
          this.getModelList();
          var fileUrl=this.ruleForm.fileUrl;
          this.fileList=[];
          if (fileUrl!=null&&fileUrl!=undefined&&fileUrl!='') {
            this.fileList=JSON.parse(fileUrl);
          } else {
            this.ruleForm.fileUrl="[]";
          }
        }
      }
    },
  },
  methods: {
    onInput() {
      this.$forceUpdate();
    },
    handleClose() {
      this.ruleForm={
        id: null,
        category: null,
        model: null,
        manufacturer: null,
        name: "",
        version: "",
        status: 10,
        fileUrl: "[]",
      };
      this.$emit("handleClose");
    },
    getCategoryList() {
      this.request.categoryList().then((res) => {
        if (res.code==0) {
          this.categoryList = res.data;
        }
      });
    },
    getModelList() {
      if (this.ruleForm.category!=null) {
        var params={
          category: this.ruleForm.category
        };
        this.request.modelList(params).then((res) => {
          if (res.code==0) {
            this.modelList = res.data;
          }
        });
      } else {
        this.modelList=[];
      }
    },
    getManufacturerList() {
      this.request.manufacturerList().then((res) => {
        if (res.code==0) {
          this.manufacturerList = res.data;
        }
      });
    },
    submit() {
      if (this.ruleForm.fileUrl=="[]") {
        this.$message.error("至少上传一个升级固件");
      } else {
        this.$refs["ruleForm"].validate((valid) => {
          if (valid) {
            if (this.editStatus==0) {
              this.request.addFirmwareDevice(this.ruleForm).then((res) => {
                if (res.code==0) {
                  this.$message.success("添加成功");
                  this.handleClose();
                }
              });
            } else if (this.editStatus==1) {
              this.request.editFirmwareDevice(this.ruleForm).then((res) => {
                if (res.code==0) {
                  this.$message.success("修改成功");
                  this.handleClose();
                }
              });
            }
          }
        });
      }
    },
    removeFile(e) {
      const i = this.fileList.findIndex((x) => x.name == e);
      this.fileList.splice(i, 1);
      var fileUrl=JSON.parse(this.ruleForm.fileUrl);
      fileUrl.splice(i, 1);
      this.ruleForm.fileUrl = JSON.stringify(fileUrl);
    },
    successFile(e) {
      this.fileList=e.fileList;
      var fileUrl=JSON.parse(this.ruleForm.fileUrl);
      var item={};
      item.url=e.url;
      item.name=e.name;
      fileUrl.push(item);
      this.ruleForm.fileUrl = JSON.stringify(fileUrl);
    },
  },
};
</script>
<style scoped>
</style>