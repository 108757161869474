export default {
  title: {
    text: "0",
    left: "29%",
    top: "center",
    textAlign: "center",
    textStyle: {
      fontSize: 16,
      color: "#000000" 
    }
  },
  tooltip: {
    trigger: "item",
  },
  legend: {
    type: "scroll",
    orient: "vertical",
    right: 10,
    top: 20,
    bottom: 20,
  },
  series: [
    {
      type: "pie",
      radius: ["40%", "55%"],
      center: ['30%', '50%'],
      left: "left",
      avoidLabelOverlap: false,
      minAngle: 15,
      maxAngle: 100,
      label: {
        show: false,
      },
      labelLine: {
        show: false,
      },
      data: [],
    },
  ],
};
