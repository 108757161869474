<template>
  <div class="baseInfo-page">
    <div class="baseInfo-body">
      <el-form :model="ruleForm" ref="ruleForm" :rules="isAdd != 3 ? rules : null" label-width="auto" class="demo-ruleForm">
        <form-title :required="isAdd == 3 ? false : true" style="font-weight:bold;">建筑类型</form-title>
        <div class="form-radio-box">
          <div class="form-radio">
            <el-radio-group v-model="buildRadioParent" size="small" :disabled="isAdd == 3" @input="handleRadioParent">
              <el-radio-button label="civilBuild">民用住宅</el-radio-button>
              <el-radio-button label="commercialBuild">商用建筑</el-radio-button>
              <el-radio-button label="publicBuild">公用建筑</el-radio-button>
            </el-radio-group>
          </div>
          <div class="form-radio-group">
            <el-form-item label-width="auto" prop="buildingType">
              <el-radio-group v-model="ruleForm.buildingType" :disabled="isAdd == 3">
                <el-radio :label="i.id" v-for="i in buildList" :key="i.id">{{ i.name }}</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
        </div>
        <form-title style="font-weight:bold;">项目信息</form-title>
        <div class="project-box">
          <el-form-item label="项目编号" style="width: 100%">
            <span>{{ ruleForm.projectCode }}</span>
          </el-form-item>
          <el-form-item label="项目状态" prop="status">
            <el-select v-model="ruleForm.status" style="width: 100%" :disabled="isAdd == 3" clearable>
              <el-option label="在建" :value="10" />
              <el-option label="试运行" :value="20" />
              <el-option label="已交付" :value="30" />
            </el-select>
          </el-form-item>
          <el-form-item label="项目名称" prop="projectName" style="width: 100%">
            <el-input
              v-model.trim="ruleForm.projectName"
              :disabled="isAdd == 3"
              clearable
              maxlength="64"
              placeholder="请输入"
            /> 
          </el-form-item>
          <el-form-item label="所属组织" prop="orgId">
            <el-cascader
              :options="orgList"
              :props="orgProps"
              v-model="ruleForm.orgId"
              style="width: 100%"
              :disabled="isAdd == 3"
              clearable
              placeholder="请选择"
            />
          </el-form-item>
          <el-form-item label="代运营方" prop="operator" style="width: 100%">
            <el-input
              v-model.trim="ruleForm.operator"
              :disabled="isAdd == 3"
              clearable
              maxlength="64"
              placeholder="请输入"
            />
          </el-form-item>
          <el-form-item label="客户姓名" prop="contactPerson" style="width: 100%">
            <el-input
              v-model.trim="ruleForm.contactPerson"
              :disabled="isAdd == 3"
              clearable
              maxlength="64"
              placeholder="请输入"
            />
          </el-form-item>
          <el-form-item label="联系电话" prop="contactPhone" style="width: 100%">
            <el-input
              v-model.trim.number="ruleForm.contactPhone"
              :disabled="isAdd == 3"
              clearable
              :controls="false"
              :maxlength="12"
              :max="11"
              placeholder="请输入"
            />
          </el-form-item>
          <div class="areaBox">
            <el-form-item label="所在地区" :required="isAdd == 3 ? false : true">
              <el-select
                v-model="ruleForm.country"
                style="width: 116px; margin-right: 20px"
                :disabled="isAdd == 3"
              >
                <el-option
                  v-for="(item, index) in countryList"
                  :key="index"
                  :label="item.name"
                  :value="item.name"
                />
              </el-select>
            </el-form-item>
            <el-form-item label-width="auto" prop="province">
              <el-select
                v-model="ruleForm.province"
                style="width: 116px; margin-right: 20px"
                @change="provinceChange"
                :disabled="isAdd == 3"
                filterable
                clearable
                placeholder="请选择省"
                @clear="clearProvince"
              >
                <el-option
                  v-for="(item, index) in provinceList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item label-width="auto" prop="city">
              <el-select
                v-model="ruleForm.city"
                style="width: 116px; margin-right: 20px"
                @change="cityChange"
                :disabled="isAdd == 3"
                filterable
                clearable
                placeholder="请选择市"
                @clear="clearCity"
              >
                <el-option
                  v-for="(item, index) in cityList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item label-width="auto" prop="area">
              <el-select
                v-model="ruleForm.area"
                style="width: 116px; margin-right: 20px"
                :disabled="isAdd == 3"
                filterable
                clearable
                placeholder="请选择区"
              >
                <el-option
                  v-for="(item, index) in areaList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item label-width="auto" style="width: 100%">
              <el-input
                v-model.trim="ruleForm.town"
                :disabled="isAdd == 3"
                clearable
                style="width: 100%"
                placeholder="请填写街道"
              />
            </el-form-item>
          </div>    
          <el-form-item label="详细地址" prop="address" style="width: 100%">
            <el-input
              v-model.trim="ruleForm.address"
              :disabled="isAdd == 3"
              clearable
              style="width: 100%"
              maxlength="64"
              placeholder="请输入"
            />
          </el-form-item>
          <el-form-item label="系统类型" prop="systemType">
            <el-select
              v-model="ruleForm.systemType"
              style="width: 100%"
              :disabled="isAdd == 3"
              filterable
              clearable
              placeholder="请选择">
              <el-option
                v-for="item in systemTypeList"
                :key="item.name"
                :label="item.name"
                :value="item.name"/> 
            </el-select>
          </el-form-item> 
          <el-form-item label="项目图片" prop="projectImage">
            <DiluUploadImg accept=".png,.jpg"  :limit="1" :fileList="photoList"  @remove="removePhoto" @success="successPhoto"/>
          </el-form-item>
        </div>
        <br/>
        <form-title style="font-weight:bold;">电价信息</form-title>
        <el-row>
          <el-col :span="8">
            <form-sub-title class="form-sub-title">
              <template #prefixIcon><i class="icon" ><img src="../images/gudian.png" alt=""/></i> </template>谷电
            </form-sub-title>
            <div class="electricity-box">
              <el-form-item label="电价(元)" prop="priceMin">
                <el-input-number
                  v-model="ruleForm.priceMin"
                  :disabled="isAdd == 3"
                  clearable
                  :controls="false"
                  :precision="2"
                  style="width: 262px"
                  :min="0"
                  placeholder="请填写"
                />
                <div v-if="isAdd != 3" class="price-btn add" @click="clickPrice('min', 'add')">
                  <i class="el-icon-plus"></i>
                </div>
              </el-form-item>
              <div class="priceEle-time">
                <div v-for="(item, index) in priceMinTimeList" :key="item.key">
                  <el-form-item label="时段">
                    <div class="timeBox">
                      <el-time-picker
                        is-range
                        :key="'priceMin_' + index"
                        v-model="elecPriceForm[item.key]"
                        range-separator="至"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                        placeholder="选择时间范围"
                        style="width: 262px"
                        :disabled="isAdd == 3"
                        format="HH:mm"
                        value-format="HH:mm"
                      >
                      </el-time-picker>
                      <div class="price-btn sub" v-if="isAdd != 3" @click="clickPrice('min', 'sub', index, item)">
                        <i class="el-icon-delete"></i>
                      </div>
                    </div>
                  </el-form-item>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <form-sub-title>
              <template #prefixIcon><i class="icon"><img src="../images/fengdian.png" alt=""/></i> </template>峰电
            </form-sub-title>
            <div class="electricity-box">
              <el-form-item label="电价(元)" prop="priceMax">
                <el-input-number
                  v-model="ruleForm.priceMax"
                  :disabled="isAdd == 3"
                  clearable
                  :controls="false"
                  :precision="2"
                  style="width: 262px"
                  :min="0"
                  placeholder="请填写"
                />
                <div v-if="isAdd != 3" class="price-btn add" @click="clickPrice('max', 'add')">
                  <i class="el-icon-plus"></i>
                </div>
              </el-form-item>
              <div class="priceEle-time">
                <div v-for="(item, index) in priceMaxTimeList" :key="item.key">
                  <el-form-item label="时段">
                    <div class="timeBox">
                      <el-time-picker
                        is-range
                        :key="'priceMax_' + index"
                        v-model="elecPriceForm[item.key]"
                        range-separator="至"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                        placeholder="选择时间范围"
                        style="width: 262px"
                        :disabled="isAdd == 3"
                        format="HH:mm"
                        value-format="HH:mm"
                      >
                      </el-time-picker>
                      <div class="price-btn sub" v-if="isAdd != 3" @click="clickPrice('max', 'sub', index, item)">
                        <i class="el-icon-delete"></i>
                      </div>
                    </div>
                  </el-form-item>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <form-sub-title>
              <template #prefixIcon><i class="icon"><img src="../images/pingdian.png" alt=""/></i> </template>平电电价
            </form-sub-title>
            <div class="electricity-box">
              <el-form-item label="电价(元)" prop="priceNormal">
                <el-input-number
                  v-model="ruleForm.priceNormal"
                  :disabled="isAdd == 3"
                  clearable
                  :controls="false"
                  :precision="2"
                  style="width: 262px"
                  :min="0"
                  placeholder="请填写"
                />
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="8">
            <form-sub-title>
              <template #prefixIcon> <i class="icon"><img src="../images/dianjia.png" alt=""/></i> </template>优惠电价
            </form-sub-title>
            <div class="electricity-box">
              <el-form-item label="电价(元)" prop="priceDiscount">
                <el-input-number
                  v-model="ruleForm.priceDiscount"
                  :disabled="isAdd == 3"
                  clearable
                  :controls="false"
                  :precision="2"
                  style="width: 262px"
                  :min="0"
                  placeholder="请填写"
                />
              </el-form-item>
            </div>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <span v-if="isAdd == '1' || isAdd == '2'" slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit">保存</el-button>
      <el-button type="text" @click="handleClose">重置</el-button>
    </span>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { DateToStr } from "@/utils/util/util";
import build from "../js/build";
import systemTypeList from "../js/systemType";
import FormTitle from "../components/FormTitle.vue";
import FormSubTitle from "../components/FormSubTitle.vue";
import DiluUploadImg from "@/components/public/dilu-uploadImg.vue";

export default {
  components: {
    FormTitle,
    FormSubTitle,
    DiluUploadImg,
  },
  data() {
    return {
      buildRadioParent: "civilBuild",
      ruleForm: {
        id: null, //自增ID
        buildingType: null, // 建筑类型
        projectCode: "", //项目编号
        status: 10, //项目状态 10：在建，20：试运行，30：已交付
        projectName: "", //项目名称
        orgId: "", // 所属组织
        operator: "", // 运营方
        contactPerson: "", //客户姓名
        contactPhone: "", //联系电话
        country: "中国", //国家
        province: "", //省份
        city: "", //城市
        area: "", //区县
        town: "", // 街道
        systemType: "", // 系统类型
        address: "", //详细地址
        priceNormal: 0.6, //正常电价
        priceMax: 1.0, //波峰电价
        priceMin: 0.4, //波谷电价
        priceDiscount: 0.4, //优惠电价
        projectImage: null,  //项目图片
        realChart: null, //设备参数
      },
      photoList: [],
      buildList: [], // 建筑列表
      province: "", //当前省份
      city: "", //当前城市
      provinceList: [], //省份列表
      cityList: [], //城市列表
      areaList: [], //区县列表
      streetList: [], // 街道列表
      countryList: [{ name: "中国" }],
      systemTypeList, // 系统类型
      isAdd: 1, // 1新增 2编辑 3查看
      currentData: {}, // 当前行数据
      orgList: [], // 所属组织数据列表
      orgProps: {
        children: "orgList",
        label: "name",
        value: "id",
        checkStrictly: true,
      },
      // 表单校验规则
      rules: {
        projectImage: [
          { required: false, message: "请上传项目图片", trigger: "change" },
        ],
        buildingType: [
          { required: true, message: "请选择建筑类型", trigger: "change" },
        ],
        status: [
          { required: true, message: "请选择项目状态", trigger: "change" },
        ],
        projectName: [
          { required: true, message: "请输入项目名称", trigger: "blur" },
        ],
        orgId: [
          { required: true, message: "请选择所属组织", trigger: "change" },
        ],
        operator: [
          { required: false, message: "请输入运营方", trigger: "blur" },
        ],
        contactPerson: [
          { required: false, message: "请输入联系人", trigger: "blur" },
        ],
        contactPhone: [
          { required: false, message: "请输入联系电话", trigger: "blur" },
        ],
        province: [{ required: true, message: "请选择省", trigger: "change" }],
        city: [{ required: true, message: "请选择市", trigger: "change" }],
        area: [{ required: true, message: "请选择地区", trigger: "change" }],
        systemType: [
          { required: false, message: "请选择系统类型", trigger: "change" },
        ],
        address: [
          { required: false, message: "请输入详细地址", trigger: "blur" },
        ],
        priceNormal: [
          { required: true, message: "请输入电价", trigger: "blur" },
        ],
      },
      priceMinTimeList: [], // 谷电 时间列表
      priceMaxTimeList: [], // 峰电 时间列表
      elecPriceForm: {}, // 存放峰谷电时间列表数据
      priceObj: {
        end: "",
        project: "",
        start: "",
        stype: "",
        id: "",
        key: "",
      },
      validTimeArr: [], // 需要校验时间段数组 [{ startTime: "", endTime: ""}]
    };
  },
  computed: {
    ...mapState(["platformData"]),
    ...mapState(["projectCurrentRowData"]),
  },
  mounted() {
    const { isAdd } = this.$route.query;
    this.isAdd = isAdd;
    this.currentData = this.projectCurrentRowData;
    this.getProvince();
    this.showInit();
    this.listOrg();
    this.setBuildList();
  },
  methods: {
    removePhoto(e) {
      const i = this.photoList.findIndex((x) => x.name == e);
      this.photoList.splice(i, 1);
      this.ruleForm.projectImage = null;
    },
    successPhoto(e) {
      this.ruleForm.projectImage = e.url;
      this.photoList = e.fileList;
    },
    // PN.<租户ID>-YYMMDDHHmmss
    getProjectCode() {
      const tenantId = this.platformData.id;
      const dateTime = DateToStr(new Date()).split(" ");
      const year = dateTime[0].split("-").join("");
      const time = dateTime[1].split(":").join("");
      this.ruleForm.projectCode = `PN.${tenantId}-${year.slice(2)}${time}`;
    },
    //部门管理-查询所有部门
    listOrg() {
      this.request.listOrg().then((res) => {
        if (res.code == 0) {
          this.orgList = [res.data];
        }
      });
    },
    createProject() {
      this.ruleForm = {
        id: null, //自增ID
        buildingType: null, // 建筑类型
        projectCode: "", //项目编号
        status: 10, //项目状态（10：正常使用，20：在建，30：交付）
        projectName: "", //项目名称
        orgId: "", // 所属组织
        operator: "", // 运营方
        contactPerson: "", //客户姓名
        contactPhone: "", //联系电话
        country: "中国", //国家
        province: "", //省份
        city: "", //城市
        area: "", //区县
        town: "", // 街道
        systemType: "", // 系统类型
        address: "", //详细地址
        priceNormal: 0.6, //正常电价
        priceMax: 1.0, //波峰电价
        priceMin: 0.4, //波谷电价
        priceDiscount: 0.4, //优惠电价
        realChart: null, //设备参数
      };
      // 设置项目编号
      this.getProjectCode();
      // 设置建筑类型
      this.buildRadioParent = "civilBuild";
      this.setBuildList();
      this.resetPriceList();
    },
    editProject() {
      const currentData = this.currentData;
      // 建筑类型
      const [buildTypeParent, buildingType] = currentData.buildingType || [];
      this.buildRadioParent = buildTypeParent
        ? build.buildEnum[buildTypeParent]
        : "civilBuild";
      // this.setBuildList();
      // 电价换算
      const priceMax = currentData.priceMax;
      const priceMin = currentData.priceMin;
      const priceNormal = currentData.priceNormal;
      const priceDiscount = currentData.priceDiscount;
      // 省市区
      const { province, city, area } = currentData;
      const provinceId = province.split(",")[0]*1;
      const cityId = city.split(",")[0]*1;
      const areaId = area.split(",")[0]*1;
      Object.assign(this.ruleForm, {
        ...currentData,
        buildingType,
        priceMax: priceMax / 100,
        priceMin: priceMin / 100,
        priceNormal: priceNormal / 100,
        priceDiscount: priceDiscount / 100,
        province: provinceId,
        city: cityId,
        area: areaId
      });
      this.photoList = [];
      this.photoList.push({
        url: this.ruleForm.projectImage,
        name: "projectImage"
      });
      this.provinceChange(provinceId);
      this.cityChange(cityId);
     
    },
    //初始显示
    showInit() {
      if (this.isAdd == 1) {
        this.createProject();
      } else if (this.isAdd == 2 || this.isAdd == 3) {
        this.editProject();
        this.electricityList();
      }
    },
    // 查询电价信息列表
    async electricityList() {
      // 谷电
      const res1 = await this.request.electricityList({
        project: this.currentData.projectCode,
        stype: 20,
      });
      this.initPriceList(res1.data || [], "priceMinTimeList", "priceMin_", 20);
      // 峰电
      const res2 = await this.request.electricityList({
        project: this.currentData.projectCode,
        stype: 10,
      });
      this.initPriceList(res2.data || [], "priceMaxTimeList", "priceMax_", 10);
      this.initPriceForm(this.priceMinTimeList, "priceMin_");
      this.initPriceForm(this.priceMaxTimeList, "priceMax_");
    },
    initPriceList(resArr, arrName, keyName, stype) {
      const resData = resArr.map((item) => {
        return { ...item, key: keyName + '_' + item.id };
      });
      const len = resData.length;
      this[arrName] = len > 0 ? resData : [];
    },
    initPriceForm(arr) {
      for (let i = 0; i < arr.length; i++) {
        const item = arr[i];
        const start = item.start || "00:00";
        const end = item.end || "00:30";
        this.$set(this.elecPriceForm, item.key, [start, end]);
      }
    },
    getProvince() {
      this.request.getProvince().then((res) => {
        this.provinceList = res.data;
      });
    },
    getCity() {
      let params = {};
      params["province"] = this.ruleForm.province;
      this.request.getCity(params).then((res) => {
        this.cityList = res.data;
      });
    },
    getArea() {
      let params = {};
      params["city"] = this.ruleForm.city;
      this.request.getArea(params).then((res) => {
        this.areaList = res.data;
      });

    },
    handleClose() {
      this.$refs["ruleForm"].resetFields();
      this.createProject();
    },
    provinceChange(value) {
      for (let item of this.provinceList) {
        if (item["name"] == value) {
          this.province = item["id"];
        }
      }
      this.getCity();
    },
    cityChange(value) {
      for (let item of this.cityList) {
        if (item["name"] == value) {
          this.city = item["id"];
        }
      }
      this.getArea();
    },
    clearProvince() {
      this.ruleForm.city = "";
      this.ruleForm.area = "";
    },
    clearCity() {
      this.ruleForm.area = "";
    },
    //确定
    submit() {
      const cloneRuleForm = JSON.parse(JSON.stringify(this.ruleForm));
      const validTime = this.validateTimePrice();
      if (!validTime) return;
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          // 建筑类型
          const buildingType = cloneRuleForm.buildingType;
          // 电价处理成分
          const priceMax = cloneRuleForm.priceMax * 100;
          const priceMin = cloneRuleForm.priceMin * 100;
          const priceNormal = cloneRuleForm.priceNormal * 100;
          const priceDiscount = cloneRuleForm.priceDiscount * 100;
          // 省市区的处理
          const { province, city, area } = cloneRuleForm;
          const findProvince = this.provinceList.find(i => i.id === province);
          const provinceName = findProvince ? findProvince.name : "";
          const findCity = this.cityList.find(i => i.id === city);
          const cityName = findCity ? findCity.name : "";
          const findArea = this.areaList.find(i => i.id === area);
          const areaName = findArea ? findArea.name : "";
          const postData = Object.assign(cloneRuleForm, {
            priceMax,
            priceMin,
            priceNormal,
            priceDiscount,
            buildingType: [build[this.buildRadioParent].id, buildingType],
            province: province + "," + provinceName,
            city: city + "," + cityName,
            area: area + "," + areaName
          });
          let api = null,
            message = "";
          if (this.isAdd == 1) {
            api = this.request.projectAdd;
            message = "新增成功";
          } else {
            api = this.request.projectEdit;
            message = "编辑成功";
          }
          this.submitData(api, postData, message);
        }
      });
    },
    submitData(api, postData, message) {
      api(postData).then((res) => {
        if (res.code == 0) {
          this.submitElePrice();
          this.$message.success(message);
          setTimeout(() => {
            this.$emit("projectList");
            this.$router.go(-1);
          }, 500);
        }
      });
    },
    validateTimePrice() {
      let validArr = [];
      Object.values(this.elecPriceForm).forEach((i) => {
        validArr.push({
          startTime: i[0],
          endTime: i[1],
        });
      });
      
      this.validTimeArr = validArr;
      const ishasCopy = this.checkTime(validArr);
      
      if (!ishasCopy) {
        this.$message({
          message: "请检查峰谷电价时段是否有重叠！",
          type: "warning",
        });
        return false;
      }
      return true
    },
    checkTime(dateAr) {
      for (let k in dateAr) {
        if (!this.judege(k)) {
          return false;
        }
      }
      return true;
    },
    judege(idx) {
      let dateAr = this.validTimeArr;
      for (let k in dateAr) {
        if (idx !== k) {
          if (
            dateAr[k].startTime <= dateAr[idx].startTime &&
            dateAr[k].endTime >= dateAr[idx].endTime
          ) {
            return false;
          }
          if (
            dateAr[k].startTime <= dateAr[idx].startTime &&
            dateAr[k].endTime >= dateAr[idx].endTime
          ) {
            return false;
          }
        }
      }
      return true;
    },
    submitElePrice() {
      let result = [];
      const arr1 = this.priceMinTimeList;
      const arr2 = this.priceMaxTimeList;
      for (let i = 0; i < arr1.length; i++) {
        const item = arr1[i];
        const [start, end] = this.elecPriceForm[item.key];
        result.push({
          start,
          end,
          project: item.project || this.ruleForm.projectCode,
          stype: 20,
          id: item.id,
        });
      }
      for (let i = 0; i < arr2.length; i++) {
        const item = arr2[i];
        const [start, end] = this.elecPriceForm[item.key];
        result.push({
          start,
          end,
          project: item.project || this.ruleForm.projectCode,
          stype: 10,
          id: item.id,
        });
      }
      for (let i = 0; i < result.length; i++) {
        this.request.electricityEdit(result[i]);
      }
    },
    // 切换建筑类型 设置具体建筑
    setBuildList() {
      this.buildList = build[this.buildRadioParent].typeList;
    },
    handleRadioParent() {
      this.ruleForm.buildingType = null;
      this.setBuildList();
    },
    // 加减峰谷电时间
    clickPrice(ele, type, index, data) {
      let key = "";
      if (ele === "min") key = "priceMin";
      if (ele === "max") key = "priceMax";
      if (type === "add") {
        this.addPrice(key);
      }
      if (type === "sub") {
        this.subPrice(key, index, data);
      }
    },
    addPrice(key) {
      const list = this[key + "TimeList"];
      const keyValue = key + "_" + new Date().getTime();
      list.push({
        end: "00:30",
        project: "",
        start: "00:00",
        stype: key == "priceMin" ? 20 : 10,
        id: "",
        key: keyValue,
      });
      // 新增时段 并初始化时间
      this.$set(this.elecPriceForm, keyValue, ["00:00", "00:30"]);
    },
    fmtPriceTime(date) {
      const nDate = new Date(date);
      let hour = nDate.getHours();
      let m = nDate.getMinutes();
      hour = hour > 10 ? hour : "0" + hour;
      m = m > 10 ? m : "0" + m;
      return hour + ":" + m;
    },
    // 新建项目，初始化一条峰谷电价 时段列表 - 数据对象
    resetPriceList() {
      this.priceMinTimeList = [];
      this.priceMaxTimeList = [];

      for (let i = 0; i < this.priceMinTimeList.length; i++) {
        this.$set(this.elecPriceForm, "priceMin_" + new Date().getTime(), [
          "00:00",
          "00:30",
        ]);
      }
      for (let i = 0; i < this.priceMaxTimeList.length; i++) {
        this.$set(this.elecPriceForm, "priceMax_" + new Date().getTime(), [
          "00:00",
          "00:30",
        ]);
      }
    },
    subPrice(key, index, data) {
      this.$confirm("此操作将删除数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
        closeOnClickModal: false,
        closeOnPressEscape: false,
      }).then(() => {
        if (data.id) {
          this.delElePrice(data, index, this[key + "TimeList"]);
        } else {
          this[key + "TimeList"].splice(index, 1);
          this.$delete(this.elecPriceForm, data.key);
        }
      })
      .catch(() => {});
    },
    delElePrice(i, index, arr) {
      this.request.electricityDel({ id: i.id }).then((res) => {
        if (res.code == 0) {
          arr.splice(index, 1);
          this.$delete(this.elecPriceForm, i.key);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">

.baseInfo-page {
  overflow: hidden;
}
.baseInfo-body {
  box-sizing: border-box;
  background-color: white;
  padding-right: 24px;
  height: calc(100% - 132px);
  width: 100%;
  overflow: auto;

  .form-radio-box {
    margin: 16px 0 30px;
    .form-radio {
      margin-bottom: 24px;
    }
  }

  .icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    vertical-align: middle;
    margin-right: 8px;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .project-box {
    margin: 20px 0;
    .areaBox {
      display: flex;
      justify-content: flex-start;
    }
  }

  .form-sub-title {
    margin: 20px 0;
  }

  .electricity-box {
    ::v-deep(.el-form-item__content) {
      display: flex;
      align-items: center;
    }
    .price-btn {
      display: inline-block;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: #f1f1f1;
      opacity: 1;
      line-height: 20px;
      text-align: center;
      i {
        font-size: 12px;
        font-weight: bold;
      }
      cursor: pointer;
      margin-left: 8px;
      &.sub {
        color: #f02929;
      }
      &.add {
        background: #3456ff;
        color: #fff;
      }
    }
  }

  .priceEle-time {
    width: 100%;
    .timeBox {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
    }
  }
}

.dialog-footer {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 560px;
  text-align: left;
  display: block;
  > :nth-child(1) {
    width: 300px;
    height: 32px;
    line-height: 32px;
    border-radius: 2px;
    opacity: 1;
    padding: 0;
    background: #3456ff;
  }
  > :nth-child(2) {
    font-family: "microsoft yahei ui","microsoft yahei";
    font-size: 14px;
    color: #666666;
    margin-left: 56px;
  }
}
</style>
