<template>
  <div class="table">
    <div class="dilu-table">
      <el-table
        :data="list"
        v-loading="loading"
        class="custom-table"
        id="dilu-table"
        :height="height"
        :header-cell-style="{ 
          background:'#F0FFFF',
          color:'rgba(0, 0, 0, 0.9)', 
          fontSize: '14px', 
          fontWeight: 'bold'
        }"
        :row-style="{ height: '36px' }" 
        :cell-style="columnStyle"
        :row-key="tableConfig.isTree ? tableConfig.isTreeText : ''"
        @selection-change="handleSelectionChange"
      >
        >
        <template slot="empty">
          <el-empty description="暂无数据" :image-size="100"></el-empty>
        </template>
        <slot></slot>
      </el-table>
    </div>

    <!-- 分页 -->
    <div class="pagination" v-if="tableConfig.isPage">
      <el-pagination
        background
        layout="prev, pager, next, total"
        :total="total"
        :page-size="pageSize"
        @current-change="currentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: ["list", "tableConfig", "total", "pageSize", "height", "loading"],
  mounted() {
    let user = JSON.parse(localStorage.getItem("userInfo"));
    this.$watermark.set(user.account, 0, -30);
  },
  methods: {
    columnStyle({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 0) {
        return 'padding:0;'
      } else {
        return 'padding:0;'
      }
    },
    handleSelectionChange(e) {
      this.$emit("handleSelectionChange", e);
    },
    //翻页触发
    currentChange(e) {
      this.$emit("currentChange", e);
    },
    //类名
    tableRowClassName({ row, rowIndex }) {
      if (this.tableConfig.stripe) {
        if (row.nodeType == 0) {
          return "selectIndex";
        }
        return "";
      }
      if (rowIndex % 2 === 0) {
        return "warning-row";
      }

      return "";
    },
  },
};
</script>

<style scoped>
.table {
  flex:none;
  position: relative;
  display: flex;
  flex-direction: column;
}
.pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
.dilu-table {
  flex: 1;
  overflow: auto;
}
.custom-talbe {
  width:100%;
}
::v-deep .el-table .el-table__row {
  height: 44px;
}
::v-deep .el-table .cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>