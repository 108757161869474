<template>
  <div class="v-card">
    <v-arrow-bg>
      <template #header>关键参数</template>
      <div class="v-card-container">
        <div
          class="count"
          v-for="i in controlList"
          :key="'control_' + i.id"
          @click="clickControl(i)"
        >
          <p class="label">{{ i.label }}</p>
          <p>
            <span class="value">{{ i.value }}</span
            ><span class="unit">{{ i.unit }}</span>
          </p>
        </div>
      </div>
    </v-arrow-bg>
  </div>
</template>

<script lang="js">
import VArrowBg from "../v-arrow-bg.vue";

export default {
  name: 'VrightCard2',
  components: {
    VArrowBg,
  },
  props: {
    propsData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  watch: {
    propsData: {
      deep: true,
      handler() {
        this.screenData = Object.assign({}, this.propsData);
      }
    }
  },
  data() {
    return {
      screenData: {},
      controlList: [
        {
          id: 0,
          label: "回水温度",
          value: 23,
          unit: " ℃"
        },
        {
          id: 1,
          label: "出水温度",
          value: 23,
          unit: " ℃"
        },
        {
          id: 2,

          label: "压机温度",
          value: 23,
          unit: " ℃"
        },
        {
          id: 3,
          label: "室外X点",
          value: 23,
          unit: " ℃"
        },
        {
          id: 4,
          label: "风机转速",
          value: 23,
          unit: " ℃"
        },
        {
          id: 5,
          label: "室内X点",
          value: 23,
          unit: " ℃"
        },
        {
          id: 6,
          label: "运行模式",
          value: 23,
          unit: " ℃"
        },
        {
          id: 7,
          label: "新风XXX",
          value: 23,
          unit: " ℃"
        },
        {
          id: 8,
          label: "XXX系数",
          value: 23,
          unit: " ℃"
        },
        {
          id: 9,
          label: "光伏功率",
          value: 23,
          unit: " ℃"
        },
        {
          id: 10,
          label: "储能电量",
          value: 23,
          unit: " ℃"
        },
        {
          id: 11,
          label: "剩余电量",
          value: 23,
          unit: " ℃"
        }
      ]
    }
  },
  methods: {}
}
</script>
<style scoped lang="scss">
.v-card {
  width: 321.33px;
  height: 248px;
  border-radius: 2px;
  overflow: hidden;

  .v-card-container {
    width: 100%;
    height: calc(100% - 40px);
    padding-top: 8px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    overflow-y: auto;

    .count {
      width: 94px;
      height: 54px;
      background: url("../../images/rect-bg.jpg") no-repeat;
      background-size: 100% 100%;
      padding: 8px;
      box-sizing: border-box;
      margin-bottom: 8px;
      .label {
        font-family: "microsoft yahei ui","microsoft yahei";
        font-size: 12px;
        color: #ffffff;
      }

      .value {
        font-family: "microsoft yahei ui","microsoft yahei";
        font-size: 18px;
        font-weight: 500;
        color: #2dfaf3;
      }

      .unit {
        font-family: "microsoft yahei ui","microsoft yahei";
        font-size: 12px;
        color: #848484;
      }
      p {
        line-height: 24px;
      }
      p:nth-child(2) {
        text-align: right;
      }
    }
  }
}
</style>
