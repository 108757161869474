<template>
  <el-empty description="暂无数据" image="">
  </el-empty>
</template>

<script>
export default {
  name: "MrEmpty"
}
</script>

<style scoped lang="scss">
.el-empty {
  margin: 0 auto;
  ::v-deep(.el-empty__image) {
    display: none;
  }
  ::v-deep(.el-empty__description p) {
    font-size: 16px;
  }
}
</style>