<template>
  <div class="scene-detail">
    <div class="exp-title">已有场景</div>
    <div class="container">
      <table border="none" :cellspacing="8" :cellpadding="0" width="100%" align="center">
        <tbody style="counter-reset: none; border: none">
          <tr v-for="(item, index) in list" :key="index">
            <td class="bg-td head">{{ item.name }}</td>
            <td class="bg-td td50">
              <tr v-for="(condition, index) in item.conditions" :key="index" class="tr-data">
                <td class="blankDiv">
                  <span v-if="index >= 1">{{item.condition.length > 1 ? "或" : ""}}</span>
                </td>
                <td class="value">{{ formatterDevice(condition.deviceId) }}</td>
                <td class="value">{{ condition.name }}</td>
                <td class="value">{{ formatterCompare(condition.compare) }}</td>
                <td class="value">{{ condition.value }}</td>
              </tr>
            </td>
            <td class="bg-td td33">
              <tr v-for="(action, index) in item.actions" :key="index" class="tr-data">
                <td class="value longvalue">{{ formatterDevice(action.deviceId) }}</td>
                <td class="value">{{ action.name }}</td>
                <td class="value">{{ action.value }}</td>
              </tr>
            </td>
            <td style="width: 68px; padding: 0; display: flex; justify-content: space-around; align-items: center;">
              <div class="opt-btn edit" @click="editItem(item)">
                <i class="el-icon-edit"></i>
              </div>
              <div class="opt-btn delete" @click="deleteItem(item)">
                <i class="el-icon-delete"></i>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import compareList from "../js/compare.js";
export default {
  name: "SceneDetail",
  data() {
    return {
      list: [],
      compareList,
      deviceList: [],
    };
  },
  computed: {
    ...mapState(["projectCurrentRowData"]),
  },
  mounted() {
    this.getDeviceList();
    this.getData();
  },
  methods: {
    // 设备列表
    getDeviceList() {
      this.request.deviceList({ project: this.projectCurrentRowData.projectCode }).then((res) => {
        this.deviceList = res.data || [];
      });
    },
    getDeviceParams(item) {
      this.request.deviceScreenInfo({
        deviceId: item.deviceId,
        project: this.projectCurrentRowData.projectCode,
      })
      .then((res) => {
        const deviceParamsList = (res.data || {}).dictParams || [];
        const findItem = deviceParamsList.find(device => device.id == item.name);
        if (findItem) {
          item["nameLabel"] = findItem.name;
        }
      });
    },
    editItem(item) {
      this.$emit("edit", item);
    },
    deleteItem(item) {
      this.$confirm("此操作将删除场景：【" + item.name + "】, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
      .then(() => {
        this.deleteFun(item)
      })
      .catch(() => {});
    },
    deleteFun(item) {
      this.request.deleteRule({ id: item.id }).then((res) => {
        if (res.code == 0) {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.getData();
        }
      });
    },
    getData() {
      this.request.listRule({
        project: this.projectCurrentRowData.projectCode,
      })
      .then((res) => {
        const resData = res.data || [];
        resData.forEach(item => {
          const conditions = item.conditions || [];
          conditions.forEach(el => {
            this.$set(el, "nameLabel", this.formatterAttrCondition(el))
          })
          const actions = item.actions || [];
          actions.forEach(el => {
            this.$set(el, "nameLabel", this.formatterAttrCondition(el))
          })
        })
        this.list = resData;
      });
    },
    formatterCompare(id) {
      const findCompare = this.compareList.find((item) => item.value == id);
      if (findCompare) {
        return findCompare.label;
      }
      return "";
    },
    formatterDevice(id) {
      const device = this.deviceList.find((item) => item.id == id);
      if (device) {
        return device.categoryName;
      }
      return id;
    },
    formatterAttrCondition(item) {
      this.getDeviceParams(item);
    }
  },
};
</script>

<style scoped lang="scss">
.scene-detail {
  .exp-title {
    font-family: "microsoft yahei ui","microsoft yahei";
    font-size: 16px;
    color: #424e66;
    margin-bottom: 18px;
    margin-top: 18px;
  }

  .box-label {
    font-family: "microsoft yahei ui","microsoft yahei";
    font-size: 12px;
    color: #666666;
    margin-bottom: 8px;
  }

  .el-row {
    height: 100%;
    margin-bottom: 8px;
    background: #f5f5f7;
  }

  .el-col {
    height: 100%;
    position: relative;
  }

  .line {
    width: 4px;
    height: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    box-sizing: border-box;
    background-color: red;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  .box-scroll {
    // height: 72px;
    // overflow-y: auto;
    padding-left: 12px;
    padding-right: 12px;
    box-sizing: border-box;
    background: #f5f5f7;
  }

  .box-label-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .group-title {
      color: #333333;
      line-height: 72px;
      font-size: 14px;
    }
  }

  .blankDiv {
    width: 25px;
    color: #999999;
    font-size: 12px;
    text-align: center;
  }

  .label {
    width: 33%;
    font-family: "microsoft yahei ui","microsoft yahei";
    font-size: 12px;
    color: #999999;
    margin-bottom: 12px;
    text-align: center;
  }
  .value {
    width: 33%;
    font-family: "microsoft yahei ui","microsoft yahei";
    font-size: 12px;
    color: #333333;
    text-align: center;
    line-height: 28px;
  }
  .longlabel {
    width: 34%;
    text-align: left;
  }
  .longvalue {
    width: 34%;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .opt-btn {
    line-height: 72px;
    margin-right: 8px;
    cursor: pointer;
    &.edit:hover i {
      color: #3456ff;
    }
    &.delete:hover i {
      color: #ff0000;
    }
  }

  table {
    width: 100%;
  }

  td {
    padding-left: 12px;
    padding-right: 12px;
  }

  .head {
    width: 16%;
    text-align: center;
  }

  .td50 {
    width: 51%;
  }

  .td33 {
    width: 27.33%;
  }

  .tr-data {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .bg-td {
    background: #f5f5f7;
  }
}
</style>
