<template>
  <ScaleBox>
    <div class="mr-visual">

      <div class="mr-visual-container" v-show="tabVal == '2D'">
        <v-2dpage v-show="tabVal == '2D'"
          :zongkaiguanMode="zongkaiguanMode"
          :gcMode="gcMode"
          :kongtiaoMode="kongtiaoMode"
          :yunxingMode="yunxingMode"></v-2dpage>
      </div>

      <div class="mr-visual-header">
        <v-header @changeProject="changeProject"></v-header>
      </div>
      <div class="mr-visual-left">
        <mr-video style="width: 100%; height: 240px;"></mr-video>
        <!-- <v-left-card1 :propsData="screenData"></v-left-card1> -->
        <!-- <v-left-card2 @control="leftCard2Control" /> -->
        <v-left-card3 @control="leftCard3Control" />
        <v-left-card4 @control="leftCard4Control" />
        <v-left-card5 @control="leftCard5Control" />
      </div>

    </div>
  </ScaleBox>
</template>

<script lang="js">
import VHeader from "./components/v-header-yanshi.vue";
import VCard2 from "./components/v-card2.vue";
import VCard4 from "./components/v-card4.vue";
import VCard5 from "./components/v-card5.vue";
import VCard6 from "./components/v-card6.vue";
import VCard7 from "./components/v-card7.vue";
import VOptDialog from "./components/v-opt-dialog.vue";
import VGltfmod from "./components/v-gltfmod.vue";
import V2dpage from "./components/v-2dpage.vue";


import VCompanyInfo from "./components/v-company-info.vue";
import ScaleBox from "@/components/view/scaleBox.vue";
import { mapState } from "vuex";

import VLeftCard1 from "./components/left/v-left-card1.vue";
import VLeftCard2 from "./components/left/v-left-card2.vue";
import VLeftCard3 from "./components/left/v-left-card3.vue";
import VLeftCard4 from "./components/left/v-left-card4.vue";
import VLeftCard5 from "./components/left/v-left-card5.vue";

import MrVideo from "./video";

export default {
  name: 'visual',
  components: {
    VHeader,
    VLeftCard1,
    VCard2,
    VCard4,
    VCard5,
    VCard6,
    VCard7,
    V2dpage,

    VOptDialog,
    VCompanyInfo,
    ScaleBox,
    VLeftCard2,
    VLeftCard3,
    VLeftCard4,
    VLeftCard5,
    MrVideo
  },
  data() {
    return {
      modopacity: 0,
      tabVal: '2D',
      scene: null,
      camera: null,
      renderer: null,
      cube: null,
      controls: null,
      app: null,
      dialogFlag: false,
      infos: null,
      tranLeft: 0, // 向左偏移
      tranTop: 0, // 向上偏移
      infoBox: false,
      gui: null,
      screenData: {}, // 大屏信息
      placeData: {}, // 建筑物列表
      projectCode: "", // 项目编号
      zongkaiguanMode: "", // 总开关
      kongtiaoMode: "", // 空调模式
      yunxingMode: "", // 运行模式
      gcMode: "", // 光储模式
    }
  },
  created() {
   
  },
  computed: {
    ...mapState(["projectCurrentRowData"])
  },
  mounted() {
    const currentData = this.projectCurrentRowData || {};
    this.projectCode = currentData.projectCode;
    this.initData();
  },
  methods: {

    // 切换项目
    changeProject(e) {
      this.projectCode = e.projectCode;
      this.initData();
    },
    // 切换楼层
    changeFloor(e) {

      console.log("切换楼层:", e)
    },

    touchstart(e) {
      console.log('touchstart', e)
    },
    initData() {
      const projectCode = this.projectCode;
      this.request.dataScreenInfo({ project: projectCode }).then((res) => {
        if (res.code == 0) {
          this.screenData = Object.assign({}, res.data);
        }
      });
      this.request.placeList({ project: projectCode }).then((res) => {
        if (res.code == 0) {
          this.placeData = Object.assign({}, res.data);
        }
      });
    },
    leftCard2Control(e) {
      console.log("总模式切换----", e);
      this.zongkaiguanMode = e.mode;
    },
    leftCard3Control(e) {
      console.log("空调模式切换----", e)
      this.kongtiaoMode = e.mode;
    },
    leftCard4Control(e) {
      console.log("运行模式切换----", e)
      this.yunxingMode = e.mode;
    },
    leftCard5Control(e) {
      console.log("光储模式切换----", e)
      this.gcMode = e.mode;
    },
  }
}
</script>
<style scoped lang="scss">
.mr-visual {
  height: 100%;
  background: url("~@/assets/images/visual/visual_bg.jpg") no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
  position: relative;



  .mr-visual-header {
    height: 64px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 24px;
    box-sizing: border-box;
  }

  .mr-visual-left,
  .mr-visual-right,
  .mr-visual-container {
    position: absolute;
    height: 100%;
    opacity: 1;
    box-sizing: border-box;

    .scroll {
      height: 100%;

      >div {
        margin-bottom: 8px;
      }
    }
  }

  .mr-visual-container {
    width: 1344px;
    left: 20%;
    height: 100%;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .mr-visual-right {
    top: 64px;
    right: 0;
    width: 321.33px;
    box-sizing: border-box;
  }

  .mr-visual-left {
    top: 64px;
    left: 0;
    width: 355px;
    box-sizing: border-box;
    >div {
      margin-bottom: 22px;
    }
  }

  .mr-opt-dialog {
    position: absolute;
    left: -78px;
    top: 25%;
  }

  .mr-visual-footer {
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    text-align: center;
  }

  .popinfos {
    /* 矩形 411 */

    position: absolute;

    width: 264.12px;
    height: 144.56px;
    opacity: 1;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    box-shadow: inset 0px 4px 32px 0px rgba(0, 34, 255, 0.45);
    z-index: 1;
    padding: 20px 15px;

    P {
      width: 210px;
      font-family: "microsoft yahei ui","microsoft yahei";
      font-size: 14px;
      color: rgba(255, 255, 255, 0.8);
      text-align: left;
      padding-left: 8px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      line-height: 26px;

      font-weight: 600;
    }
  }
}
</style>
