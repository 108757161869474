<template>
  <div class="mr-device-routeView">
    <ScaleBox>
    <router-view />
  </ScaleBox>
  </div>
</template>


<script>

import ScaleBox from "@/components/view/scaleBox.vue";
export default {
  name: "VDevice",
  components: {
    ScaleBox
  },
  data() {
    return {}
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
.mr-device-routeView {
  height: 100%;
}
</style>