<template>
  <div class="mr-region">
    <el-select
      v-model="province"
      :disabled="disabled"
      filterable
      clearable
      placeholder="请选择省"
      @clear="clearProvince"
      @change="provinceChange"
      style="width: 120px;"
    >
      <el-option
        v-for="(item, index) in provinceList"
        :key="index"
        :label="item.name"
        :value="item.id"
      />
    </el-select>
    &nbsp;&nbsp;
    <el-select
      v-model="city"
      :disabled="disabled"
      filterable
      clearable
      placeholder="请选择市"
      @clear="clearCity"
      @change="cityChange"
      style="width: 120px;"
    >
      <el-option
        v-for="(item, index) in cityList"
        :key="index"
        :label="item.name"
        :value="item.id"
      />
    </el-select>
    &nbsp;&nbsp;
    <el-select
      v-model="area"
      :disabled="disabled"
      filterable
      clearable
      placeholder="请选择区"
      style="width: 120px;"
    >
      <el-option
        v-for="(item, index) in areaList"
        :key="index"
        :label="item.name"
        :value="item.id"
      />
    </el-select>
  </div>
</template>

<script>
export default {
  name: "MrRegion",
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      province: "",
      city: "",
      area: "",
      provinceList: [],
      cityList: [],
      areaList: []
    }
  },
  mounted() {
    this.getProvince();
  },
  methods: {
    // 省
    getProvince() {
      this.request.getProvince().then((res) => {
        this.provinceList = res.data;
      });
    },
    // 市
    getCity() {
      this.request.getCity({ province: this.province }).then((res) => {
        this.cityList = res.data;
      });
    },
    // 区
    getArea() {
      this.request.getArea({ city: this.city }).then((res) => {
        this.areaList = res.data;
      });
    },
    provinceChange() {
     this.getCity();
    },
    cityChange() {
      this.getArea();
    },
    clearProvince() {
      this.city = "";
      this.area = "";
    },
    clearCity() {
      this.area = "";
    },
    emitData(changeValue) {
      this.$emit("changeRegion", changeValue)
    },
    reset() {
      this.province = "";
      this.city = "";
      this.area = "";
    }
  },
  watch: {
    province: {
      handler() {
        this.emitData({
          province: this.province,
          city: this.city,
          area: this.area
        })
      }
    },
    city: {
      handler() {
        this.emitData({
          province: this.province,
          city: this.city,
          area: this.area
        })
      }
    },
    area: {
      handler() {
        this.emitData({
          province: this.province,
          city: this.city,
          area: this.area
        })
      }
    }
  }
};
</script>

<style></style>
