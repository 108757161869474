export default {
  tooltip: {
    trigger: 'item'
  },
  legend: {
    show: false
  },
  series: [
    {
      name: '故障类别',
      type: 'pie',
      radius: ['40%', '50%'],
      data: [],
      label: {
        color: "rgba(255, 255, 255, 0.6)"
      }
    }
  ]
}