<template>
  <el-dialog title="远程设备调试" :visible.sync="debugVisible" width="900px" :before-close="handleClose" :close-on-click-modal="false" center>
    <div class="from-body">
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">关 闭</el-button>
        <el-button @click="loadDebug">刷 新</el-button>
        <el-button @click="submit">发 送</el-button>
      </span>
      <div style="height: 20px;">&nbsp;</div>
      <el-form :model="ruleForm" ref="ruleForm" :rules="rules" label-width="auto" class="demo-ruleForm">
        <el-form-item label="调试信息" prop="content">
          <el-input v-model="ruleForm.content" placeholder="请输入8个字节数组（空格分开）" style="width:650px;"/>
        </el-form-item>
      </el-form>
      <el-table 
        id="debug-table" 
        :data="tableData" 
        :header-cell-style="{ 
          background:'#F0FFFF',
          color:'rgba(0, 0, 0, 0.9)', 
          fontSize: '14px', 
          fontWeight: 'bold'
        }"
        :row-style="{ height: '36px' }" 
        :cell-style="columnStyle"
      >
        <el-table-column prop="send" label="发送信息" width="200" align="center"/>
        <el-table-column prop="reply" label="应答内容" width="360" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <div :style="{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'normal'}">
              {{ scope.row.reply }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="updatedAt" label="调试时间" width="170" align="center"/>
      </el-table>
    </div>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      tableData: [],
      ruleForm: {
        content: null,
      },
      components: { },
      rules: {
        content: [
          {
            required: true,
            message: "调试信息不能为空",
            trigger: "blur",
          }
        ],
      }
    };
  },
  computed: {
    ...mapState(["buttonTextColor"]),
  },
  props: ["debugVisible","deviceId"],
  watch: {
    debugVisible(newValue) {
      if (newValue) {
        this.loadDebug();
      }
    },
  },
  methods: {
    onInput() {
      this.$forceUpdate();
    },
    handleClose() {
      this.ruleForm = {
        content: null,
      };
      this.$emit("handleClose");
    },
    loadDebug() {
      this.request.dataDebug({"deviceId": this.deviceId}).then((res) => {
        if (res.code==0) {
          this.tableData=res.data;
        }
      });
    },
    submit() {
      if (this.ruleForm.content==null||this.ruleForm.content=="") {
        this.$message.error("调试信息不能为空");
        return;
      } 
      var param={
        "deviceId": this.deviceId,
        "content": this.ruleForm.content,
      };
      this.request.deviceDebug(param).then((res) => {
        if (res.code==0) {
          this.$message({
            message: JSON.stringify(res.data,null,2),
            type: 'info',
            center: true,
            duration: 12000
          });
          setTimeout(()=>{
            this.loadDebug();
          },6000);
        }
      });
    },
  },
};
</script>
<style scoped>
.from-body {
  padding: 0 60px;
  box-sizing: border-box;
}
.table {
  flex:none;
  position: relative;
  display: flex;
  flex-direction: column;
}
.dilu-table {
  flex: 1;
  overflow: auto;
}
.custom-talbe {
  width:100%;
}
::v-deep .el-table .el-table__row {
  height: 44px;
}
::v-deep .el-table .cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>