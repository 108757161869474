<template>
  <!-- 角色管理 -->
  <div class="dilu-page">
    <!-- 搜索区域 -->
    <div class="search">
      <el-form ref="form" :model="searchParams" label-width="auto" class="search-form">
        <el-form-item label="角色名称">
          <el-input v-model="searchParams.roleName" placeholder="请输入角色名称"></el-input>
        </el-form-item>
      </el-form>
      <div class="search-submit">
        <el-button icon="el-icon-search" type="primary" @click="search">搜索</el-button>
        <el-button icon="el-icon-refresh" type="primary" @click="reset">重置</el-button>
      </div>
    </div>

    <!-- 按钮区域 -->
    <div class="operation-div">
      <el-button icon="el-icon-plus" type="primary" @click="add">新增</el-button>
    </div>

    <!-- 表格 -->
    <DiluTable :list="tableData" :tableConfig="tableConfig" :total="total" :pageSize="searchParams.pageSize" @currentChange="currentChange">
      <el-table-column prop="date" label="#" width="80" align="center">
        <template slot-scope="scope">
          <span>{{ scope.$index + 1 }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="roleName" label="角色名称" align="center"/>
      <el-table-column prop="createdAt" label="创建时间" align="center"/>
      <el-table-column prop="accountNum" label="人数" align="center"/>
      <el-table-column prop="address" label="操作" align="center" fixed="right">
        <template slot-scope="scope">
          <div class="table-operation">
            <el-button @click="see(scope.row)" type="text">查看</el-button>
            <el-button @click="edit(scope.row)" type="text">编辑</el-button>
            <el-button type="text" @click="remove(scope.row)">删除</el-button>
          </div>
        </template>
      </el-table-column>
    </DiluTable>

    <!-- 新增角色 -->
    <AddRoleForm :roleVisible="roleVisible" @roleClose="roleClose" @roleManagementList="roleManagementList" :isAdd="isAdd" :currentRole="currentRole"/>
    
  </div>
</template>


<script>
import DiluTable from "@/components/public/dilu-table.vue";
import AddRoleForm from "./module/addRoleForm.vue";
import { mapState } from "vuex";
export default {
  data() {
    return {
      currentRole: {},
      isAdd: 1,
      isSerch: false, //是否在查询
      roleVisible: false,
      tableConfig: {
        isTree: false,
        isPage: true,
      },
      tableData: [],
      total: 0,
      searchParams: {
        pageNum: 1, //页码
        pageSize: 12, //每页条数
        roleName: "", //角色名称
      },
    };
  },
  components: {
    DiluTable,
    AddRoleForm,
  },
  computed: {
    ...mapState(["buttonTextColor"]),
  },
  mounted() {
    this.roleManagementList();
  },
  methods: {
    //翻页
    currentChange(e) {
      if (!this.isSerch) {
        this.searchParams.pageNum = 1;
        this.searchParams.pageSize = 10;
        this.searchParams.roleName = "";
      }
      this.searchParams.pageNum = e;
      this.roleManagementList();
    },
    //新增
    add() {
      this.isAdd = 1;
      this.roleVisible = true;
    },
    //查看
    see(e) {
      this.currentRole = e;
      this.roleManagementInfo(e.id, 3, 0);
    },
    //编辑
    edit(e) {
      this.currentRole = e;
      this.roleManagementInfo(e.id, 2, 1);
    },
    //角色管理-根据id查询菜单按钮权限
    roleManagementInfo(id, i, j) {
      let str = "?id=" + id + "&flag=" + j;
      this.request.roleManagementInfo(str).then((res) => {
        let arr = [];
        let arr1 = [];
        for (let i in res.data.buttons) {
          arr.push(-res.data.buttons[i].id);
        }
        for (let i in res.data.menus) {
          arr1.push(res.data.menus[i].id);
        }
        this.currentRole.menuIds = arr1;
        this.currentRole.buttonIds = arr;
        this.currentRole.treeList = res.data.treeList;
        this.isAdd = i;
        this.roleVisible = true;
      });
    },
    // 关闭角色弹窗
    roleClose() {
      this.roleVisible = false;
    },
    //搜索
    search() {
      this.isSerch = true; //是否在查询
      this.searchParams.pageNum = 1;
      this.total = 0;
      this.roleManagementList();
    },
    //重置
    reset() {
      this.isSerch = false; //是否在查询
      this.searchParams.pageNum = 1;
      this.searchParams.pageSize = 10;
      this.searchParams.roleName = "";
      this.total = 0;
      this.roleManagementList();
    },
    //删除
    remove(e) {
      this.$confirm("是否删除该角色", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let str = "?id=" + e.id;
        this.request.roleManagementDelete(str).then((res) => {
          if (res.code==0) {
            this.$message.success("删除成功");
            this.roleManagementList();
          }
        });
      });
    },
    //查询列表
    roleManagementList() {
      this.request.roleManagementList(this.searchParams).then((res) => {
        this.tableData = res.data;
        this.total = res.dataMaxCount;
      });
    },
  },
};
</script>

<style scoped>
</style>