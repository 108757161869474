<template>
  <div class="dilu-page">

    <div class="search">
      <el-form :inline="true" :model="searchParams" size="mini">
        <el-form-item label="最近在线">
          <el-select v-model="searchParams.lastOnline" style="width:120px;margin-right:10px;" clearable>
            <el-option label="10分钟内" value=10 />
            <el-option label="1小时内" value=20 />
            <el-option label="24小时内" value=30 />
            <el-option label="72小时内" value=40 />
          </el-select>
        </el-form-item>
        <el-form-item label="设备名称">
          <el-input v-model="searchParams.category" style="width:120px;margin-right:10px;" clearable/>
        </el-form-item>
        <el-form-item label="设备型号">
          <el-input v-model="searchParams.model" style="width:120px;margin-right:10px;" clearable/>
        </el-form-item>
        <el-form-item label="网关编号">
          <el-input v-model="searchParams.gateway" style="width:120px;margin-right:10px;" clearable/>
        </el-form-item>
        <el-form-item label="项目编号">
          <el-input v-model="searchParams.project" style="width:120px;margin-right:10px;" clearable/>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="goSearch" style="width:80px;margin-left:20px;">查 询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <DiluTable :list="tableData" :tableConfig="tableConfig" :pageSize="searchParams.pageSize" @currentChange="currentChange" :total="total">
      <el-table-column prop="projectCode" label="项目编码" width="200" align="center">
        <template slot-scope="scope">
          <span @click="goProject(scope.row.projectCode)">{{scope.row.projectCode}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="gateway" label="网关编号" width="150" align="center"/>
      <el-table-column prop="address" label="端口地址" width="110" align="center"/>
      <el-table-column prop="categoryName" label="设备名称" width="200" align="left"/>
      <el-table-column prop="modelName" label="设备型号" width="200" align="left"/>
      <el-table-column prop="manufacturerName" label="制造商" width="200" align="left"/>
      <el-table-column prop="status" label="在线状态" align="center" width="100">
        <template slot-scope="scope">
          <span v-if="scope.row.status == 'yes'">在线</span>
          <span v-if="scope.row.status == 'no'">离线</span>
        </template>
      </el-table-column>
      <el-table-column prop="id" label="设备编号" width="100" align="center"/>
      <el-table-column prop="lastOnline" label="最近在线" width="180" align="center"/>
      <el-table-column prop="createdAt" label="创建时间" width="180" align="center"/>
      <el-table-column label="操作" align="center" width="100" fixed="right">
        <template slot-scope="scope">
          <div class="table-operation">
            <span @click="goDebug(scope.row)" icon="el-icon-set-up" style="color:#3456ff">调试</span>
          </div>
        </template>
      </el-table-column>
    </DiluTable>

    <!--设备远程调试-->
    <DebugDTU :deviceId="deviceId" :debugVisible="debugVisible" @handleClose="handleClose"/>

  </div>
</template>

<script>
import DiluTable from "@/components/public/dilu-table.vue";
import DebugDTU from "./module/debugDTU.vue";
import store from "@/store";
export default {
  data() {
    return {
      searchParams: {
        project: null,
        lastOnline: null,
        category: null,
        model: null,
        gateway: null,
        pageNum: 1, 
        pageSize: 12,
      },
      tableConfig: {
        isTree: false,
        isPage: true, 
      },
      total: 0,
      tableData: [],
      debugVisible: false,
      deviceId: null,
    };
  },
  components: {
    DiluTable,
    DebugDTU,
  },
  computed: {
    
  },
  mounted() {
     this.goSearch();
  },
  methods: {
     goDebug(e) {
      this.deviceId=e.id;
      this.debugVisible=true;
     },
     handleClose() {
      this.debugVisible=false;
     },
     goProject(projectCode) {
      this.request.projectInfo({ project: projectCode }).then(res => {
        if (res.code == 0) {
          store.commit("setProjectCurrentRowData", res.data);
          this.$router.push({
            path: "/project/proinfo/info",
          });
        }
      });
     },
     goSearch() {
      this.request.searchDevice(this.searchParams).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data;
          this.total = res.dataMaxCount;
        }
      });
     },
     //翻页
     currentChange(e) {
        this.searchParams.pageNum = e;
        this.goSearch();
     },
  },
};
</script>

<style lang="scss" scoped>
  ::-webkit-scrollbar {
      display: none;
  }
  .search {
    margin-bottom: 15px;
    ::v-deep(.el-form) {
      .el-form-item--mini.el-form-item {
        margin-bottom: 0;
      }
      .el-form-item__label {
        line-height: 40px;
      }
    }
  }
</style>