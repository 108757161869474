<template>
    <div class="vf-tabs">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
            <el-tab-pane label="2D" name="2D"></el-tab-pane>
            <el-tab-pane label="3D" name="3D"></el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
export default {
    name: "VTabs",
    data() {
        return {
            activeName: '',
        };
    },
    props: {
        tabVal: {
            type: String,
            default: () => {
                return {}
            }
        }
    },
    computed: {

    },
    mounted() {
        this.activeName = this.tabVal;
    },
    methods: {
        handleClick(e) {
            //console.log(this.$emit)
            this.$emit('update:msg', e.label);

        }
    },
}
</script>

<style scoped lang="scss">
.vf-tabs {
    display: flex;
    justify-content: right;
    height: 78px;
    width: auto;
    position: absolute;
    top: 24px;
    right: 380px;
    color: #fff;

    ::v-deep .el-tabs__item {
        color: #fff;
        border: 0 !important;
    }

    ::v-deep .el-tabs__nav {

        border: 0 !important;
    }

    ::v-deep .el-tabs__header {

        border: 0 !important;
    }

    ::v-deep .el-tabs__item {
        background: url("~@/assets/images/visual/tab_bg.png") no-repeat;
        background-size: 100% 100%;
        margin: 0 10px;
    }

    ::v-deep .is-active {
        background: url("~@/assets/images/visual/tab_bg_active.png") no-repeat;
        background-size: 100% 100%;
    }
}
</style>