<template>
  <div class="upload">
    <el-upload
      class="upload-image"
      :action="action"
      list-type="picture-card"
      :accept="accept"
      :headers="headers"
      :limit="limit"
      :file-list="fileList"
      :data="qiniuData"
      :before-upload="beforeupload"
      :disabled="fileList.length >= limit || uploaDisabled"
      :on-success="(response, file, fileList) => uploadSuccess(response, file, fileList)"
    >
      <i slot="default" class="el-icon-plus"/>
      <div slot="file" slot-scope="{ file }">
        <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
        <span class="el-upload-list__item-actions">
          <!-- 打开图片缩略图 -->
          <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
            <i class="el-icon-zoom-in"/>
          </span>
          <!-- 下载图片 -->
          <span v-if="!uploaDisabled" class="el-upload-list__item-delete" @click="handleDownload(file)">
            <i class="el-icon-download"/>
          </span>
          <!-- 删除图片 -->
          <span v-if="!uploaDisabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
            <i class="el-icon-delete"/>
          </span>
        </span>
      </div>
      <div slot="tip" class="el-upload__tip" v-if="limitWidth && limitHeight">
        {{ "只能上传" + accept + "图片尺寸" + limitWidth + "*" + limitHeight + "px" }}
      </div>
    </el-upload>
    <!-- 图片弹窗 -->
    <el-dialog :visible.sync="dialogVisible" :modal="false">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      qiniuData: {},
      domain: null,
      headers: {},
      action: "",
      fileUrl: "",
    };
  },
  props: [
    "limit",
    "fileList",
    "type",
    "accept",
    "uploaDisabled",
    "limitWidth",
    "limitHeight",
  ],
  mounted() {
    this.getQiniuToken();
    this.headers = { Authorization: localStorage.getItem("token") };
  },
  methods: {
    //获取七牛token
    getQiniuToken() {
      this.request.qiniuUploadToken().then((res) => {
        this.qiniuData.token = res.data.uptoken;
        this.domain = res.data.domain;
        this.action = res.data.upload;
      });
    },
    //上传成功
    uploadSuccess(res, file, fileList) {
      this.$emit("success", {
        url: this.domain + res.key,
        hash: res.hash,
        name: file.name,
        fileList,
      });
    },
    //删除图片
    handleRemove(file) {
      this.$emit("remove", file.name);
    },
    //打开图片缩略图
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    //下载图片
    handleDownload(file) {
    },
    //格式化时间
    formatDate() {
        var date=new Date();
        const year = date.getFullYear().toString().padStart(4, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hour = date.getHours().toString().padStart(2, '0');
        const minute = date.getMinutes().toString().padStart(2, '0');
        const second = date.getSeconds().toString().padStart(2, '0');
        return year+""+month+""+day+""+hour+""+minute+""+second+""+Math.round(Math.random()*100);
    },
    //  上传前处理文件类型和大小
    beforeupload(file) {
      let suffix = "." + file.name.split(".")[file.name.split(".").length - 1];
      let isKon = file.name.indexOf(" ") != -1;
      if (isKon) {
        this.$message.error("文件名不能有空格");
        return false;
      }
      if (this.accept!=undefined&&this.accept!=null) {
        if (this.accept.indexOf(suffix)==-1) {
          this.$message.error("只能上传" + this.accept + "文件");
          return false;
        }
      }
      this.qiniuData.key=this.formatDate()+suffix; 
      if (this.size!=undefined&&this.size!=null) {
        const isSize = file.size / 1024 / 1024 < this.size;
        if (this.size && !isSize) {
          this.$message.error("上传文件要求不超过" + this.size + "M");
          return false;
        } 
      }
      return true;
    },
  },
};
</script>
<style scoped>
</style>