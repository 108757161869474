<template>
  <div class="dilu-page">
    <div class="search">
      <el-form :inline="true" :model="searchParams" size="mini">
        <el-form-item label="最近在线">
          <el-select v-model="searchParams.lastOnline" style="width:120px;margin-right:10px;" clearable>
            <el-option label="10分钟内" value=10 />
            <el-option label="1小时内" value=20 />
            <el-option label="24小时内" value=30 />
            <el-option label="72小时内" value=40 />
          </el-select>
        </el-form-item>
        <el-form-item label="项目编号">
          <el-input v-model="searchParams.project" style="width:120px;margin-right:10px;" clearable/>
        </el-form-item>
        <el-form-item label="网关编号">
          <el-input v-model="searchParams.imei" style="width:120px;margin-right:10px;" clearable/>
        </el-form-item>
        <el-form-item label="SIM卡号">
          <el-input v-model="searchParams.imsi" style="width:120px;margin-right:10px;" clearable/>
        </el-form-item>
        <el-form-item label="ICCID">
          <el-input v-model="searchParams.iccid" style="width:120px;margin-right:10px;" clearable/>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="goSearch" style="width:80px;margin-left:20px;">查 询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <DiluTable :list="tableData" :tableConfig="tableConfig" :pageSize="searchParams.pageSize" @currentChange="currentChange" :total="total">
      <el-table-column prop="projectCode" label="项目编码" width="200" align="center">
        <template slot-scope="scope">
          <span @click="goProject(scope.row.projectCode)">{{scope.row.projectCode}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="imei" label="网关编号" width="160" align="center"/>
      <el-table-column prop="imsi" label="SIM卡号" width="160" align="center"/>
      <el-table-column prop="iccid" label="ICCID" width="200" align="center"/>
      <el-table-column prop="status" label="在线状态" align="center" width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.status == 'yes'" style="color:green;">在线</span>
          <span v-if="scope.row.status == 'no'">离线</span>
        </template>
      </el-table-column>
      <el-table-column prop="lastOnline" label="最近在线" width="180" align="center"/>
      <el-table-column prop="createdAt" label="创建时间" width="180" align="center"/>
    </DiluTable>
  </div>
</template>

<script>
import DiluTable from "@/components/public/dilu-table.vue";
import store from "@/store";
export default {
  data() {
    return {
      searchParams: {
        project: null,
        lastOnline: null,
        imei: null,
        imsi: null,
        iccid: null,
        pageNum: 1, 
        pageSize: 12,
      },
      tableConfig: {
        isTree: false,
        isPage: true, 
      },
      total: 0,
      tableData: [],
    };
  },
  components: {
    DiluTable
  },
  computed: {
    
  },
  mounted() {
     this.goSearch();
  },
  methods: {
     goProject(projectCode) {
      this.request.projectInfo({ project: projectCode }).then(res => {
        if (res.code == 0) {
          store.commit("setProjectCurrentRowData", res.data);
          this.$router.push({
            path: "/project/proinfo/info",
          });
        }
      });
     },
     goSearch() {
      this.request.searchGateway(this.searchParams).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data;
          this.total = res.dataMaxCount;
        }
      });
     },
     //翻页
     currentChange(e) {
        this.searchParams.pageNum = e;
        this.goSearch();
     },
  },
};
</script>

<style lang="scss" scoped>
  ::-webkit-scrollbar {
      display: none;
  }
  .search {
    margin-bottom: 15px;
    ::v-deep(.el-form) {
      .el-form-item--mini.el-form-item {
        margin-bottom: 0;
      }
      .el-form-item__label {
        line-height: 40px;
      }
    }
  }
</style>