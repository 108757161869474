<template>
  <div class="chart-pie">
    <mr-echarts :options="optionsPie"></mr-echarts>
  </div>
</template>

<script>
import MrEcharts from "@/components/public/mr-echarts.vue";
import chartPie from "../chartOpt/chartPie";
import build from "../js/build";
export default {
  name: "SummaryPie",
  components: {
    MrEcharts,
  },
  props: {
    propsData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      optionsPie: {},
      buildStatic: build.buildStatic
    };
  },
  watch: {
    propsData: {
      deep: true,
      handler() {
        this.initChart();
      },
    },
  },
  methods: {
    initChart() {
      setTimeout(() => {
        const buildList = this.propsData.buildList || [];
        let chartData = [];
        for(let i=0; i<buildList.length; i++) {
          const item = buildList[i];
          const findItem = this.buildStatic.find(i => i.id === item.name);
          const name = findItem ? findItem.name : "";
          chartData.push({
            ...item,
            name
          })
        }
        chartPie.title.text=this.propsData.total+"\r\n项目总数";
        chartPie.series[0].data = chartData;
        this.optionsPie = chartPie;
      }, 10);
    },
  },
};
</script>

<style scoped lang="scss">
.chart-pie {
  width: 333px;
  height: 100%;
  border-radius: 4px;
  opacity: 1;
  background: linear-gradient(179deg, #ffffff 0%, #f4f7ff 72%, #edf1ff 122%);
}
</style>
