<template>
  <div class="mr-visual">
    <div class="mr-visual-container">
      <div class="mr-visual-header">
        <v-header/>
        <v-card :propsData="projectCode"/>
      </div>
      <div class="title">宁夏链杞烘干车间智慧控制系统</div>
      <div class="operation">
        <div style="width:77.5%;margin-left:1.5%;">
          <el-form ref="form" :model="searchParams" label-width="auto" class="search-form" style="height:50px;line-height:50px;display:flex;align-items:center;margin-top:5px;">
            <el-form-item label="设备列表">
              <el-select v-model="searchParams.deviceId" placeholder="请选择查看设备" style="width:300px;margin-top:5px;" @change="getDeviceInfo">
                <el-option v-for="(item, index) in deviceList" :key="index" :label="item.name" :value="item.id"/>
              </el-select>
            </el-form-item>
            <el-form-item label="参数属性">
              <el-select v-model="searchParams.readWrite" placeholder="全部属性" style="width:150px;margin-top:5px;" clearable>
                <el-option label="可读" value=10 />
                <el-option label="可写" value=20 />
                <el-option label="可读可写" value=30 />
              </el-select>
            </el-form-item>
            <el-form-item label="参数名称">
              <el-input v-model="searchParams.dictName" style="width:150px;margin-top:5px;" clearable/>
            </el-form-item>
            <el-date-picker class="pick-date" style="width:140px;margin-bottom:25px;" v-model="selectDate" type="date" placeholder="选择日期" :picker-options="pickerOptions" @change="changeDate"/>
          </el-form>
        </div>
        <div style="width:20%;margin-right:1%;display:flex;flex-direction:row;justify-content:flex-end;">
          <div class="btnHome" @click="backHome"><img :src="zhuye" style="width:20px;height:20px;margin-right:5px;"/>主页</div>
          <div class="btnBack" @click="backPrev"><img :src="back" style="width:16px;height:16px;margin-right:5px;"/>返回</div>
        </div>
      </div>
      <div class="body1">
        <div class="param-list" v-if="paramList.length>0">
          <el-checkbox-group v-model="checkParams" @change="handleChecked">
            <template v-for="(item,index) in paramList">
              <template v-if="showLine(item)">
                <el-checkbox :key="index" :label="item.id" class="check-item">{{ item.chinese!=null?item.chinese:item.name}}</el-checkbox> 
              </template>
            </template>
          </el-checkbox-group>
        </div>
        <div class="device-chart">
          <div v-for="(item,index) in checkParams" :id="`chart${item}`" :key="index" class="chart-item"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { DateToYYYYYMMDD, DateToHHmm, DateToMMddHHmm } from "../../../utils/util/util";
import * as echarts from "echarts";
import VHeader from "./components/v-header.vue";
import VCard from "./components/v-card.vue";
export default {
  name: "nxhg",
  components: {
    VCard,
    VHeader
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }]
      },
      searchParams:{
        deviceId: null,
        readWrite: null,
        dictName: null,
        pageSize: 20,
        pageNum: 1,
      },
      bakup: {},
      option: {},
      checkParams: [],
      paramList: [],
      deviceData: {},
      dictParams: [],
      paramData: [],
      placeList: [],
      deviceList: [],
      itemList: [],
      projectCode: "",
      selectDate: "",
      first: true,
      zhuye: require("@/assets/images/nxhg/zhuye.png"),
      back: require("@/assets/images/nxhg/back.png"),
    }
  },
  computed: { },
  mounted() {
    this.projectCode = this.$route.query.projectCode;
    this.selectDate=new Date();
    this.getPlaceList();
  },
  methods: {
    getDeviceList() {
      this.request.deviceList({project:this.projectCode}).then((res) => {
        this.deviceList=[];
        var itemList = res.data || [];
        for (var i=0; i<itemList.length; i++) {
          var name=itemList[i].categoryName+"|"+itemList[i].modelName+"|"+itemList[i].manufacturerName;
          var place=this.getPlace(this.placeList,itemList[i].place[itemList[i].place.length-1]);
          if (place!=null) {
            name=name+"|"+place;
          }
          if (itemList[i].online=='yes') {
            name=name+"|在线";
          } else {
            name=name+"|离线";
          }
          var item={
            "id":itemList[i].id,
            "name":name
          };
          if (itemList[i].categoryName=="PLC") {
            this.deviceList.push(item);
            if (i!=0) {
              var temp=this.deviceList[0];
              this.deviceList[0]=item;
              this.deviceList[i]=temp;
            }
          } else {
            this.deviceList.push(item);
          }
        }
        this.itemList=itemList;
        if (this.deviceList.length>0) {
          this.searchParams.deviceId=this.deviceList[0].id;
          this.getDeviceInfo();
        }
      });
    },
    getPlaceList() {
      this.request.placeList({project:this.projectCode}).then((res) => {
        if (res.code==0) {
          this.placeList=[res.data];
          if (this.first) {
            this.first=false;
            this.getDeviceList();
          }
        }
      });
    },
    getPlace(list,placeId) {
      for (var j=0;j<list.length;j++) {
        if (placeId==list[j].id) {
          return list[j].name;
        }
        if (list[j].nodeList!=null&&list[j].nodeList.length>0) {
          return this.getPlace(list[j].nodeList,placeId);
        }
      }
      return null;
    },
    getDeviceInfo() {
      for (var i=0; i<this.itemList.length; i++) {
        if (this.searchParams.deviceId==this.itemList[i].id) {
          this.current=this.itemList[i];
        }
      }
      this.getParamList();
    },
    showLine(item) {
      if (this.searchParams.readWrite==null||this.searchParams.readWrite=='') {
        if (this.searchParams.dictName==null||this.searchParams.dictName=='') {
          return true;
        } else {
          if (item.name.includes(this.searchParams.dictName)) {
            return true;
          } else {
            return false;
          }
        }
      } else {
        if (this.searchParams.readWrite==item.readWrite) {
          if (this.searchParams.dictName==null||this.searchParams.dictName=='') {
            return true;
          } else {
            if (item.name.includes(this.searchParams.dictName)) {
              return true;
            } else {
              return false;
            }
          }
        } else {
          return false;
        }
      }
    },
    getParamList() {
      var params={
        "deviceId":this.current.id
      }
      this.request.deviceParams(params).then((res) => {
        if (res.code == 0) {
          this.checkParams=[];
          this.clearChart();
          this.paramList=res.data;
          if (this.first==true) {
            this.initChart();
          }
        }
      });
    },
    clearChart() {

    },
    initChart() {
      this.first=false;

    },
    changeDate() {
      this.checkParams=[];
    },
    handleChecked(val) {
      this.checkParams=val;
      if (this.checkParams.length>3) {
        this.checkParams.splice(0,1);
      }
      if (this.checkParams.length>0) {
        setTimeout(()=>{
          for (let i=0;i<this.checkParams.length;i++) {
            var param={};
            for (var j=0;j<this.paramList.length;j++) {
              if (this.checkParams[i]==this.paramList[j].id) {
                param=this.paramList[j];
              }
            }
            var data=this.bakup[this.checkParams[i]+"-"+DateToYYYYYMMDD(this.selectDate)];
            if (data==null) {
              this.getParamData(param);
            } else {
              this.showChart(param,data);  
            }
          }
        },100);
      };
    },
    getParamData(param) {
      var params={
        "deviceId": this.current.id,
        "items": param.name,
        "date": DateToYYYYYMMDD(this.selectDate)
      };
      this.request.readDateParams(params).then((res) => {
        if (res.code == 0) {
          var data=[];
          if (res.data!=null&&res.data.length>0) {
            let list=res.data[0].data;
            if (list!=null&&list.length>0) {
              for (let i=0; i<list.length; i++) {
                var time=DateToHHmm(new Date(parseInt(list[i].time)*1000));
                data.push({"value":[time,list[i].value]});
              }
            }
          }
          this.bakup[param.id+"-"+DateToYYYYYMMDD(this.selectDate)]=data;
          this.showChart(param,data);  
        }
      });
    },
    showChart(param,data) {
      var chartDom = document.getElementById("chart"+param.id);
      var myChart = echarts.init(chartDom,'light');
      var serie={
        "data": data,
        "type": "line",
        "name": param.name,
        "smooth": true,
        "endLabel": {
          "show": true,
          "formatter": function (params) {
            return params.seriesName;
          }
        }
      };
      var option = {
        xAxis: {
          type: "category",
          boundaryGap: false,
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: false,
          },
          axisLine: {
            show: true,
          },
        },
        tooltip: {
          trigger: "axis",
        },
        grid: {
          left: 40,
          right: 80,
          top: 15,
          bottom: 35,
        },
        series: [serie],
      };
      myChart.setOption(option,true);
    },
    backHome() {
      this.$router.replace({
        path: "/project"
      });
    },
    backPrev() {
      this.$router.replace({
        path: "/nxhg",
        query: {
          projectCode: this.projectCode,
        },
      });
    }
  }
}
</script>
<style scoped lang="scss">
.mr-visual {
  height: 100%;
  background: #EFEFEF;
  overflow: hidden;
  position: relative;

  .mr-visual-container {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .mr-visual-header {
    height: 52px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    background: black;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 24px;
    box-sizing: border-box;
  }

  .title {
    width:100%;
    height:52px;
    line-height:52px;
    text-align:center;
    color:white;
    font-size: 20px;
    top: 0px;
    position: absolute;
    z-index: 200;
  }

  .operation {
    margin-top:65px;
    width:100%;
    height:50px;
    line-height:50px;
    display: flex;
    flex-direction: row;
  }

  .btnSwitch {
    width:90px;
    height:36px;
    line-height:36px;
    text-align:center;
    margin-left:15px;
    margin-right:10px;
    color:#2D76F6;
    background:white;
    border-radius:10px;
  }

  .labelStatus {
    width:80px;
    height:36px;
    line-height:36px;
    font-size:16px;
    font-weight: bold;
    margin-left:15px;
  }

  .btnHome {
    width:90px;
    height:36px;
    line-height:36px;
    text-align:center;
    margin-right:10px;
    color:black;
    background:white;
    border-radius:10px;
    display:flex;
    align-items: center;
    justify-content: center;
  }

  .btnBack {
    width:90px;
    height:36px;
    line-height:36px;
    text-align:center;
    margin-left:15px;
    color:black;
    background:white;
    border-radius:10px;
    display:flex;
    align-items: center;
    justify-content: center;
  }

  .body1 {
    width: 98%;
    height: 85%;
    margin-left: 1%;
    margin-right: 1%;
    background: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
  }

  .check-item {
    width: 12%;
    height: 25px;
  }

  .param-list {
    width: 96%;
    height: 125px;
    margin-left: 1.5%;
    margin-right: 1.5%;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 10px;
    padding-top: 10px;
    background-color: whitesmoke;
    border-radius: 5px;
    overflow: auto;
  }

  .device-chart {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .chart-item {
    width: 96%;
    height: 180px;
    margin-bottom: 20px;
  }

}
</style>
