<template>
  <div class="device-params">
    <div class="left">
      <d-card1 :propsData="deviceData"></d-card1>
      <div class="driver40"></div>
      <d-card2 :propsData="deviceData"></d-card2>
    </div>
    <div class="right">
      <d-card3 :propsData="deviceData" @setParams="setParams"></d-card3>
      <div class="driver52"></div>
      <d-card4 :propsData="deviceData" @setParams="setParams"></d-card4>
    </div>
  </div>
</template>


<script>
import DCard1 from "./components/d-card1.vue";
import DCard2 from "./components/d-card2.vue";
import DCard3 from "./components/d-card3.vue";
import DCard4 from "./components/d-card4.vue";
import { mapState } from "vuex"

export default {
  name: "VDeviceParams",
  components: {
    DCard1,
    DCard2,
    DCard3,
    DCard4,
  },
  data() {
    return {
      projectCode: "",
      deviceId: "",
      deviceData: {},
      active: 0
    }
  },
  props: {
    deviceIdStr: {
      type: [String, Number],
      default: ""
    }
  },
  watch: {
    deviceIdStr: {
      handler() {
        this.deviceId = this.deviceIdStr;
        if (this.deviceId) {
          this.getDeviceInfo()
        } else {
          this.deviceData = {};
        }
      }
    }
  },
  computed: {
    ...mapState(["projectCurrentRowData"])
  },
  mounted() {
    const currentData = this.projectCurrentRowData;
    this.projectCode = currentData.projectCode;
    this.deviceId = this.deviceIdStr;
    if (this.deviceId) {
      this.getDeviceInfo()
    }
    
  },
  methods: {
    setParams(params) {
      this.request.deviceSetParams({ ...params, deviceId: this.deviceId }).then(res => {
        if (res.code == 0) {
          setTimeout(() => {
            this.getDeviceInfo();
          }, 5000)
        }
      })
    },
    getDeviceInfo() {
      this.request.deviceScreenInfo({ deviceId: this.deviceId, project: this.projectCode }).then(res => {
        this.deviceData = res.data || {};
      })
    }
  }
}
</script>

<style scoped lang="scss">
.device-params {
  flex: 1;
  display: flex;
  justify-content: space-between;
  margin-top: 46px;
  .left {
    height: 100%;
    flex: 2;
    
  }
  .right {
    flex: 1;
    height: 100%;
    padding-left: 34px;
    box-sizing: border-box;
    
  }
  .driver40 {
    height: 40px;
  }

  .driver52 {
    height: 52px;
  }
}
</style>