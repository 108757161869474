<template>
  <div class="break-bottom-center">
    <div class="box">
      <div class="title">
        <span>故障看板</span>
      </div>
      <div class="chart">
        <div :class="['chart-item', i.level == 20 ? 'error' : '']" v-for="i, index in faultBoards" :key="'faultBoards_' + index">
          <span class="label">{{ i.name }}</span>
          <span class="value"><span class="num">{{ i.value }}</span>{{ i.unit }}</span>
          <span class="tips">Tips&nbsp;&nbsp;{{ i.info }}</span>
        </div>
      </div>
    </div>
    <div class="box">
      <div class="title">
        <span>故障排名</span>
      </div>
      <div class="chart">
        <div class="vc-list">
          <div v-for="i,index in faultRanks" :key="'faultRanks_' + index">
            <span class="label">
              <i
                ><img
                  :src="require('@/assets/images/visual/' + (index+1) + '.png')"
                  alt=""
              /></i>
              {{ i.name }}
            </span>
            <div class="num-box">
              <span class="num" :style="{ width: (i.rate) + '%' }"></span>
            </div>
            <span class="val">{{ i.value }}</span>
            <span class="rate">{{i.rate}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "BreakBottomLeft",
  props: {
    propsData: {
      type: Object, 
      default: () => {
        return {}
      }
    }
  },
  watch: {
    propsData: {
      deep: true,
      handler() {
        this.faultData = JSON.parse(JSON.stringify(this.propsData));
        this.faultBoards = this.faultData.faultBoards.sort((a, b) => b.level - a.level)
        this.fmtRank()
      }
    }
  },
  data() {
    return {
      faultData: {},
      faultRanks: [],
      faultBoards: []
    };
  },
  mounted() {
  },
  methods: {
    fmtRank() {
      const rank = this.faultData.faultRanks;
      let total = rank.reduce((pre, cur) => {
        return (pre.value || 0)+(cur.value || 0)
      }, 0)
      
      rank.forEach(i => {
        i.rate = this.fmtRateForTotal(i.value, total)
      })

      this.faultRanks = rank;
    },
    fmtNum(val) {
      if (val <= 0) {
        return 0
      } else if (val >= 100) {
        return 100
      } else {
        return val
      }
    },
    // 当前数值占总数的比例
    fmtRateForTotal(value, total) {
      const val = (value / (total || 1) * 100).toFixed(2);
      return this.fmtNum(val)
    }
  },
};
</script>

<style scoped lang="scss">
.break-bottom-center {
  .box {
    width: 583px;
    height: 314px;
    opacity: 1;
    background: linear-gradient(
      180deg,
      rgba(27, 53, 56, 0.52) 0%,
      rgba(21, 52, 73, 0) 41%,
      rgba(13, 51, 97, 0.09) 100%
    );
    box-sizing: border-box;
    padding: 12px;
    border: 1px solid;
    border-image: linear-gradient(
        283deg,
        rgba(0, 255, 221, 0.26) 8%,
        rgba(0, 255, 221, 0) 24%,
        rgba(0, 229, 255, 0) 81%,
        rgba(39, 233, 255, 0.66) 96%
      )
      1;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    .title {
      width: 128px;
      height: 28px;
      background: url("~@/assets/images/visual/rect.png") no-repeat;
      background-size: 100% 100%;
      padding-left: 24px;
      margin-top: 24px;
      line-height: 28px;
      > span {
        font-family: "microsoft yahei ui","microsoft yahei";
        color: #ffffff;
        font-size: 16px;
      }
    }

    .chart {
      flex: 1;
      overflow-y: auto;
      .chart-item {
        height: 44px;
        background: linear-gradient(
          180deg,
          rgba(0, 118, 73, 0.22) 3%,
          rgba(0, 64, 39, 0.2) 100%
        );
        box-sizing: border-box;
        border: 1px solid;
        border-image: linear-gradient(
            180deg,
            rgba(18, 255, 156, 0.45) 0%,
            rgba(18, 255, 156, 0.11) 52%,
            rgba(56, 247, 168, 0.18) 100%
          )
          1;
        margin-top: 24px;
        padding: 0 28px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .label {
          display: inline-block;
          width: 120px;
          text-align: left;
          color: #bfebda;
        }
        .value {
          width: 80px;
          text-align: left;
          color: #bfebda;
        }
        span {
          font-family: "microsoft yahei ui","microsoft yahei";
          font-size: 14px;
          color: #bfebda;
        }
        .num {
          font-size: 24px;
          margin-right: 4px;
        }
        .tips {
          width: 50%;
          font-family: "microsoft yahei ui","microsoft yahei";
          font-size: 12px;
          text-align: left;
          color: #40936b;
        }

        &.error {
          background: linear-gradient(
            180deg,
            #411f1f 0%,
            rgba(65, 31, 31, 0) 100%
          );
          box-sizing: border-box;
          border: 1px solid;
          border-image: linear-gradient(
              180deg,
              rgba(255, 64, 64, 0.56) 0%,
              rgba(255, 64, 64, 0.4178) 58%,
              rgba(255, 64, 64, 0.26) 100%
            )
            1;
          .label {
            color: #faebeb;
          }
          .value {
            color: #cf1322;
          }
          .num {
            color: #cf1322;
          }
          .tips {
            color: #b82a2a;
          }
        }
      }

      > .vc-list {
        > div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 12px;
          &:nth-child(1) {
            margin-top: 12px;
          }
          .label {
            font-family: "microsoft yahei ui","microsoft yahei";
            font-size: 12px;
            font-weight: normal;
            letter-spacing: 0em;
            font-variation-settings: "opsz" auto;
            background: linear-gradient(
              180deg,
              rgba(255, 255, 255, 0.9) 0%,
              rgba(237, 254, 255, 0.9) 100%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            width: 80px;
            line-height: 1.5;
          }

          .num-box {
            width: 60%;
            height: 5px;
            background-color: rgba(255, 255, 255, 0.2);
            position: relative;
            .num {
              font-size: 12px;
              display: inline-block;
              height: 5px;
              width: 100%;
              background: linear-gradient(270deg, #FF0239 0%, rgba(255, 2, 82, 0) 100%);
              position: absolute;
              left: 0;
              top: 0;
            }
          }

          .val {
            font-family: "microsoft yahei ui","microsoft yahei";
            font-size: 18px;
            line-height: 12px;
            text-align: right;
            color: rgba(255, 255, 255, 0.6);
          }

          .rate {
            font-family: "microsoft yahei ui","microsoft yahei";
            font-size: 12px;
            font-weight: normal;
            line-height: 12px;
            text-align: right;
            letter-spacing: 0px;

            font-variation-settings: "opsz" auto;
            color: rgba(255, 255, 255, 0.4);
          }
        }
      }
    }
  }
}
</style>
