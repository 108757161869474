<template>
  <div class="d-box">
    <div class="device-img">
      <img src="./../images/device.png" alt="" />
      <img class="device_bg" src="./../images/device_bg.png" alt="" />
    </div>
    <div class="device-params">
      <div class="status-box">
        <div class="status">
          <span>{{ deviceEnum[deviceData.status] }}</span>
        </div>
        <div class="time">
          <p>持续工作 <span>{{ timeRange }}</span> 小时</p>
          <p>{{deviceData.startTime}}</p>
          <p>{{ deviceData.endTime }}</p>
        </div>
      </div>
      <div class="params-list">
        <div class="params-list-box" ref="params-list-box">
          <template v-if="deviceParams.length > 0">
            <template v-for="i in deviceParams">
              <div class="pl" v-if="i.runStatus == 10" :key="i.name + '_' + i.id">
                <p>{{ i.value }}</p>
                <p>{{i.name}}({{ i.unit }})</p>
              </div>
            </template>
          </template>
          <mr-empty v-else />
        </div>
        <span class="more-btn" v-if="isMore" @click="clickMore">{{ moreText }}</span>
      </div>
    </div>
    <div class="params">
      <p>设备信息</p>
      <ul>
        <li>
          <div class="label">名称</div>
          <div class="value">{{ deviceData.categoryName }}</div>
        </li>
        <li>
          <div class="label">型号</div>
          <div class="value">{{ deviceData.modelName }}</div>
        </li>
        <!-- <li>
          <div class="label">序列号</div>
          <div class="value">20102818889112</div>
        </li> -->
        <li>
          <div class="label">厂家</div>
          <div class="value">{{ deviceData.manufacturerName }}</div>
        </li>
        <li>
          <div class="label">安装日期</div>
          <div class="value">{{ deviceData.createdAt }}</div>
        </li>
        <!-- <li>
          <div class="label">资产编号</div>
          <div class="value">G1234689754</div>
        </li> -->
      </ul>
    </div>
  </div>
</template>

<script>

import MrEmpty from "@/components/public/mr-empty.vue";
export default {
  name: "DCard1",
  components: {
    MrEmpty,
  },
  props: {
    propsData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      list: 0,
      deviceData: {},
      deviceParams: [],
      deviceEnum: {
        10: "运行中",
        20: "已离线",
      },
      isMore: false,
      moreText: "展开"
    };
  },
  watch: {
    propsData: {
      deep: true,
      handler() {
        const { dictParams, paramData } = this.propsData;
        this.deviceData = this.propsData;
        if (dictParams && paramData) {
          const cloneParams = JSON.parse(JSON.stringify(dictParams));
          const cloneParamData = JSON.parse(JSON.stringify(paramData));
          this.initParams(cloneParams, cloneParamData);
        } else {
          this.deviceParams = [];
        }
      },
    },
  },
  computed: {
    timeRange() {
      const { startTime, endTime } = this.deviceData;
      let timeRange = null;
      if (startTime && endTime) {
        const start = new Date(startTime).getTime();
        const end  = new Date(endTime).getTime();
        const diff = end - start;
        timeRange = diff / 1000 / 60 / 60;
      }
      return timeRange
    }
  },
  methods: {
    getMore() {
      const refParamsListBox = this.$refs["params-list-box"];
      if (refParamsListBox.offsetHeight > 38) {
        this.isMore = true;
        this.moreText = "展开";
      } else {
        this.isMore = false;
      }
    },
    clickMore() {
      const refParamsListBox = this.$refs["params-list-box"];
      if (this.moreText == "收起") {
        refParamsListBox.scrollTop = 0;
        refParamsListBox.style.overflow = "hidden";
        this.moreText = "展开";
      } else {
        refParamsListBox.style.overflowY = "auto";
        this.moreText = "收起";
      }
    },
    initParams(cloneParams, paramData) {
      for (let i = 0; i < cloneParams.length; i++) {
        const item = cloneParams[i];
        this.fmtParams(item, paramData);
      }
      this.deviceParams = cloneParams;
    },
    fmtParams(item, paramData) {
      const fi = paramData.find((i) => i.name === item.name);
      item.value = fi ? fi.value + "" : "-";
    },
  },
};
</script>
<style scoped lang="scss">
.d-box {
  height: 326.1px;
  position: relative;
  border: 1px solid;
  border-image: linear-gradient(
      273deg,
      rgba(0, 255, 221, 0.26) 1%,
      rgba(0, 255, 221, 0) 19%,
      rgba(0, 229, 255, 0) 82%,
      rgba(39, 233, 255, 0.66) 98%
    )
    1;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 87px;
  padding-top: 45px;
  .device-img {
    width: 198px;
    height: 293px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    .device_bg {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 10;
    }
    &::after {
      content: "";
      display: inline-block;
      width: 237px;
      height: 65px;
      opacity: 1;
      background: url("../images/device_circle.png") no-repeat;
      background-size: 100% 100%;
      position: absolute;
      left: -22px;
      bottom: -16px;
    }
  }

  .device-params {
    width: 50%;
    .status-box {
      width: 237px;
      height: 66px;
      border-radius: 564px;
      background: url("../images/running_bg.png") no-repeat;
      background-size: 100% 100%;
      text-align: center;
      position: relative;
      .status {
        width: 72px;
        height: 66px;
        background: url("../images/circle.png") no-repeat;
        background-size: 100% 100%;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        > span {
          font-family: "microsoft yahei ui","microsoft yahei";
          font-size: 16px;
          color: #2dfaf3;
          line-height: 66px;
        }
      }
      .time {
        position: absolute;
        left: 86px;
        top: 0;
        bottom: 0;
        margin: auto;
        padding-top: 14px;
        p {
          font-family: "microsoft yahei ui","microsoft yahei";
          font-size: 12px;
          font-weight: 500;
          color: rgba(255, 255, 255, 0.61);
          text-align: left;
          line-height: 1.5;
          span {
            color: #7DF6FF;
            font-size: 14px;
          }
        }
      }
    }

    .params-list {
      width: 100%;
      overflow: hidden;
      position: relative;
      margin-top: 24px;
      .params-list-box {
        height: 196px;
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;
        overflow-y: auto;
      }
      .more-btn {
        color: rgba(106, 255, 213, 0.9412);
        cursor: pointer;
        font-size: 14px;
        position: absolute;
        right: 20px;
        bottom: 0;
      }
      .pl {
        width: 47px;
        margin-right: 42px;
        margin-top: 16px;
        line-height: 1.5;
        p:nth-child(1) {
          font-size: 12px;
          text-align: center;
          color: #2dfaf3;
          position: relative;
          font-weight: bold;
          &::after {
            content: "";
            display: inline-block;
            width: 100%;
            height: 1px;
            background-color: #646464;
            position: absolute;
            left: 0;
            right: 0;
            bottom: -4px;
          }
        }
        p:nth-child(2) {
          font-size: 12px;
          color: #ffffff;
          margin-top: 12px;
        }
      }
    }
  }
  .params {
    > p {
      font-family: "microsoft yahei ui","microsoft yahei";
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
      line-height: 35px;
      padding-left: 16px;
    }
    ul {
      li {
        display: flex;
        justify-content: space-between;
        line-height: 35px;
        .label {
          font-family: "microsoft yahei ui","microsoft yahei";
          font-size: 14px;
          color: rgba(255, 255, 255, 0.7);
          width: 80px;
          text-align: right;
          margin-right: 12px;
        }

        .value {
          width: 210px;
          font-family: "microsoft yahei ui","microsoft yahei";
          font-size: 14px;
          font-weight: 500;
          color: rgba(255, 255, 255, 0.8);
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }
}
</style>
